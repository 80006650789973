/**
 *  @fileOverview Contact form
 *
 *  @author       Lukasz Goral <lukasz.goral@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     modules/service
 *  @requires     utils/helper
 */
import throttle from 'lodash/throttle';
import forEach from 'lodash/forEach';
import { api } from './api';
import { generalError } from './general-errors';
import { helper } from '../utils/helper';
import { addLoader, removeLoader } from '../utils/button-helper';

/* eslint-disable no-console */
class UnconectedAnalyst {

  init() {
    this.contactForm = null;
    this.formDOMElements = null;
    this.submit = null;
    this.baseUrl = location.origin;
    this.formData = {};
    this.initDOMElements();
  }

  initDOMElements() {
    this.contactForm = document.querySelector('[data-unconnected-analyst]');
    if (this.contactForm != null) {
      this.inputs = this.contactForm.querySelectorAll('[data-form-input]');
      this.formDOMElements = this.contactForm.querySelectorAll('[data-form-element]');
      this.countrySelect = this.contactForm.querySelector('[name="Country"]');
      this.countries = [];

      this.submit = this.contactForm.querySelector('[type="submit"]');
      this.successLayer = this.contactForm.querySelector('[data-enquire-success]');
      this.initBindings();
    }
  }

  initBindings() {
    this.submit.addEventListener('click', throttle((e) => {
      addLoader(this.submit);
      e.preventDefault();
      this.onSubmit();
    }, 2000));
    this.submit.addEventListener('click', (e) => {
      e.preventDefault();
    });
    forEach(this.inputs, (input) => {
      if (input.tagName === 'SELECT' || input.getAttribute('type') === 'checkbox') {
        input.addEventListener('change', () => {
          this.onInputChange(input);
        });
      } else {
        input.addEventListener('keydown', () => {
          this.onInputChange(input);
        });
      }
    });
  }

  getFormData() {
    forEach(this.formDOMElements, (el) => {
      const formElName = el.getAttribute('data-form-element');
      const formField = el.querySelectorAll(`[name="${formElName}"]`);
      const tagName = formField[0].tagName;
      const inputType = formField[0].getAttribute('type');
      if (tagName === 'INPUT' && inputType === 'text') {
        this.formData[formElName] = this.contactForm.querySelectorAll(`[name="${formElName}"]`)[0].value;
      }
      if (tagName === 'INPUT' && inputType === 'checkbox') {
        this.formData[formElName] = this.contactForm.querySelectorAll(`[name="${formElName}"]`)[0].checked;
      }
      if (tagName === 'SELECT') {
        const selectEl = this.contactForm.querySelectorAll(`[name="${formElName}"]`)[0];
        this.formData[formElName] = selectEl.options[selectEl.selectedIndex].value;
      }
    });
    this.formData.Culture = document.documentElement.lang;
  }

  sendData(formData) {
    let result = {};
    const url = `${this.baseUrl}/api/unconnectedanalyst/`;
    return api.request(url, 'post', formData)
      .then((res) => {
        if ((res || {}).data || {}) {
          if (res.status === 400 && res.data.ModelState) {
            this.handleErrors(res.data.ModelState);
          } else {
            this.showSuccess();
            result = res.data;
          }
        }
        removeLoader(this.submit);
        return result;
      })
      .catch((err) => {
        if ((err || {}).message) {
          generalError.showError(err.message);
        }
      });
  }

  onSubmit() {
    this.getFormData();
    console.log(this.formData);
    this.sendData(this.formData);
  }

  handleErrors(data) {
    const errors = Object.keys(data);
    const errorKeys = [];
    forEach(errors, (errKey) => { errorKeys.push(errKey.split('.')[1]); });
    forEach(this.formDOMElements, (item) => {
      const index = errorKeys.indexOf(item.getAttribute('data-form-element'));
      const isGrouped = helper.getParentNode(item, '.form__element-group').length > 0 && window.innerWidth > 768;
      const formGroup = helper.getParentNode(item, '.form__element-group')[0];

      if (index >= 0) {
        const errorSpan = item.querySelector('[data-form-error-message]');
        errorSpan.innerHTML = data[errors[index]][0];
        if (isGrouped) {
          formGroup.classList.add('error');
        }
        item.classList.add('error');
      } else {
        item.classList.remove('error');
      }
    });
  }

  onInputChange(input) {
    const formGroup = helper.getParentNode(input, '.form__element-group')[0];
    const field = helper.getParentNode(input, '[data-form-element]')[0];

    if (field.classList.contains('error')) {
      field.classList.remove('error');
    }

    if (formGroup && formGroup.classList.contains('error')
      && formGroup.querySelectorAll('.error').length === 0) {
      formGroup.classList.remove('error');
    }
  }

  showSuccess() {
    this.contactForm.classList.add('success');
    this.successLayer.classList.add('show');
    /* eslint-disable no-param-reassign */
    setTimeout(() => {
      this.successLayer.classList.add('visible');
    }, 50);
    /* eslint-enable no-param-reassign */
  }
}

export const unconnectedAnalyst = new UnconectedAnalyst();
/* eslint-enable no-console */
