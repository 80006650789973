/**
 *  @fileOverview Gallery controller - collects and controls all the galleries on a page
 *
 *  @author       Daniel Jung <daniel.jung@possible.com>
 *
 */

class GeneralError {
  init() {
    this.AUTO_CLOSE = true;
    this.CLOSE_TIMEOUT = 5000;
    this.onClickBind = this.onClick.bind(this);
    this.initDOMElements();
    this.initBindings();
  }

  destroy() {
    if (this.errorCloseButton) {
      this.errorCloseButton.removeEventListener('click', this.onClickBind);
    }
  }

  initDOMElements() {
    this.errorContainer = document.querySelector('[data-general-error]');
    this.errorMessage = document.querySelector('[data-general-error-message]');
    this.errorCloseButton = document.querySelector('[data-general-error-close]');
  }

  initBindings() {
    if (this.errorCloseButton) {
      this.errorCloseButton.addEventListener('click', this.onClickBind);
    }
  }

  onClick(e) {
    e.preventDefault();
    this.closeError();
  }

  showError(message) {
    if (this.errorContainer && this.errorMessage) {
      this.errorMessage.innerHTML = message;
      this.errorContainer.classList.add('general-error--open');
      if (this.AUTO_CLOSE) {
        setTimeout(() => this.closeError(), this.CLOSE_TIMEOUT);
      }
    }
  }

  closeError() {
    this.errorContainer.classList.remove('general-error--open');
  }
}

export const generalError = new GeneralError();
