/**
 *  @fileOverview Tab controller
 *
 *  @author       Julia Ero <julia.ero@possible.com>
 *  @author       Daniel Jung <daniel.jung@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     modules/tab
 */

import forEach from 'lodash/forEach';
import TabModule from './tab';

class TabModuleController {
  init() {
    this.tabModules = document.querySelectorAll('[data-tab-module]');
    this.tabModuleInstances = [];
    if (this.tabModules.length) {
      this.initTabModules();
    }
  }

  initTabModules() {
    forEach(this.tabModules, (tabModule) => {
      if (tabModule.getAttribute('data-tabmodule-inited') === null) {
        this.tabModuleInstances.push(new TabModule(tabModule));
        tabModule.setAttribute('data-tabmodule-inited', true);
      }
    });
  }
}

export const tabModuleController = new TabModuleController();
