/**
 *  @fileOverview Image resize and load handler
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *  @author       Szymon Grzybek <szymon.grzybek@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     NPM:supportsPassiveEvents
 *  @requires     NPM:withinViewport
 *  @requires     utils/device-properties
 */

import { withinViewport } from 'withinviewport';
import forEach from 'lodash/forEach';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import { supportsPassiveEvents } from 'detect-it';
import getDeviceProperties from '../utils/device-properties';

const EVENTTRIGGER = true;
const RANGES = [
  320, 480, 568, 640, 720, 768,
  800, 960, 1080, 1200, 1366,
  1440, 1536, 1600, 1720, 1920,
  2048, 2160, 2560, 2880, 3000,
  3200, 3600, 3840, 4000, 5120,
];

class ImageHandler {

  constructor() {
    this.images = [];
    this.bindingsInited = false;
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.isCMS = document.querySelectorAll('.sfPageEditor').length > 0;
  }

  init() {
    this.images = [];

    forEach(document.body.querySelectorAll('[data-img-src]'), (el) => {
      this.images.push({
        el,
        loaded: false,
        ungrey: false,
        error: false,
        src: el.getAttribute('data-img-src'),
        loadedWidth: 0,
        loadedHeight: 0,
        checkVisibility: el.getAttribute('data-img-checkVisibility') !== null,
        keepWidth: el.getAttribute('data-img-keepWidth') !== null,
        keepHeight: el.getAttribute('data-img-keepHeight') !== null,
        fullSize: el.getAttribute('data-img-fullSize') !== null,
        instantLoad: el.getAttribute('data-img-instantLoad') !== null || this.isCMS,
        instantLoadOnTrigger: el.getAttribute('data-img-instantLoadOnTrigger') !== null || this.isCMS,
        quality: el.getAttribute('data-img-quality') !== null ? parseInt(el.getAttribute('data-img-quality'), 10) : 60,
        format: el.getAttribute('data-img-format') || '',
      });
    });

    this.checkAndLoad();
    if (this.bindingsInited === false) {
      this.initBindings();
      this.bindingsInited = true;
    }
  }

  initBindings() {
    window.addEventListener('resize', debounce(() => {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
      this.checkAndLoad(EVENTTRIGGER);
    }, 250));

    window.addEventListener('scroll', throttle(() => {
      this.checkAndLoad(EVENTTRIGGER);
    }, 50), supportsPassiveEvents ? { capture: false, passive: false } : false);
  }

  checkAndLoad(trigger) {
    forEach(this.images, (img) => {
      if (
        img.src !== null
        && img.error === false
      ) {
        /* eslint-disable no-param-reassign */

        if (img.checkVisibility && img.el.offsetParent === null) {
          img.visible = false;
        } else {
          img.visible = withinViewport(img.el, {
            sides: 'top bottom',
            top: this.screenHeight / -2,
            bottom: this.screenHeight / -2,
          });
        }

        const { containerWidth, containerHeight } = this.getContainerSize(img);

        if (
          (img.loadedWidth / containerWidth < 0.7)
          || (img.loadedHeight / containerHeight < 0.7)
        ) {
          img.loaded = false;
        }

        if (
          img.loaded === false
          && (
            (trigger && img.instantLoadOnTrigger)
            || img.instantLoad
            || img.visible
          )
        ) {
          this.loadImage(img);
        }

        if (
          img.ungrey === false
          && img.visible
          && img.instantLoadOnTrigger
        ) {
          img.ungrey = true;
          img.el.setAttribute('data-img-ungrey', true);
        }

        /* eslint-enable no-param-reassign */
      }
    });
  }

  getClosestSize(originalSize) {
    let selectedSize;

    forEach(RANGES, (range) => {
      if (originalSize < range && !selectedSize) {
        selectedSize = range;
      }
    });

    return selectedSize;
  }

  getContainerSize(image) {
    const el = image.el;

    let containerWidth;
    let containerHeight;

    if (el.nodeName.toLowerCase() === 'img' && image.fullSize === false) {
      containerWidth = el.offsetWidth;
      containerHeight = el.offsetHeight;
    } else {
      containerWidth = this.screenWidth;
      containerHeight = this.screenHeight;
    }

    return {
      containerWidth,
      containerHeight,
    };
  }

  loadImage(image) {
    const el = image.el;
    const quality = image.quality;
    const format = image.format;
    const { containerWidth, containerHeight } = this.getContainerSize(image);
    const imgHeight = parseInt(containerHeight * getDeviceProperties.pixelRatio, 10);
    let imgWidth = parseInt(containerWidth * getDeviceProperties.pixelRatio, 10);
    let src = image.src;

    imgWidth = this.getClosestSize(imgWidth);

    const srcParts = src.split('&');

    if (srcParts.length > 0) {
      src = srcParts[0];
    }

    let parametrizedSrc = src;

    if (src.match(/\?/ig) !== null) {
      parametrizedSrc = `${src}&w=${imgWidth}`;
    } else {
      parametrizedSrc = `${src}?w=${imgWidth}`;
    }

    if (image.keepHeight) {
      parametrizedSrc = `${parametrizedSrc}&h=${imgHeight}`;
    }

    if (format) {
      parametrizedSrc = `${parametrizedSrc}&format=${format}`;
    }

    parametrizedSrc = `${parametrizedSrc}&quality=${quality}`;

    /* eslint-disable no-param-reassign */
    el.onload = () => {
      image.loaded = true;
      image.loadedWidth = containerWidth;
      image.loadedHeight = containerHeight;
      el.setAttribute('data-img-loaded', true);
    };
    el.onerror = () => {
      image.error = true;
      el.setAttribute('data-img-error', true);
    };
    /* eslint-enable no-param-reassign */
    if (imgWidth !== 0 || imgHeight !== 0) {
      el.setAttribute('src', parametrizedSrc);
    }
  }
}

export const imageHandler = new ImageHandler();
