import forEach from 'lodash/forEach';
import Enquire from './enquire';

class EnquireController {
  init() {
    this.enquires = [];
    this.initEnquires();
  }

  initEnquires() {
    forEach(document.querySelectorAll('[data-enquire]'), (elem) => {
      this.enquires.push(new Enquire(elem));
    });
  }

  getEnquireById(id) {
    return this.enquires.filter((item) => (item.id === id))[0];
  }
}

export const enquireController = new EnquireController();
