/**
 *  @fileOverview Audio player handler
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     NPM:detect-it
 *  @requires     NPM:in-view
 *  @requires     modules/image-handler
 *  @requires     utils/camelcase
 *  @requires     utils/accessibility
 *  @requires     utils/device-properties
 */

import forEach from 'lodash/forEach';
import { accessibilityHelper } from '../utils/accessibility';

const PLAYING = 'playing';
const PAUSED = 'paused';
const ENDED = 'ended';
const LOADED = 'loaded';
const ERROR = 'error';

class AudioPlayer {
  constructor(el, id) {
    if (!(typeof el && el.nodeName)) {
      throw TypeError('Constructor parameter should be a valid DOM element!');
    }

    this.state = [];
    this.audio = el;
    this.id = id;
    this.currentAudio = null;
    this.sources = [];
    this.player = el.querySelector('audio');

    this.loadedCallback = null;

    if (this.player === null) {
      throw TypeError('Cannot find a valid audio element!');
    }

    this.initSources();
    this.initDOMElements();
    this.initEvents();
    this.initBindings();
  }

  initSources() {
    forEach(this.audio.querySelectorAll('[data-audio-src]'), (elem, id) => {
      this.sources.push({
        id,
        src: elem.getAttribute('data-audio-src'),
        label: elem.getAttribute('data-audio-label'),
      });
    });
  }

  initDOMElements() {
    this.labelTarget = this.audio.querySelector('[data-audio-label-target]');
    this.originalLabel = this.labelTarget.getAttribute('data-audio-label-org');
  }

  initEvents() {
    this.player.addEventListener('canplay', this.onLoaded.bind(this));
    this.player.addEventListener('playing', this.onPlay.bind(this));
    this.player.addEventListener('pause', this.onPause.bind(this));
    this.player.addEventListener('ended', this.onEnd.bind(this));
    this.player.addEventListener('error', this.onError.bind(this));
  }

  initBindings() {
    forEach(this.audio.querySelectorAll('[data-audio-play]'), (elem) => {
      elem.addEventListener('click', this.onClick.bind(this));
      accessibilityHelper.subscribe(elem, 'open', this.onClick.bind(this));
    });
  }

  onClick(e) {
    e.preventDefault();
    const source = this.getSourceByUrl(e.currentTarget.getAttribute('data-audio-src'));

    this.setSource(source.src);
    this.resetButtons();
    this.resetLabels();

    if (this.currentAudio !== source.id) {
      this.currentAudio = source.id;
      e.currentTarget.classList.add('active');
      this.playAudio();
      this.setLabel(source.label);
    } else {
      this.stopAudio();
    }

  }

  resetButtons() {
    forEach(this.audio.querySelectorAll('[data-audio-play]'), (elem) => {
      elem.classList.remove('active');
    });
  }

  resetLabels() {
    this.setLabel(this.originalLabel);
  }

  setLabel(label) {
    this.labelTarget.innerHTML = label;
  }

  setSource(src) {
    this.player.setAttribute('src', src);
  }

  getSourceByUrl(src) {
    const source = this.sources.filter((s) => (s.src === src));

    if (source && source[0] && source[0].src) {
      return source[0];
    }

    return null;
  }

  checkPlayerState() {
    if (this.player.readyState > 3) {
      this.onLoaded();
      this.onPlayerReady();
    } else {
      window.setTimeout(() => {
        this.checkPlayerState();
      }, 100);
    }
  }

  onPlayerReady() {
    this.initBindings();
    this.configurePlayer();
  }

  onPlay() {
    this.removeState(PAUSED);
    this.setState(PLAYING);
  }

  onPause() {
    this.removeState(PLAYING);
    this.setState(PAUSED);
  }

  onEnd() {
    this.removeState(PLAYING);
    this.removeState(PAUSED);
    this.setState(ENDED);
    this.resetButtons();
    this.resetLabels();
    this.currentAudio = null;
  }

  onError() {
    this.removeState(PAUSED);
    this.removeState(PLAYING);
    this.setState(ERROR);
  }

  onLoaded() {
    this.setState(LOADED);
  }

  getState(state = PLAYING) {
    return this.state.includes(state);
  }

  setState(state = null) {
    if (state !== null && this.state.includes(state) === false) {
      this.state.push(state);
      this.audio.classList.add(`audio--${state}`);
    }
    this.audio.setAttribute('data-audio-state', this.state.join('|'));
  }

  removeState(state) {
    this.audio.classList.remove(`audio--${state}`);
    this.state = this.state.filter((val) => (val !== state));
    this.setState();
  }

  playAudio() {
    this.player.play();
  }

  stopAudio() {
    this.player.pause();
    this.player.currentTime = 0;
  }

}

export default AudioPlayer;
