/**
 *  @fileOverview Location switch
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *
 */

import { deviceType } from 'detect-it';
import { homepageController } from './homepage-controller';

const getCurrentPage = () => (document.body.getAttribute('data-page').toLowerCase());

class LocationSwitch {

  init() {
    this.page = getCurrentPage();
    this.locationListOpened = false;
    this.onLocationSwitchClickBind = this.onLocationSwitchClick.bind(this);

    this.initDOMElements();
    if (this.locationSwitch !== null) {
      this.initBindings();
    }
  }

  destroy() {
    this.removeBindings();
  }

  initDOMElements() {
    this.main = document.body.querySelector('main');
    this.footer = document.body.querySelector('[data-footer]');
    if (this.footer) {
      this.locationSwitch = this.footer.querySelector('[data-location-switch]');
      this.locationClose = this.footer.querySelector('[data-location-close]');
      this.emissionBlock = this.footer.querySelector('[data-emission-block]');
    }
  }

  initBindings() {
    if (this.locationSwitch) {
      this.locationSwitch.addEventListener('click', this.onLocationSwitchClickBind, false);
    }
    if (this.locationSwitch) {
      this.locationClose.addEventListener('click', this.onLocationSwitchClickBind, false);
    }
  }

  removeBindings() {
    if (this.locationSwitch) {
      this.locationSwitch.removeEventListener('click', this.onLocationSwitchClickBind);
    }

    if (this.locationClose) {
      this.locationClose.removeEventListener('click', this.onLocationSwitchClickBind);
    }
  }

  onLocationSwitchClick(e) {
    e.preventDefault();
    this.locationListOpened = !this.locationListOpened;
    if (this.locationListOpened) {
      this.footer.classList.add('footer--location-active');
    } else {
      this.footer.classList.remove('footer--location-active');
    }

    if (this.page === 'home-page' && deviceType === 'mouseOnly') {
      let footerHeight = this.locationListOpened ? this.footer.offsetHeight : 200;

      if (this.emissionBlock !== null) {
        footerHeight = this.footer.offsetHeight;
      }

      this.main.style.marginBottom = `${footerHeight}px`;
      homepageController.setFooterHeight(footerHeight);
      homepageController.scrollToBlock(3);
    }
  }

}

export const locationSwitch = new LocationSwitch();
