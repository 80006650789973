/**
 *  @fileOverview Parallax controller
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     modules/parallax
 */

import forEach from 'lodash/forEach';
import Parallax from './parallax';

class ParallaxController {
  constructor() {
    this.parallax = [];
  }

  init() {
    this.isCMS = document.querySelectorAll('.sfPageEditor').length > 0;
    if (this.isCMS === false) {
      this.initParallaxElements();
    }
  }

  initParallaxElements() {
    let name;
    forEach(document.body.querySelectorAll('[data-parallax]'), (elem) => {
      name = elem.getAttribute('data-parallax-name') || '';
      if (
        elem.getAttribute('data-parallax-inited') === null
        && elem.clientWidth !== 0
      ) {
        this.parallax.push({
          element: new Parallax(elem),
          name,
        });
        elem.setAttribute('data-parallax-inited', true);
      }
    });
  }

  getParallaxItemByName(name) {
    const parallaxObj = this.parallax.filter((parallax) => (parallax.name === name))[0];
    return (parallaxObj && parallaxObj.element) || null;
  }

  updateAllParallaxItems() {
    forEach(this.parallax, (item) => {
      item.element.refreshParallax();
    });
  }

}

export const parallaxController = new ParallaxController();
