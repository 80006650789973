/**
 *  @fileOverview Module controller, controll all the connected modules
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *  @author       Julia Ero <julia.ero@possible.com>
 *  @author       Daniel Jung <daniel.jung@possible.com>
 *
 *  @requires     modules/overlay-controller
 *  @requires     modules/video-controller
 *  @requires     modules/homepage-controller
 *  @requires     modules/experience-controller
 *  @requires     modules/modelpage-controller
 *  @requires     modules/slider-controller
 *  @requires     modules/gallery-controller
 *  @requires     modules/quote-controller
 *  @requires     modules/subnavigation
 *  @requires     modules/accordion-controller
 *  @requires     modules/model-accordion
 *  @requires     modules/enquire
 *  @requires     modules/general-errors
 *  @requires     modules/specs-template
 *  @requires     modules/search
 *  @requires     modules/brand-module
 *  @requires     modules/parallax-controller
 *  @requires     modules/enquire-form-controller
 *  @requires     modules/colorize
 *  @requires     modules/tab-controller
 *  @requires     modules/header
 *  @requires     modules/image-handler
 *  @requires     modules/count-up-controller
 *  @requires     modules/cms
 *  @requires     modules/investis-feed
 *  @requires     utils/helper
 *  @requires     utils/accessibility
 *
 */

import { helper } from '../utils/helper';
import { overlayController } from './overlay-controller';
import { videoController } from './video-controller';
import { audioController } from './audio-controller';
// import { homepageController } from './homepage-controller';
// import { megaNav } from './meganav';
import { contactFormController } from './contact-form-controller';
import { globalLandingPageController } from './global-landingpage-controller';
import { experienceController } from './experience-controller';
import { modelpageController } from './modelpage-controller';
import { sliderController } from './slider-controller';
import { galleryController } from './gallery-controller';
import { quoteController } from './quote-controller';
import { subNavigation } from './subnavigation';
import { accordionController } from './accordion-controller';
import { modelAccordion } from './model-accordion';
import { enquireController } from './enquire-controller';
import { generalError } from './general-errors';
import { specsTemplate } from './specs-template';
import { search } from './search';
import { brandVideo } from './brand-module';
import { parallaxController } from './parallax-controller';
import { formController } from './enquire-form-controller';
import { loginForm } from './login-form';
import { colorize } from './colorize';
import { locationSwitch } from './location-switch';
import { tabModuleController } from './tab-controller';
// import { header } from './header';
import { cookieBar } from './cookiebar';
import { imageHandler } from './image-handler';
import { countUpController } from './count-up-controller';
import { cmsJs } from './cms';
import { accessibilityHelper } from '../utils/accessibility';
import { objectFit } from '../utils/object-fit';
import { profileController } from './profile-controller';
import { unconnectedAnalyst } from './unconnected-analyst';
import { popupController } from './popup-controller';
import { iframeController } from './iframe-controller';
import { globalHeader } from './global-header';
import { globalNavigation } from './global-navigation';
import { languageSwitcherController } from './language-switcher';
import { globalFooter } from './global-footer';

const getCurrentPage = () => (document.body.getAttribute('data-page').toLowerCase());

class ModuleController {

  initMethod(method) {
    /* eslint-disable no-console */
    try {
      method.init();
    } catch (e) {
      console.warn(e);
    }
    /* eslint-enable */
  }

  initModules() {
    this.page = getCurrentPage();

    [
      helper,
      overlayController,
      videoController,
      audioController,
      sliderController,
      accordionController,
      experienceController,
      generalError,
      search,
      parallaxController,
      enquireController,
      formController,
      colorize,
      tabModuleController,
      countUpController,
      imageHandler,
      // megaNav,
      contactFormController,
      cmsJs,
      profileController,
      galleryController,
      quoteController,
      modelpageController,
      locationSwitch,
      cookieBar,
      objectFit,
      popupController,
      unconnectedAnalyst,
      iframeController,
      globalHeader,
      globalNavigation,
      languageSwitcherController,
      globalFooter,
    ].map(this.initMethod);

    accessibilityHelper.removeClickFocus();

    switch (this.page) {
      case 'home-page':
        // homepageController.init();
        break;
      case 'global-landing':
        globalLandingPageController.init();
        break;
      case 'model-page':
        if ('objectFit' in document.documentElement.style === false) {
          objectFit.init();
        }
        subNavigation.init();
        modelAccordion.init();
        specsTemplate.init();
        break;
      case 'brand-page':
        // header.init();
        brandVideo.init();
        break;
      case 'login-page':
        loginForm.init();
        // header.init();
        break;
      default:
        // header.init();
        break;
    }
  }

  destroyModules() {
    helper.destroy();
    // header.destroy();
    // homepageController.destroy();
    experienceController.destroy();
    generalError.destroy();
    countUpController.destroy();
    locationSwitch.destroy();
    if (modelAccordion) {
      modelAccordion.destroy();
    }
    subNavigation.destroy();
  }

  getCurrentPage() {
    return this.page;
  }
}

export const moduleController = new ModuleController();
