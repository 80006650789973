/**
 *  @fileOverview Jump to module
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *
 *  @requires     utils/easings
 */

import { easeInOutQuad } from './easings';

const jumper = () => {
  let element;
  let start;
  let stop;
  let offset;
  let easing;
  let a11y;
  let distance;
  let duration;
  let timeStart;
  let timeElapsed;
  let next;
  let callback;

  function location() {
    return window.scrollY || window.pageYOffset;
  }

  function top(elem) {
    return elem.getBoundingClientRect().top + start;
  }

  function done() {
    window.scrollTo(0, start + distance);

    if (element && a11y) {
      element.setAttribute('tabindex', '-1');
      element.focus();
    }

    if (typeof callback === 'function') {
      callback();
    }

    timeStart = false;
  }

  function loop(timeCurrent) {
    if (!timeStart) {
      timeStart = timeCurrent;
    }

    timeElapsed = timeCurrent - timeStart;

    next = easing(timeElapsed, start, distance, duration);

    window.scrollTo(0, next);

    if (timeElapsed < duration) {
      window.requestAnimationFrame(loop);
    } else {
      done();
    }
  }

  function jump(target, options = {}) {
    duration = options.duration || 1000;
    offset = options.offset || 0;
    callback = options.callback;
    easing = options.easing || easeInOutQuad;
    a11y = options.a11y || false;
    start = location();

    switch (typeof target) {
      // scroll from current position
      case 'number':
        element = undefined;
        a11y = false;
        stop = start + target;
        break;
      case 'object':
        element = target;
        stop = top(element);
        break;
      case 'string':
        element = document.querySelector(target);
        stop = top(element);
        break;
      default:
        break;
    }

    distance = (stop - start) + offset;

    switch (typeof options.duration) {
      case 'number':
        duration = options.duration;
        break;
      case 'function':
        duration = options.duration(distance);
        break;
      default:
        break;
    }

    window.requestAnimationFrame(loop);
  }

  return jump;
};

const singleton = jumper();

export default singleton;
