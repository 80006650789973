/**
 *  @fileOverview ProfileController - gallery of people profiles with overlay for Profile Module
 *
 *  @author       Lukasz Goral <lukasz.goral@possible.com>
 *
 *  @requires     NPM:lodash
 */

import forEach from 'lodash/forEach';
import {
  Profile
} from './profile';

class ProfileController {
  init() {
    this.profileModules = null;
    this.profileModules = document.querySelectorAll('[data-profile]');
    if (this.profileModules) {
      forEach(this.profileModules, (item) => {
        const profile = new Profile(item);
        profile.init();
      });
    }

  }

}

export const profileController = new ProfileController();
