/**
 *  @fileOverview Brand page and brand video handler
 *
 *  @author       Julia Ero <julia.ero@possible.com>
 *  @author       Daniel Jung <daniel.jung@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     NPM:detect-it
 *  @requires     modules/video-controller
 *  @requires     modules/image-handler
 *  @requires     modules/parallax-controller
 *  @requires     utils/object-fit
 *  @requires     utils/accessibility
 */

import forEach from 'lodash/forEach';
import { deviceType } from 'detect-it';
import jump from '../utils/jump';
import { easeOutSine } from '../utils/easings';

import { videoController } from './video-controller';
import { imageHandler } from './image-handler';
import { parallaxController } from './parallax-controller';
import { objectFit } from '../utils/object-fit';
import { accessibilityHelper } from '../utils/accessibility';

class BrandVideo {
  init() {
    this.currentFullvideo = null;
    this.currentFullVideoModule = null;
    this.currentIndex = 0;
    this.isFullScreen = false;
    this.initDOMElements();
    this.initBindings();
  }

  initDOMElements() {
    this.brand = document.querySelector('[data-brand]');
    this.footer = document.querySelector('[data-footer]');

    if (this.brand) {
      this.brandVideos = this.brand.querySelectorAll('[data-brand-video]');
      this.brandFullVideos = this.brand.querySelectorAll('[data-brand-full-video]');
      this.brandMenuButtons = this.brand.querySelectorAll('[data-video-control="menu"]');
      this.brandShowMore = this.brand.querySelectorAll('[data-brand-show]');
      this.brandContent = this.brand.querySelector('[data-brand-content-wrapper]');
    }
  }

  initBindings() {
    if (this.brand && this.brandVideos && this.footer) {
      forEach(this.brandVideos, (item) => {
        item.addEventListener('click', (e) => {
          this.activateBrandSection(e);
        });

        accessibilityHelper.subscribe(item, 'open', (e) => {
          this.activateBrandSection(e);
        });
      });
    }

    if (this.brandFullVideos) {
      forEach(this.brandFullVideos, (item) => {
        item.addEventListener('transitionend', (e) => {
          this.onFullscreenTransition(e);
        });

        accessibilityHelper.subscribe(item, 'close', () => {
          if (!this.brandContent.classList.contains('show')) {
            this.onMenuButton();
          }
        });
      });

      forEach(this.brandMenuButtons, (item) => {
        item.addEventListener('click', () => {
          this.onMenuButton();
        });

        accessibilityHelper.subscribe(item, 'open', this.onMenuButton.bind(this));
      });

      forEach(this.brandShowMore, (item) => {
        item.addEventListener('click', () => {
          this.showBrandContent();
        });

        accessibilityHelper.subscribe(item, 'open', () => {
          this.showBrandContent();
        });
      });
    }
  }

  showFullscreenVideo(index) {
    this.currentFullvideo = this.brand.querySelector(`[data-brand-full-video="${index}"]`);

    if (this.currentFullvideo) {
      const id = this.currentFullvideo.querySelector('[data-video]').getAttribute('data-video');
      this.currentFullVideoModule = videoController.getPlayerById(id);

      if (deviceType === 'mouseOnly' && this.currentFullVideoModule.getState('loaded')) {
        this.currentFullvideo.classList.add('show');
        this.currentFullVideoModule.player.play();
        this.currentFullVideoModule.setActiveControl();

        setTimeout(() => {
          this.currentFullvideo.classList.add('visible');
          this.currentFullvideo.setAttribute('aria-hidden', 'false');
          this.currentFullvideo.focus();
        }, 50);

        objectFit.update();
        this.initCallback();
        this.isFullScreen = true;
      }
    }
  }

  onFullscreenTransition(e) {
    if (e.propertyName === 'opacity' && !e.target.classList.contains('visible')) {
      e.target.classList.remove('show');
    }
  }

  onMenuButton() {
    this.footer.classList.remove('footer--enabled');
    this.brand.setAttribute('data-brand-active', '0');
    this.brandContent.classList.remove('show');
    this.resetVideo();
  }

  showBrandContent() {
    const isPlaying = this.currentFullVideoModule.getState('playing');
    this.footer.classList.add('footer--enabled');
    this.brandContent.classList.add('show');

    if (isPlaying) {
      this.currentFullVideoModule.pauseVideo();
      this.currentFullVideoModule.setActiveControl();
    }

    imageHandler.checkAndLoad();
    objectFit.update();
    parallaxController.init();
  }

  activateBrandSection(e) {
    this.currentIndex = parseInt(e.currentTarget.getAttribute('data-brand-video'), 10);

    this.brand.setAttribute('data-brand-active', this.currentIndex);
    this.showFullscreenVideo(this.currentIndex);

    if (window.innerWidth < 769) {
      jump(this.brand.querySelector(`[data-brand-content="${this.currentIndex}"]`), {
        duration: 400,
        easing: easeOutSine,
        offset: 0,
      });
      parallaxController.init();
    }
  }

  initCallback() {
    this.currentFullVideoModule.setEndCallback(() => {
      this.resetVideo();
    });
  }

  resetVideo() {
    this.currentFullvideo.classList.remove('visible');
    this.currentFullVideoModule.stopVideo();
    this.currentFullvideo.setAttribute('aria-hidden', 'true');
    this.isFullScreen = false;
  }
}

export const brandVideo = new BrandVideo();
