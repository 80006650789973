/**
 *  @fileOverview Popup module controller to control popup behaviour
 *
 *  @author       Szymon Grzybek <szymon.grzybek@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     popup-item
 */

import forEach from 'lodash/forEach';
import { PopupItem } from './popup-item';

class PopupController {
  init() {
    this.popups = [];
    forEach(document.querySelectorAll('.popup'), (elem) => {
      this.popups.push(new PopupItem(elem));
    });
  }
}

export const popupController = new PopupController();
