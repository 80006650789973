/**
 *  @fileOverview Profile - gallery of people profiles with overlay for Profile Module
 *
 *  @author       Lukasz Goral <lukasz.goral@possible.com>
 *
 *  @requires     NPM:lodash
 */

import forEach from 'lodash/forEach';
import { lockScroll } from '../utils/lock-scroll';

export class Profile {
  constructor(item) {
    this.profileModule = item;
  }

  init() {
    this.profileItems = null;
    this.initDOMElements();
  }

  initDOMElements() {
    if (this.profileModule !== null) {
      this.createOverlayNode();
      this.overlay = document.body.querySelector('.profile-overlay');
      this.overlayRow = this.overlay.querySelector('.profile-overlay__row');
      this.profileItems = this.profileModule.querySelectorAll('.profile__item');
    }
    if (this.profileItems !== null) {
      this.initBindings();
    }

  }

  initBindings() {
    forEach(this.profileItems, (item, index) => {
      item.addEventListener('click', () => {
        this.showItemInModal(index);
      });
    });
    this.overlay.addEventListener('click', () => {
      this.hideItemInModal();
    });
    this.overlayRow.addEventListener('click', (e) => {
      e.stopPropagation();
    });

  }

  showItemInModal(index) {
    while (this.overlayRow.firstChild) {
      this.overlayRow.removeChild(this.overlayRow.firstChild);
    }

    this.overlay.classList.add('profile-overlay--visible');
    const name = this.profileItems[index].querySelector('.profile__name').innerHTML;
    let image = this.profileItems[index].querySelector('.profile__image').src;
    image = `<img src="${image}" alt="${image}" class="profile-overlay__image">`;
    const jobTitle = this.profileItems[index].querySelector('.profile__job-title').innerHTML;
    const description = this.profileItems[index].querySelector('.profile__description').innerHTML;
    const template = `
    <button class="icon icon-close" role="presentation" aria-hidden="true">
    <span class="icon__wrapper">
      <svg viewBox="0 0 256 256" role="img" aria-label="Close">
        <title>Close</title>
        <use xlink:href="#icon-close"></use>
      </svg>
    </span>
  </button>
    <div class="col col--4-12">
    <h2 class="profile-overlay__name">${name}</h2>
    <p class="profile-overlay__job-title">${jobTitle}</p>
    <p class="profile-overlay__description">${description}</p>
    </div>
    <div class="col col--offset-1-12 col--7-12">
  

    <div class="profile-overlay__image-container">
    ${image}
    </div>
    </div>`;
    this.overlayRow.innerHTML = template;
    const closeBt = this.overlayRow.querySelector('.icon-close');
    closeBt.addEventListener('click', (e) => {
      e.stopPropagation();
      this.hideItemInModal();
    });
    lockScroll.lock();

  }

  hideItemInModal() {
    this.overlay.classList.add('profile-overlay--hidding');
    setTimeout(() => {
      while (this.overlayRow.firstChild) {
        this.overlayRow.removeChild(this.overlayRow.firstChild);
      }
      this.overlay.classList.remove('profile-overlay--visible');
      this.overlay.classList.remove('profile-overlay--hidding');

    }, 400);
    lockScroll.unlock();

  }

  createOverlayNode() {
    const overlayNode = document.createElement('div');
    overlayNode.classList.add('profile-overlay');
    const template = `
    <div class="pillarbox grid profile-overlay__modal">
    <div class="row row--no-margin row--m profile-overlay__row">
    </div>
    </div>`;

    overlayNode.innerHTML = template;
    document.body.appendChild(overlayNode);

  }

}
