/**
 *  @fileOverview GlobalNavigation
 *
 *  @author       Adrian Bobak <adrian.bobak@wundermanthompson.com>
 *
 */

import debounce from 'lodash/debounce';

const isDesktop = window.matchMedia('(min-width: 1024px)');
const LVL_CLSES = ['lvl1', 'lvl2', 'lvl3'];
const TRANSITION = 1250;

class GlobalNavigation {
  constructor() {
    this.items = [];
    this.panels = [];
    this.isMenuActive = false;
    this.activeLevel = null;
  }

  focusTrapMethods() {
    this.restoreFocusTrap = (e) => {
      if (e.key === 'Shift') {
        document.addEventListener('keydown', this.focusTrap);
        document.removeEventListener('keydown', this.reverseFocusTrap);
        document.removeEventListener('keyup', this.restoreFocusTrap);
      }
    };

    this.reverseFocusTrap = (e) => {
      if (e.key === 'Tab' && e.target === this.focusable[0]) {
        e.preventDefault();
        this.focusable[this.focusable.length - 1].focus();
      }
    };

    this.focusTrap = (e) => {
      if (e.key === 'Shift') {
        document.removeEventListener('keydown', this.focusTrap);
        document.addEventListener('keydown', this.reverseFocusTrap);
        document.addEventListener('keyup', this.restoreFocusTrap);
      } else if (
        e.key === 'Tab'
        && e.target === this.focusable[this.focusable.length - 1]
      ) {
        e.preventDefault();
        this.focusable[0].focus();
      }
    };
  }

  disableFocusTrap() {
    document.removeEventListener('keydown', this.focusTrap);
    this.lastFocusedElement.focus();
    this.lastFocusedElement = null;
  }

  enableFocusTrap() {
    this.lastFocusedElement = document.activeElement;
    document.addEventListener('keydown', this.focusTrap);
    setTimeout(() => {
      this.el.focus();
    }, TRANSITION);
  }

  hideLowerPanels(panelsIndex) {
    if (this.panels[panelsIndex]) {
      this.hidePanels(panelsIndex);
      this.hideLowerPanels(panelsIndex + 1);
    }
  }

  hidePanels(panelsIndex) {
    this.panels[panelsIndex].forEach((panel) => {
      panel.setAttribute('aria-expanded', false);
    });
  }

  initMethods() {
    this.focusTrapMethods();

    this.toggleMenu = () => {
      if (!this.isMenuActive) this.showMenu();
      else this.hideMenu();
    };

    this.onClickOutside = (e) => {
      if (!this.el.contains(e.target)) this.hideMenu();
    };

    this.closeOnEscape = (e) => {
      if (e.key === 'Escape') this.hideMenu();
    };

    this.showMenu = () => {
      this.el.setAttribute('aria-expanded', true);
      this.panels[0][0].setAttribute('aria-expanded', true);

      this.activeLevel = 0;
      this.el.classList.add(LVL_CLSES[0]);
      this.enableFocusTrap();

      setTimeout(() => {
        document.addEventListener('click', this.onClickOutside);
        document.addEventListener('keydown', this.closeOnEscape);
      });
    };

    this.hideMenu = () => {
      this.el.classList.add('close');

      setTimeout(() => {
        this.el.classList.remove('close');
        this.el.setAttribute('aria-expanded', false);

        this.panels.forEach((lvl, index) => this.hidePanels(index));

        this.el.classList.remove(LVL_CLSES[this.activeLevel]);
        this.activeLevel = null;
        this.disableFocusTrap();

        document.removeEventListener('click', this.onClickOutside);
        document.removeEventListener('keydown', this.closeOnEscape);
      }, TRANSITION);
    };

    this.onBackBtnClick = () => {
      if (this.activeLevel === 0) return;
      this.el.classList.remove(LVL_CLSES[this.activeLevel]);
      this.hidePanels(this.activeLevel);

      this.activeLevel -= 1;
      this.el.classList.add(LVL_CLSES[this.activeLevel]);
    };

    this.onItemClick = (clickedItem, newActiveLvl) => {
      this.el.classList.remove(LVL_CLSES[this.activeLevel]);
      this.activeLevel = newActiveLvl;
      this.el.classList.add(LVL_CLSES[this.activeLevel]);

      const clickedSection = clickedItem.parentNode;
      this.panels[newActiveLvl].forEach((panel) => {
        panel.setAttribute('aria-expanded', clickedSection.contains(panel));
      });

      if (this.panels[newActiveLvl + 1]) this.hidePanels(this.activeLevel + 1);
    };

    this.onLinkHover = (newActiveLvl) => {
      this.el.classList.remove(LVL_CLSES[this.activeLevel]);
      this.activeLevel = newActiveLvl;
      this.el.classList.add(LVL_CLSES[this.activeLevel]);
      this.hideLowerPanels(newActiveLvl + 1);
    };

    this.onCloseBtnHover = () => {
      if (isDesktop.matches) this.onLinkHover(0);
    };
  }

  setItemBindigs(itemsIndex) {
    this.items[itemsIndex].forEach((item) => {
      const isButton = !item.getAttribute('href');
      const targetsLvl = itemsIndex + 1;

      if (isButton) {
        item.addEventListener('click', () => this.onItemClick(item, targetsLvl)
        );
        item.addEventListener('mouseover', () => {
          this.onItemClick(item, targetsLvl);
          this.toggleInactiveClass(item.parentNode);
        });
      } else {
        item.addEventListener('mouseover', () => {
          this.onLinkHover(itemsIndex);
          this.toggleInactiveClass(item.parentNode);
        });
      }
    });
  }

  toggleInactiveClass(clickedSection) {
    if (isDesktop.matches && clickedSection.parentNode.children) {
      const elmList = Array.from(clickedSection.parentNode.children);
      elmList.forEach((item) => {
        if (clickedSection !== item) {
          item.querySelector('.nav-item').classList.add('inactive');
        } else {
          this.resetInactiveClass(item);
        }
      });
    }
  }

  resetInactiveClass(item = []) {
    if (isDesktop.matches) {
      item.querySelectorAll('.nav-item').forEach(((elm) => {
        elm.classList.remove('inactive');
      }));
    }
  }

  initBindings() {
    document.addEventListener('onHamburgerClick', this.toggleMenu);
    if (this.closeBtn) {
      this.closeBtn.addEventListener('click', this.hideMenu);
      this.closeBtn.addEventListener('mouseover', this.onCloseBtnHover);
    }
    if (this.navOverlay) {
      this.navOverlay.addEventListener('click', this.hideMenu);
    }
    if (this.backBtn) {
      this.backBtn.addEventListener('click', this.onBackBtnClick);
    }

    window.addEventListener(
      'resize',
      debounce(() => this.setNavContainerHeight(), 300)
    );

    this.setItemBindigs(0);
    this.setItemBindigs(1);
  }

  initDOM() {
    this.navContent = this.el.querySelector('.nav-content');
    this.navContainer = this.el.querySelector('.nav-container');
    this.closeBtn = this.el.querySelector('.nav-close-btn');
    this.backBtn = this.el.querySelector('.nav-back-btn');
    this.navOverlay = this.el.querySelector('.nav-overlay');

    this.items[0] = this.el.querySelectorAll(
      '.nav-panel.lvl1>li>.nav-item'
    );
    this.items[1] = this.el.querySelectorAll(
      '.nav-panel.lvl2>li>.nav-item'
    );

    this.panels[0] = this.el.querySelectorAll('.nav-panel.lvl1');
    this.panels[1] = this.el.querySelectorAll('.nav-panel.lvl2');
    this.panels[2] = this.el.querySelectorAll('.nav-panel.lvl3');

    this.focusable = this.el.querySelectorAll('a, button');
  }

  checkOverflow({ clientHeight, scrollHeight }) {
    return scrollHeight > clientHeight ? scrollHeight : 0;
  }

  setNavContainerHeight() {
    this.navContainer.style.height = '';
    let maxHeight = 0;

    this.panels.forEach((panels) => {
      panels.forEach((panel) => {
        if (this.checkOverflow(panel) > maxHeight) {
          maxHeight = this.checkOverflow(panel);
        }
      });
    });

    if (maxHeight) {
      this.navContainer.style.height = `${maxHeight}px`;
    }
  }

  init() {
    this.el = document.querySelector('.global-navigation');
    if (!this.el) return;

    this.initDOM();
    this.initMethods();
    this.initBindings();
    this.setNavContainerHeight();
  }
}

export const globalNavigation = new GlobalNavigation();
