/**
 *  @fileOverview Enquire form and phases handler form part
 *
 *  @author       Julia Ero <julia.ero@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     modules/enquire-form
 */

import forEach from 'lodash/forEach';
import EnquireForm from './enquire-form';

class FormController {
  init() {
    this.forms = [];
    this.overlayEnquire = document.querySelector('[data-enquire-form="overlay"]');
    this.initOverlayForm();
    this.initForms();
  }

  initOverlayForm() {
    if (
      this.overlayEnquire
      && this.overlayEnquire.getAttribute('data-form-inited') === null
    ) {
      this.overlayForm = new EnquireForm(this.overlayEnquire);
      this.overlayEnquire.setAttribute('data-form-inited', true);
    }
  }

  initForms() {
    forEach(document.querySelectorAll('[data-enquire-form]'), (form) => {
      if (form.getAttribute('data-form-inited') === null && form.getAttribute('data-enquire-form') !== 'overlay') {
        this.forms.push(new EnquireForm(form));
        form.setAttribute('data-form-inited', true);
      }
    });

    this.forms.push(this.overlayForm);
  }

  getFormById(id) {
    return this.forms.filter((form) => (form.id === id))[0];
  }
}

export const formController = new FormController();
