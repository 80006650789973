/**
 *  @fileOverview Service module
 *
 *  @author       Daniel Jung <daniel.jung@possible.com>
 *
 *  @requires     modules/api
 *  @requires     modules/general-errors
 */

import { api } from './api';
import { generalError } from './general-errors';

const DEBUG_HOSTS = /localhost/ig;
const LANG = document.body.getAttribute('data-lang') ? document.body.getAttribute('data-lang') : 'en-US';

class Service {

  constructor() {
    this.baseUrl = '';

    if (
      window.location
      && window.location.host
      && window.location.host.match(DEBUG_HOSTS) !== null
    ) {
      this.baseUrl = 'http://am-qa.azurewebsites.net';
    }
  }

  getModelPage(url) {
    return api.request(`${this.baseUrl}${url}?xhr=1`, 'get', {}, false, true)
      .then((res) => (res))
      .catch((err) => {
        if ((err || {}).message) {
          generalError.showError(err.message);
        }
      });
  }

  specs(model) {
    let result = {};
    return api.request(`${this.baseUrl}/Sitefinity/Public/Services/Car/CarService.svc/${LANG}/Car/${model}`)
      .then((res) => {
        if ((res || {}).data || {}) {
          result = res.data;
        }
        return result;
      })
      .catch((err) => {
        /* eslint-disable no-console */
        console.log('%c %s %s %s ', 'color: yellow; background-color: black;', '--', `Specs service error: ${err}`, '--');
        /* eslint-enable no-console */
      });
  }

  getModels() {
    let result = {};
    return api.request(`${this.baseUrl}/Sitefinity/Public/Services/Car/CarService.svc/${LANG}/BodyStyle`)
      .then((res) => {
        if ((res || {}).data || {}) {
          result = res.data;
        }
        return result;
      })
      .catch((err) => {
        if ((err || {}).message) {
          generalError.showError(err.message);
        }
      });
  }

  enquireForm(formData) {
    let result = {};
    return api.request(`${this.baseUrl}/api/enquire/add`, 'post', formData)
      .then((res) => {
        if ((res || {}).data || {}) {
          if (res.status === 400 && res.data.ModelState) {
            const errors = res.data.ModelState;
            const errorKeys = Object.keys(errors);
            /* eslint-disable no-return-assign */
            errorKeys.forEach((errKey) => result[errKey.split('.')[1]] = errors[errKey]);
            /* eslint-enable no-return-assign */
          } else {
            result = res.data;
          }
        }
        return result;
      })
      .catch((err) => {
        if ((err || {}).message) {
          generalError.showError(err.message);
        }
      });
  }

  getDealer() {
    let result = {};
    return api.request(`${this.baseUrl}/api/enquire/getDealers?cultureName=${LANG}`)
      .then((res) => {
        if ((res || {}).data || {}) {
          result = res.data;
        }
        return result;
      })
      .catch((err) => {
        if ((err || {}).message) {
          generalError.showError(err.message);
        }
      });
  }

  getSharePrice(endpoint) {
    let result = {};
    return endpoint ? api.request(endpoint)
      .then((res) => {
        if ((res || {}).data || {}) {
          result = res.data;
        }
        return result;
      })
      .catch((err) => {
        if ((err || {}).message) {
          generalError.showError(err.message);
        }
      }) : null;
  }

  search(term, searchIndex) {
    let result = {};
    return api.request(`${this.baseUrl}/Sitefinity/Public/Services/CustomSearch/CustomSearch.svc/${searchIndex}/${LANG}/SearchResults/?term=${term}&takeInGroup=3`)
      .then((res) => {
        if ((res || {}).data) {
          result = res.data;
        }
        if ((result || {}).Categories && result.Categories.length === 0) {
          result = { Categories: [{ Name: 'No result found', Results: [], TotalCount: 0 }] };
        }
        return result;
      })
      .catch((err) => {
        if ((err || {}).message) {
          generalError.showError(err.message);
        }
      });
  }

  getCarCarouselData(id) {
    let result = {};
    return api.request(`${this.baseUrl}/Sitefinity/Public/Services/Car/CarService.svc/${LANG}/CarPresets/${id}`)
      .then((res) => {
        if ((res || {}).data) {
          result = res.data;
        }
        return result;
      })
      .catch((err) => {
        /* eslint-disable no-console */
        console.log('%c %s %s %s ', 'color: yellow; background-color: black;', '--', `Car service error: ${err}`, '--');
        /* eslint-enable no-console */
      });
  }

  findDealers(lat, lon) {
    let result = {};
    return api.request(`${this.baseUrl}/api/enquire/findDealers?latitude=${lat}&longitude=${lon}&cultureName=${LANG}&take=150`)
      .then((res) => {
        if ((res || {}).data) {
          const data = res.data;
          result = data.map((dealerData) => ({
            distance: (dealerData || {}).DistanceMiles.toFixed(1) || '-',
            name: (dealerData || {}).Name || '-',
            address: `${((dealerData || {}).Address || {}).Street}, ${((dealerData || {}).Address || {}).City}, ${((dealerData || {}).Address || {}).Zip}` || '-',
            lat: ((dealerData || {}).Address || {}).Latitude || '-',
            lon: ((dealerData || {}).Address || {}).Longitude || '-',
            phone: (dealerData || {}).PhoneNumber || '-',
            website: (dealerData || {}).WebsiteURL || '-',
            dealer: (dealerData || {}).DealerCode,
          }));
        }
        return result;
      })
      .catch((err) => {
        if ((err || {}).message) {
          generalError.showError(err.message);
        }
      });
  }

}

export const service = new Service();
