/**
 *  @fileOverview Development helper
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *
 *  @requires     NPM:detect-it
 */

import { supportsPassiveEvents } from 'detect-it';

class Develop {

  init() {
    if (supportsPassiveEvents === true) {
      document.body.addEventListener('keyup', this.toggleDebug, { capture: false, passive: true });
    } else {
      document.body.addEventListener('keyup', this.toggleDebug, false);
    }
  }

  toggleDebug(event) {
    if (event.which === 71) {
      document.body.classList.toggle('debug');
    }
  }

}

export const develop = new Develop();
