/**
 *  @fileOverview Car and model accordion module
 *
 *  @author       Daniel Jung <daniel.jung@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     NPM:in-view
 *  @requires     modules/service
 *  @requires     modules/specs-template
 *  @requires     utils/easings
 */

import inView from 'in-view';
import forEach from 'lodash/forEach';
import { deviceType } from 'detect-it';
import { service } from './service';
import { specsTemplate } from './specs-template';
import {
  easeOutCubic, easeInSine, customHoverEasing, easeInOutCubic
} from '../utils/easings';

class Car {
  constructor(modelData, globCtx, globWidth, globHeight, pId, drawReqCB) {
    this.carImageData = modelData.car;
    this.shadowImageData = modelData.shadow;
    this.wheelImageData = {
      wheel: [modelData.wheel1, modelData.wheel2],
    };
    this.elapsed = modelData.elapsedTime;
    this.prev = modelData.prevTime;
    this.presetId = pId;
    this.prevCarPos = 0;
    this.currentWheelAngle = 0;
    this.animating = false;
    this.maxDistance = globWidth + (this.width / 2);
    this.currentPos = this.maxDistance;
    this.globalCtx = globCtx;
    this.globalCanvasHeight = globHeight;
    this.drawRequest = drawReqCB;
    this.timeProportion = 0;
    this.callBackDelay = 600;
    this.newPresetCB = specsTemplate.loadPresetSpecsById.bind(specsTemplate.getContext());
    this.newVersionCB = specsTemplate.loadModelVariationById.bind(specsTemplate.getContext());
    this.requestedCarCBCalled = false;
    this.customEasingError = 5;
  }

  roll(start, amount, duration, easingFunc, delay, requestedCar, newVariation) {
    if (delay) {
      setTimeout(() => {
        this.animate(start, amount, duration, easingFunc, requestedCar, newVariation);
      }, delay);
    } else {
      this.animate(start, amount, duration, easingFunc, requestedCar, newVariation);
    }
  }

  animate(start, am, duration, easingFunc, requestedCar, newVariation) {
    let startPos = start;
    let amount = am;
    if (!startPos) {
      startPos = this.currentPos;
    }
    if (!amount) {
      amount = -(this.currentPos + this.width);
    }
    this.animating = true;
    const currentTime = new Date().getTime();
    this.elapsedTime += this.prevTime !== 0 ? currentTime - this.prevTime : 0;
    const nextPos = easingFunc(
      this.elapsedTime, startPos, amount, duration
    );
    this.prevTime = currentTime;
    this.currentPos = nextPos;
    this.timeProportion = (nextPos - startPos) / amount;
    this.drawRequest(am);

    if (this.elapsedTime < duration) {
      if (this.elapsedTime + this.callBackDelay > duration && requestedCar) {
        if (!this.requestedCarCBCalled) {
          this.newPresetCB(this.presetId);
          if (newVariation) {
            this.newVersionCB(newVariation);
          }
        }
        this.requestedCarCBCalled = true;
      }
      window.requestAnimationFrame(() => {
        this.animate(startPos, amount, duration, easingFunc, requestedCar, newVariation);
      });
    } else {
      this.resetTimers();
      this.animating = false;
      if (Math.abs(this.currentPos - startPos) < this.customEasingError) {
        this.currentPos = startPos;
      } else {
        this.currentPos = (startPos + amount) < 0 ? this.maxDistance : (startPos + amount);
      }
      this.drawRequest(am);
    }
  }

  updateMaxPosition(newCanvasWidth) {
    this.maxDistance = newCanvasWidth + (this.width / 2);
  }

  draw(noTilt) {
    const proportion = this.scaleProportion(this.timeProportion);
    const scaleFactor = this.calcScaleFactor();
    const carPosX = (this.currentPos / scaleFactor) - (this.width / 2);
    const carPosY = (this.canvasHeight / scaleFactor) - (this.height * 1.2);
    const carPosXDelta = this.prevCarPos - carPosX;
    const wheelRad1 = this.wheelImageData.wheel[0].width / 2;
    const wheelRad2 = this.wheelImageData.wheel[1].width / 2;
    const k = wheelRad1 * 2 * Math.PI;
    this.prevCarPos = carPosX;
    this.currentWheelAngle -= (carPosXDelta / k) * 360 || 0;
    this.ctx.scale(scaleFactor, scaleFactor);
    this.ctx.drawImage(this.shadowImageData.image, carPosX, carPosY);
    this.drawCar(this.carImageData.image, carPosX, carPosY,
      this.width / 2, this.height / 2, proportion, noTilt
    );
    this.drawWheel(this.wheelImageData.wheel[0].image,
      (this.getWheelPosition(0, 'x') + carPosX) - wheelRad1,
      (this.getWheelPosition(0, 'y') + carPosY) - wheelRad1,
      wheelRad1,
      this.currentWheelAngle
    );
    this.drawWheel(this.wheelImageData.wheel[1].image,
      (this.getWheelPosition(1, 'x') + carPosX) - wheelRad2,
      (this.getWheelPosition(1, 'y') + carPosY) - wheelRad2,
      wheelRad2,
      this.currentWheelAngle
    );
    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
  }

  scaleProportion(proportion) {
    let newProportion = 0;
    if (proportion < 0.5) {
      newProportion = (((0.5 - Math.abs(0.5 - proportion))) * 0.6) + 0.2;
    } else {
      newProportion = ((0.5 - Math.abs(0.5 - proportion)));
    }
    return ((Math.abs(newProportion - 0.1)) - 0.1) * 4;
  }

  drawCar(image, x, y, rotationCenterX, rotationCenterY, proportion, noTilt) {
    if (noTilt) {
      this.ctx.drawImage(image, x, y);
    } else {
      const rad = (proportion / 180) * Math.PI;
      this.ctx.save();
      this.ctx.translate(x + rotationCenterX, y + rotationCenterY);
      this.ctx.rotate(rad);
      this.ctx.translate(-(x + rotationCenterX), -(y + rotationCenterY));
      this.ctx.drawImage(image, x, y);
      this.ctx.restore();
    }
  }

  drawWheel(image, x, y, wheelRadius, angle) {
    const rad = (angle / 180) * Math.PI;
    this.ctx.save();
    this.ctx.translate(x + wheelRadius, y + wheelRadius);
    this.ctx.rotate(rad);
    this.ctx.translate(-(x + wheelRadius), -(y + wheelRadius));
    this.ctx.drawImage(image, x, y);
    this.ctx.restore();
  }

  resetTimers() {
    this.elapsedTime = 0;
    this.prevTime = 0;
    this.timeProportion = 0;
    this.requestedCarCBCalled = false;
  }

  mapRange(value, low1, high1, low2, high2) {
    return low2 + (((high2 - low2) * (value - low1)) / (high1 - low1));
  }

  calcScaleFactor() {
    const currentWidth = window.innerWidth;
    let scaleFactor = 1;
    if (currentWidth >= 1000 && currentWidth < 1600) {
      scaleFactor = this.mapRange(currentWidth, 1000, 1700, 0.6, 1);
    }
    if (currentWidth >= 320 && currentWidth < 1000) {
      scaleFactor = this.mapRange(currentWidth, 320, 1000, 0.35, 0.8);
    }
    return scaleFactor;
  }

  getWheelPosition(index, type) {
    switch (type) {
      case 'x': {
        return this.carImageData.wheelPositions[index].x;
      }
      case 'y': {
        return this.carImageData.wheelPositions[index].y;
      }
      default: {
        return null;
      }
    }
  }

  get width() {
    return this.carImageData.width;
  }

  get height() {
    return this.carImageData.height;
  }

  get elapsedTime() {
    return this.elapsed;
  }

  set elapsedTime(val) {
    this.elapsed = val;
  }

  set currentPosition(val) {
    this.currentPos = val;
  }

  get prevTime() {
    return this.prev;
  }

  set prevTime(val) {
    this.prev = val;
  }

  get animationInProgress() {
    return this.animating;
  }

  get ctx() {
    return this.globalCtx;
  }

  get canvasHeight() {
    return this.globalCanvasHeight;
  }
}

class ModelAccordion {
  constructor() {
    this.canvasContainer = null;
    this.canvas = null;
    this.ctx = null;
    this.width = null;
    this.height = null;
    this.rollAmount = null;
    this.carVariations = {};
    this.currentVariation = {};
    this.currentCarIndex = 0;
    this.nextCarIndex = 1;
    this.rollInDuration = 3000;
    this.rollOutDuration = 1500;
    this.rollHoverDuration = 1200;
    this.wheelDetectionMaxThreshold = 10;
    this.noTiltAmount = 15;
    this.tabletSize = 1000;
    this.allImageLoaded = false;
    this.carouselError = false;
    this.nextCarHovered = false;
    this.assetLoadingStarted = false;
  }

  init() {
    this.initDOMElements();
    if (this.canvasContainer !== null) {
      this.initCanvas();
      this.initBindings();
    }
  }

  initDOMElements() {
    this.configurator = document.querySelector('[data-configurator]');
    this.configuratorMobileButton = document.querySelector('[data-configurator-mobile-next-button]');
    this.canvasContainer = document.querySelector('[data-model-accordion]');
    this.canvas = document.querySelector('[data-model-accordion-canvas]');
    this.carCarouselTabs = document.querySelectorAll('[data-variation-id]');
    this.currentCarData = document.querySelector('[data-model-id]');
    this.currentModelId = this.currentCarData
      ? this.currentCarData.getAttribute('data-model-id') : null;
    this.currentVersionId = this.currentCarData
      ? this.currentCarData.getAttribute('data-model-version-id') : null;
  }

  initCanvas() {
    if (this.canvas && this.canvasContainer) {
      this.ctx = this.canvas.getContext('2d');
      this.updateCanvasResolution();
    }
  }

  initCars(carVariations) {
    this.currentCarIndex = 0;
    this.nextCarIndex = 1;
    const carVariationImages = [];
    carVariations.forEach((carVar) => {
      const carPresetImages = carVar.Presets.map((v) => v.Assets);
      const data = this.parseImageData(carPresetImages);
      carVariationImages.push(
        new Promise((resolve, reject) => {
          Promise.all(data.images)
            .then((resolvedImages) => this.objectify(resolvedImages, data.imageKeys))
            .then((objectifiedData) => this.detectRelativeWheelPositions(objectifiedData))
            .then((resolvedPresets) => resolve(resolvedPresets))
            .catch((reason) => reject(reason));
        })
      );
    });

    Promise.all(carVariationImages)
      .then((variations) => {
        const variationIds = carVariations.map((v) => v.Id);
        variations.forEach((variation, variationIndex) => {
          this.carVariations[variationIds[variationIndex]] = {};
          this.carVariations[variationIds[variationIndex]].presets = [];
          variation.forEach((preset, presetIndex) => {
            const presetId = carVariations[variationIndex].Presets[presetIndex].Id;
            this.carVariations[variationIds[variationIndex]].presets.push(
              new Car(preset, this.ctx, this.width, this.height,
                presetId, this.drawRequested.bind(this)
              )
            );
            this.carVariations[variationIds[variationIndex]].carSequence = Array.from(
              { length: this.carVariations[variationIds[variationIndex]].presets.length },
              (v, i) => i
            );
          });
        });
        this.currentVariation = this.currentVersionId
          ? this.getVariation(this.currentVersionId) : null;
        if (this.currentVariation) {
          this.allImageLoaded = true;
        } else {
          this.carouselError = true;
        }
      })
      .catch((err) => {
        this.carouselError = true;
        /* eslint-disable no-console */
        console.log('%c %s %s %s ', 'color: yellow; background-color: black;', '--', `Model accordion error: ${err}`, '--');
        /* eslint-enable no-console */
      });
  }

  initBindings() {
    window.addEventListener('resize', this.updateCanvasResolution.bind(this));
    this.canvas.addEventListener('mousemove', this.handleHoverOverNextCar.bind(this));
    this.canvas.addEventListener('click', this.handleClickOnNextCar.bind(this));
    inView('[data-model-accordion]').on('enter', this.handleFirstLoad.bind(this));
    if (inView.is(this.canvasContainer)) {
      this.handleFirstLoad.call(this);
    }

    if (this.carCarouselTabs) {
      forEach(this.carCarouselTabs, (tab) => {
        tab.addEventListener('click', this.tabClickHandler.bind(this));
      });
    }

    if (this.configuratorMobileButton) {
      this.configuratorMobileButton.addEventListener('click', this.configuratorButtonHandler.bind(this));
    }
  }

  destroy() {
    if (this.carCarouselTabs) {
      forEach(this.carCarouselTabs, (tab) => {
        tab.removeEventListener('click', this.tabClickHandler);
      });
    }
    if (this.configuratorMobileButton) {
      this.configuratorMobileButton.removeEventListener('click', this.configuratorButtonHandler.bind(this));
    }
    window.removeEventListener('resize', this.updateCanvasResolution);
    if (this.canvas) {
      this.canvas.removeEventListener('mousemove', this.handleHoverOverNextCar);
      this.canvas.removeEventListener('click', this.handleClickOnNextCar);
    }
    inView('[data-model-accordion]').off('enter', this.handleFirstLoad);
    this.resetModuleVariables();
  }

  resetModuleVariables() {
    this.canvasContainer = null;
    this.canvas = null;
    this.ctx = null;
    this.width = null;
    this.height = null;
    this.rollAmount = null;
    this.carVariations = {};
    this.currentVariation = {};
    this.currentCarIndex = 0;
    this.nextCarIndex = 1;
    this.allImageLoaded = false;
    this.carouselError = false;
    this.nextCarHovered = false;
    this.assetLoadingStarted = false;
  }

  configuratorButtonHandler() {
    const isActive = this.configuratorMobileButton.classList.contains('active');

    if (this.isNoAnimationInProgress() && isActive) {
      this.nextCar();
    }
  }

  tabClickHandler(e) {
    e.preventDefault();
    const tab = e.currentTarget;
    if (this.isNoAnimationInProgress()) {
      forEach(this.carCarouselTabs, (t) => {
        t.classList.remove('active');
      });
      e.target.classList.add('active');
      const varId = tab.getAttribute('data-variation-id');
      this.rollNewVariation(varId);
      const newVariation = this.getVariation(varId);
      this.updateVersionId(varId);
      this.updatePresetId(newVariation.presets[0].presetId);
    }
  }

  handleHoverOverNextCar(e) {
    if (this.allImageLoaded) {
      const offsetRightX = this.width - e.clientX;
      if (this.currentVariation.presets[0].width / 2 > offsetRightX
        && this.currentVariation.presets.length > 1
      ) {
        this.canvas.style.cursor = 'pointer';
        if (
          !this.nextCarHovered
          && this.currentVariation.presets[this.nextCarIndex]
          && this.isNoAnimationInProgress()
        ) {
          this.nextCarHovered = true;
          this.rollHover(this.currentVariation.presets[this.nextCarIndex]);
        }
      } else {
        this.nextCarHovered = false;
        this.canvas.style.cursor = 'auto';
      }
    }
  }

  handleClickOnNextCar() {
    if (this.viewPortWidthLargerThan(this.tabletSize)) {
      if (this.nextCarHovered) {
        this.nextCar();
      }
    }
  }

  updateVersionId(newVersionId) {
    this.currentCarData.setAttribute('data-model-version-id', newVersionId);
  }

  updatePresetId(newPresetId) {
    this.currentCarData.setAttribute('data-version-preset-id', newPresetId);
  }

  getVariation(variationId) {
    return this.carVariations[variationId];
  }

  rollNewVariation(variationId) {
    if (this.isNoAnimationInProgress()) {
      let rollThroughNumber = 1;
      this.rollThrough(this.currentVariation.presets[this.currentCarIndex]);
      if (this.currentVariation.presets.length > 1) {
        rollThroughNumber = 2;
        this.rollThrough(
          this.currentVariation.presets[this.nextCarIndex],
          this.rollOutDuration / 2
        );
      }
      this.currentCarIndex = 0;
      this.nextCarIndex = 1;
      setTimeout(() => {
        this.currentVariation = this.getVariation(variationId);
        this.loadFirstCars(variationId);
      }, this.rollOutDuration * ((rollThroughNumber + 1) / 2));
    }
  }

  handleFirstLoad() {
    if (!this.assetLoadingStarted) {
      this.loadAssets();
      this.assetLoadingStarted = true;
    }
    if (!(this.canvasContainer.getAttribute('data-inited') === 'true')) {
      this.loadFirstCars();
    }
    this.canvasContainer.setAttribute('data-inited', 'true');
  }

  loadAssets() {
    if (this.currentModelId) {
      service.getCarCarouselData(this.currentModelId)
        .then((res) => {
          this.initCars(res.ModelVariations);
        })
        .catch((err) => {
          this.handleCarouselError();
          /* eslint-disable no-console */
          console.log('%c %s %s %s ', 'color: yellow; background-color: black;', '--', `Model accordion error: ${err}`, '--');
          /* eslint-enable no-console */
        });
    }
  }

  loadFirstCars(newVariation) {
    if (!this.allImageLoaded) {
      if (!this.carouselError) {
        setTimeout(() => {
          this.loadFirstCars(newVariation);
        }, 500);
      } else {
        this.handleCarouselError();
      }
    } else {
      if (this.configurator) {
        this.configurator.classList.remove('loading');
      }
      this.rollCenterFirst(this.currentVariation.presets[0], newVariation);
      this.rollIn(this.currentVariation.presets[1], newVariation);
      this.handleMobileButton();
    }
  }

  handleCarouselError() {
    if (this.configurator) {
      this.configurator.classList.remove('loading');
      this.configurator.classList.add('error');
    }
  }

  handleMobileButton() {
    this.configuratorMobileButton.classList.remove('active');

    if (this.currentVariation.presets.length > 1 && deviceType === 'touchOnly') {
      this.configuratorMobileButton.classList.add('active');
    }
  }

  drawRequested(am) {
    this.ctx.clearRect(0, 0, this.width, this.height);
    this.currentVariation.presets.forEach((car) => {
      if (Math.abs(am) < this.noTiltAmount * 2) {
        car.draw(true);
      } else {
        car.draw();
      }
    });
  }

  jumpTo(indexTo) {
    if (this.currentVariation.presets.length > 1) {
      if (this.isNoAnimationInProgress()) {
        const moveCounter = this.calcStepsToMove(indexTo);
        if (moveCounter !== 0) {
          let step = 0;
          while (this.currentVariation.carSequence[0] !== indexTo) {
            const delay = (this.rollOutDuration / 2) * step;
            this.rollThrough(this.currentVariation.presets[this.currentCarIndex], delay);
            this.setNextCar();
            step++;
          }
          if (moveCounter === 1) {
            this.rollCenter(this.currentVariation.presets[this.currentCarIndex]);
            this.rollIn(this.currentVariation.presets[this.nextCarIndex], true);
          } else {
            setTimeout(() => {
              this.rollCenterFirst(this.currentVariation.presets[this.currentCarIndex]);
              this.rollIn(this.currentVariation.presets[this.nextCarIndex], true);
            }, (this.rollOutDuration / 2) * moveCounter);
          }
        }
      }
    }
  }

  calcStepsToMove(indexTo) {
    let moveCounter = 0;
    if (indexTo < this.currentCarIndex) {
      moveCounter = (this.currentVariation.carSequence.length - this.currentCarIndex) + indexTo;
    } else if (indexTo === this.currentCarIndex) {
      moveCounter = 0;
    } else {
      moveCounter = indexTo - this.currentCarIndex;
    }
    return moveCounter;
  }

  nextCar() {
    if (this.currentVariation.presets.length > 1) {
      if (this.isNoAnimationInProgress()) {
        this.rollOut(this.currentVariation.presets[this.currentCarIndex]);
        this.setNextCar();
        this.updatePresetId(this.currentVariation.presets[this.currentCarIndex].presetId);
        this.rollCenter(this.currentVariation.presets[this.currentCarIndex]);
        this.rollIn(this.currentVariation.presets[this.nextCarIndex], true);
      }
    }
  }

  setNextCar() {
    this.currentCarIndex++;
    this.nextCarIndex++;
    if (this.currentCarIndex === this.currentVariation.presets.length) {
      this.currentCarIndex = 0;
    }
    if (this.nextCarIndex === this.currentVariation.presets.length) {
      this.nextCarIndex = 0;
    }
    this.arrayRotate(this.currentVariation.carSequence);
  }

  isNoAnimationInProgress() {
    if (this.currentVariation.presets) {
      return this.currentVariation.presets.map((car) => car.animationInProgress).every((el) => !el);
    }
    return true;
  }

  rollIn(car) {
    if (car) {
      const carWidth = car.width;
      let rollAmount = car.width * (3 / 4);
      if (this.viewPortWidthLargerThan(this.tabletSize)) {
        rollAmount = car.width;
      }
      car.roll(
        this.width + carWidth, -rollAmount, this.rollInDuration,
        easeOutCubic, this.rollOutDuration
      );
    }
  }

  rollCenterFirst(car, newVariation) {
    const carWidth = car.width;
    const requestedCar = !!newVariation;
    car.roll(
      this.width + carWidth, -this.rollAmount - carWidth,
      this.rollInDuration, easeOutCubic, null, requestedCar, newVariation
    );
  }

  rollCenter(car, newVariation) {
    if (this.viewPortWidthLargerThan(this.tabletSize)) {
      car.roll(
        this.width, -this.rollAmount, this.rollInDuration,
        easeInOutCubic, null, true, newVariation
      );
    } else {
      car.roll(
        null, -(this.rollAmount + (car.width / 4)), this.rollInDuration,
        easeInOutCubic, null, true, newVariation
      );
    }
  }

  rollOut(car, delay) {
    const carWidth = car.width;
    car.roll(
      this.width / 2, -this.rollAmount - carWidth,
      this.rollOutDuration, easeInSine, delay
    );
  }

  rollThrough(car, delay) {
    car.roll(
      null, null, this.rollOutDuration, easeInSine, delay
    );
  }

  rollHover(car, delay) {
    car.roll(
      null, -this.noTiltAmount, this.rollHoverDuration, customHoverEasing, delay
    );
  }

  updateCanvasResolution() {
    this.width = this.canvasContainer.clientWidth;
    this.height = this.canvasContainer.clientHeight;
    this.canvas.width = this.width;
    this.canvas.height = this.height;
    this.rollAmount = this.width / 2;
    if (this.allImageLoaded) {
      this.refreshCars();
    }
  }

  refreshCars() {
    const centeredCarIndex = this.currentVariation.carSequence[0];
    const nextCarIndex = this.currentVariation.carSequence[1];
    this.updateCurrentCarPositions(centeredCarIndex, nextCarIndex);
    this.currentVariation.presets[centeredCarIndex].draw();
    this.currentVariation.presets[nextCarIndex].draw();
  }

  updateCurrentCarPositions(centeredCarIndex, nextCarIndex) {
    this.currentVariation.presets.forEach((car, i) => {
      const currentCar = car;
      currentCar.updateMaxPosition(this.width);
      if (i === centeredCarIndex) {
        currentCar.currentPosition = this.width / 2;
      } else if (i === nextCarIndex) {
        if (this.viewPortWidthLargerThan(this.tabletSize)) {
          currentCar.currentPosition = this.width;
        } else {
          currentCar.currentPosition = this.width + (currentCar.width / 4);
        }
      } else {
        currentCar.currentPosition = this.width + currentCar.width;
      }
    });
  }

  parseImageData(carInfo) {
    const images = [];
    const imageKeys = Object.keys(carInfo[0]);
    carInfo.forEach((car, index) => {
      const carImages = this.loadImage(car, index);
      images.push(
        new Promise((resolve, reject) => {
          Promise.all(carImages)
            .then((resolvedImages) => resolve(resolvedImages))
            .catch((reason) => reject(reason));
        })
      );
    });
    return { images, imageKeys };
  }

  /* eslint-disable prefer-promise-reject-errors */
  loadImage(paths) {
    const keys = Object.keys(paths);
    const carImages = [];
    keys.forEach((key) => {
      carImages.push(
        new Promise((resolve, reject) => {
          const image = new Image();
          image.onload = () => resolve(image);
          image.onerror = () => reject(new Error(`Could not load image: ${paths[key]}`));
          image.src = paths[key];
        })
      );
    });
    return carImages;
  }
  /* eslint-enable */

  detectRelativeWheelPositions(data) {
    /* eslint-disable no-param-reassign */
    try {
      data.forEach((modelData) => {
        const canvasBuffer = document.createElement('canvas');
        const ctx = canvasBuffer.getContext('2d');
        const carImage = modelData.car.image;
        const w = modelData.car.width;
        const h = modelData.car.height;
        canvasBuffer.width = w;
        canvasBuffer.height = h;
        ctx.fillRect(0, 0, w, h);
        ctx.drawImage(carImage, 0, 0);
        const imageData = ctx.getImageData(0, 0, w, h);
        modelData.car.wheelPositions = this.detectWheelCenter(imageData.data, imageData.width, 0);
        if (modelData.car.wheelPositions.length < 2) {
          /* eslint-disable no-console */
          console.log('%c %s %s %s ', 'color: yellow; background-color: black;', '--', 'Could not detect wheel positions!', '--');
          /* eslint-enable no-console */
        }
        modelData.elapsedTime = 0;
        modelData.prevTime = 0;
      });
      return data;
    } catch (err) {
      this.handleCarouselError();
      /* eslint-disable no-console */
      console.log('%c %s %s %s ', 'color: yellow; background-color: black;', '--', 'Not allowed to use cross-origin images!', '--');
      /* eslint-enable no-console */
      return false;
    }
    /* eslint-enable no-param-reassign */
  }

  detectWheelCenter(imageData, width, threshold) {
    const wheelPositions = [];
    const length = imageData.length;
    let i = 0;
    for (i; i < length; i += 4) {
      const r = imageData[i];
      const g = imageData[i + 1];
      const b = imageData[i + 2];
      if (r <= 0 + threshold && g >= 255 - threshold && b <= 0 + threshold) {
        const x = (i / 4) % width;
        const y = ((i / 4) - x) / width;
        wheelPositions.push({ x, y });
      }
    }
    if (threshold < this.wheelDetectionMaxThreshold) {
      if (wheelPositions.length < 2) {
        return this.detectWheelCenter(imageData, width, threshold + 1);
      }
    }
    return wheelPositions;
  }

  objectify(source, keys) {
    const arrayOfArrays = source;
    arrayOfArrays.forEach((array, outerIndex) => {
      const obj = {};
      if (array.length === keys.length) {
        array.forEach((val, index) => {
          obj[this.toCamelCase(keys[index])] = {
            image: val,
            width: val.width,
            height: val.height,
          };
        });
        arrayOfArrays[outerIndex] = obj;
      }
    });
    return arrayOfArrays;
  }

  arrayRotate(arr) {
    arr.push(arr.shift());
  }

  viewPortWidthLargerThan(size) {
    return window.innerWidth > size;
  }

  toCamelCase(exp) {
    return `${exp[0].toLowerCase()}${exp.slice(1)}`;
  }
}

export const modelAccordion = new ModelAccordion();
