/**
 *  @fileOverview Popup Item module
 *
 *  @author       Szymon Grzybek <szymon.grzybek@possible.com>
 *
 */
import { lockScroll } from '../utils/lock-scroll';
import { lockTabbable } from '../utils/lock-tabbable';

export class PopupItem {
  constructor(el) {
    if (!(typeof el && el.nodeName)) {
      throw TypeError('Constructor parameter should be a valid DOM element!');
    }
    this.popupItem = el;
    this.redirectUrl = '/';
    this.tabables = null;

    if (this.initDOMElements()) {
      this.initModal();
      this.initBindings();
    }
  }

  initDOMElements() {
    const ths = this;
    ths.buttons = ths.popupItem.querySelector('.popup-buttons');
    if (!ths.buttons) return false;

    ths.acceptButton = ths.buttons.querySelector('[data-accept-button]');
    ths.declineButton = ths.buttons.querySelector('[data-decline-button]');
    if (!ths.declineButton) return false;

    ths.redirectUrl = ths.declineButton.getAttribute('href') || ths.redirectUrl;
    return true;
  }

  initModal() {
    const ths = this;
    ths.showModal();
  }

  initBindings() {
    const ths = this;
    ths.acceptButton.addEventListener('click', (e) => {
      e.preventDefault();
      ths.hidePopup();
    });
    ths.declineButton.addEventListener('click', () => {
      document.location.href = ths.redirectUrl;
    });
  }

  showModal() {
    lockScroll.lock(0);
    lockTabbable.lock('.popup');
    this.popupItem.classList.add('popup--visible');
    document.body.classList.add('overlay-active');
  }

  hidePopup() {
    lockScroll.unlock();
    lockTabbable.unlock();
    this.popupItem.classList.remove('popup--visible');
    document.body.classList.remove('overlay-active');
  }
}
