/**
 *  @fileOverview Count-uo controller, manages all the counters
 *
 *  @author       Daniel Jung <daniel.jung@possible.com>
 *
 *  @requires     NPM:in-view
 *  @requires     modules/count-up
 */

import inView from 'in-view';
import UpCounter from './count-up';

class CountUpController {
  init() {
    this.upCounters = [];

    inView('[data-count-up]').on('enter', (item) => {
      if (item.getAttribute('data-count-up-inited') !== 'true') {
        const amount = item.getAttribute('data-count-up');
        const splittedInput = this.processNumbers(amount.replace(',', '.'));
        this.upCounters.push(new UpCounter(item, splittedInput));
      }
    });
  }

  destroy() {
    inView('[data-count-up]').off('enter');
  }

  processNumbers(num) {
    return num.split(/(\d+\.*\d+)/g);
  }
}

export const countUpController = new CountUpController();
