/**
 *  @fileOverview Sitefinity (CMS) related helper functions
 *
 *  @author       Daniel Jung <daniel.jung@possible.com>
 *
 *  @requires     NPM:lodash
 */

import forEach from 'lodash/forEach';

class CmsJs {
  init() {
    if (document.querySelectorAll('.sfPageEditor').length > 0) {
      this.brandPageFix();
    }
  }

  brandPageFix() {
    forEach(document.querySelectorAll('.sfPageEditor [data-brand-video="1"]'), (brandVideo) => {
      brandVideo.parentNode.setAttribute('data-cms-brand-header-wrapper', 'true');
    });

    const cmsBrandModuleWrapper = document.querySelector('.sfPageEditor [data-sf-element="brandHeader"]');
    if (cmsBrandModuleWrapper) {
      const { children } = cmsBrandModuleWrapper;
      cmsBrandModuleWrapper.insertBefore(children[1], children[0]);
    }
  }
}

export const cmsJs = new CmsJs();
