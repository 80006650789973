/**
 *  @fileOverview Collection - model page transition handler
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     NPM:detect-it
 *  @requires     modules/module-controller
 *  @requires     modules/overlay-controller
 *  @requires     modules/service
 *  @requires     utils/device-properties
 *  @requires     utils/easings
 */

import forEach from 'lodash/forEach';
import throttle from 'lodash/throttle';
import { deviceType, supportsPassiveEvents } from 'detect-it';
import getDeviceProperties from '../utils/device-properties';
import { easeOutSine, easeOutExpo } from '../utils/easings';
import { service } from './service';
import { overlayController } from './overlay-controller';
import { moduleController } from './module-controller';
import { imageHandler } from './image-handler';

const MASK_START_WIDTH = window.innerWidth * 0.84;
const MASK_START_HEIGHT = window.innerHeight * 0.72;
const ZOOM_TRANSITION = 400;
const ZOOM_FACTOR = 0.95;

let BACKGROUND_TRANSITION_DURATION = 500;
let MASK_TRANSITION_DURATION = 500;
let BACKGROUND_IMAGE_TRANSITION = 500;

class CollectionMask {
  constructor() {
    this.inited = false;
    this.maskObjects = [];
    this.currentItem = null;
    this.currentMarkerId = null;
    this.pageTransitionActive = false;
    this.collectionOverlayObj = null;
    this.pageLoadTid = null;
    this.previousUrl = null;

    this.backgroundImageParams = {
      currentSrc: null,
      nextSrc: null,
      currentImage: null,
      nextImage: null,
      startTime: null,
      elapsedTime: null,
      currentAlpha: 0,
    };

    this.maskParams = {
      startHeight: MASK_START_HEIGHT,
      startWidth: MASK_START_WIDTH,
      currentWidth: MASK_START_WIDTH,
      currentHeight: MASK_START_HEIGHT,
      targetHeight: window.innerHeight,
      targetWidth: window.innerWidth,
      startTime: null,
      elapsedTime: null,
      zoomTriggered: false,
      zoom: ZOOM_FACTOR,
    };

    this.canvasWidth = 0;
    this.canvasHeight = 0;

    this.backgroundAnimationActive = false;
    this.backgroundImageAnimationActive = false;
    this.maskAnimationActive = false;
    this.zoomAnimationActive = false;
    this.pageTarget = null;
    this.navigationType = 'normal';

    /* eslint-disable no-console */
    try {
      this.initDOMElements();
    } catch (e) {
      console.warn(e);
    }
    /* eslint-enable */
  }

  init() {
    if (this.inited) {
      return;
    }

    if (deviceType === 'mouseOnly') {
      if (this.canvasWrapper) {
        BACKGROUND_TRANSITION_DURATION = parseInt(this.canvasWrapper.getAttribute('data-background-transition-duration'), 10);
        MASK_TRANSITION_DURATION = parseInt(this.canvasWrapper.getAttribute('data-mask-transition-duration'), 10);
        BACKGROUND_IMAGE_TRANSITION = parseInt(this.canvasWrapper.getAttribute('data-image-transition-duration'), 10);
      }

      this.setCanvasProperties();
      this.setBufferCanvasProperties();
      this.initMaskObjects();
      this.preloadImages();
    }
    this.collectionOverlayObj = overlayController.getOverlayById('collection-menu');
    this.inited = true;
  }

  setCanvasProperties() {
    this.canvas.width = getDeviceProperties.window.width;
    this.canvas.height = getDeviceProperties.window.height;
    this.canvasWidth = getDeviceProperties.window.width;
    this.canvasHeight = getDeviceProperties.window.height;
    this.canvas.style.width = `${getDeviceProperties.window.width}px`;
    this.canvas.style.height = `${getDeviceProperties.window.height}px`;
  }

  setBufferCanvasProperties() {
    this.bufferCanvas.width = this.canvas.width;
    this.bufferCanvas.height = this.canvas.height;
    this.bufferCanvas.style.width = this.canvas.style.width;
    this.bufferCanvas.style.height = this.canvas.style.height;

    this.canvasWidth = this.canvas.width;
    this.canvasHeight = this.canvas.height;
    this.canvas.style.transitionDuration = `${BACKGROUND_TRANSITION_DURATION}ms`;

    this.maskParams.startWidth = window.innerWidth * 0.84;
    this.maskParams.startHeight = window.innerHeight * 0.72;
    this.maskParams.currentWidth = window.innerWidth * 0.84;
    this.maskParams.currentHeight = window.innerHeight * 0.72;
  }

  initDOMElements() {
    this.collectionOverlay = document.body.querySelector('[data-overlay="collection-menu"]');
    if (this.collectionOverlay) {
      this.canvasWrapper = this.collectionOverlay.querySelector('[data-canvas-wrapper]');
      this.canvas = this.collectionOverlay.querySelector('canvas');
      this.ctx = this.canvas.getContext('2d');
    }
    this.bufferCanvas = document.createElement('canvas');
    this.bufferCtx = this.bufferCanvas.getContext('2d');
    this.heroAModuleImg = document.body.querySelector('.hero.hero--a .cover-image');

    if (this.heroAModuleImg) {
      this.heroAModuleImg.style.width = `calc(100% + ${getDeviceProperties.scrollbarWidth}px)`;
    }
  }

  initBindings() {
    window.addEventListener('resize', throttle(() => {
      this.setCanvasProperties();
      this.setBufferCanvasProperties();
    }, 100));

    forEach(this.collectionOverlay.querySelectorAll('li:not([disabled])'), (item) => {
      item.addEventListener('mouseover', (e) => {
        if (this.pageTransitionActive === false) {
          this.setItem(e.currentTarget);
          this.setBackgroundColor();
          this.setBackgroundImage();
        }
      }, supportsPassiveEvents ? { capture: false, passive: true } : false);
      item.addEventListener('mouseleave', () => {
        if (this.pageTransitionActive === false) {
          this.resetBackgroundColor();
          this.resetBackgroundImage();
        }
      }, supportsPassiveEvents ? { capture: false, passive: true } : false);
    });

    forEach(this.collectionOverlay.querySelectorAll('li'), (item) => {
      item.querySelector('a').addEventListener('click', (e) => {
        this.pageTransition(e.currentTarget, e);
      }, false);
    });

    window.addEventListener('popstate', () => {
      if (this.previousUrl !== null && window.location.hash === '') {
        window.location = this.previousUrl;
      }
    });
  }

  initMaskObjects() {
    const { containerWidth } = imageHandler.getContainerSize({ el: this.canvasWrapper });
    const imgWidth = parseInt(containerWidth * getDeviceProperties.pixelRatio, 10);
    let src;
    let srcParts;
    let srcBase;
    let srcParametrized;

    forEach(this.collectionOverlay.querySelectorAll('[data-collection-mask-src]'), (item) => {
      src = item.getAttribute('data-collection-mask-src');
      srcParts = src.split('&');
      [srcBase] = srcParts;
      srcParametrized = srcBase;

      if (srcBase.match(/\?/ig) !== null) {
        srcParametrized = `${srcBase}&w=${imgWidth}&quality=65`;
      } else {
        srcParametrized = `${srcBase}?w=${imgWidth}&quality=65`;
      }

      this.maskObjects.push({
        id: item.getAttribute('data-collection-mask'),
        src: srcParametrized,
        color: item.getAttribute('data-collection-color'),
        img: new Image(),
        item,
      });
    });
  }

  preloadImages() {
    Promise.all([...this.loadMaskImages()]).then(() => {
      this.maskingEnabled = true;
      this.initBindings();
      this.animate();
    }).catch((reason) => {
      /* eslint-disable no-console */
      console.log('%c %s %s %s ', 'color: yellow; background-color: black;', '--', reason, '--');
      /* eslint-enable no-console */
    });
  }

  loadMaskImages() {
    const maskImages = [];
    forEach(this.maskObjects, (obj) => {
      /* eslint-disable no-param-reassign */
      maskImages.push(new Promise((resolve) => {
        const image = new Image();
        if (!obj.src) {
          /* eslint-disable no-console */
          console.log('%c %s %s %s ', 'color: yellow; background-color: black;', '--', `No image set for the ${obj.id && obj.id.toUpperCase()} model! Please set it in the CMS!`, '--');
          /* eslint-enable no-console */
          obj.item.setAttribute('disabled', true);
          resolve();
        } else {
          image.onload = () => {
            obj.img = image;
            obj.width = image.width;
            obj.height = image.height;
            resolve(image);
          };
          image.onerror = (() => {
            obj.item.setAttribute('disabled', true);
            /* eslint-disable no-console */
            console.log('%c %s %s %s ', 'color: yellow; background-color: black;', '--', `Could not load image for the model ${obj.id && obj.id.toUpperCase()}!`, '--');
            /* eslint-enable no-console */
            resolve();
          });
          image.src = obj.src;
        }
      }));
      /* eslint-enable no-param-reassign */
    });
    return maskImages;
  }

  getMaskItemById(id) {
    return this.maskObjects.filter((mask) => (mask.id === id))[0];
  }

  setItem(sender) {
    this.currentItem = this.getMaskItemById(sender.getAttribute('data-collection-mask'));
  }

  setBackgroundColor() {
    this.canvas.style.background = this.currentItem.color;
  }

  setBackgroundImage() {
    this.backgroundImageParams.nextImage = this.currentItem.img;
    this.backgroundImageParams.startTime = Date.now();
    this.backgroundImageAnimationActive = true;
  }

  setMask() {
    this.maskParams.startTime = Date.now();
    this.maskAnimationActive = true;
  }

  setZoom() {
    this.maskParams.zoomStartTime = Date.now();
    this.maskParams.zoom = ZOOM_FACTOR;
    this.zoomAnimationActive = true;
  }

  resetBackgroundColor() {
    this.canvas.style.background = 'transparent';
  }

  resetBackgroundImage() {
    this.backgroundImageParams.currentImage = this.backgroundImageParams.nextImage;
    this.backgroundImageParams.nextImage = null;
    this.backgroundImageParams.startTime = Date.now();
    this.backgroundImageAnimationActive = true;
  }

  resetMask() {
    this.maskParams.startTime = Date.now();
    this.maskParams.zoom = ZOOM_FACTOR;
    this.maskAnimationActive = false;
  }

  resetZoom() {
    this.maskParams.zoomStartTime = Date.now();
    this.maskParams.zoom = ZOOM_FACTOR;
    this.zoomAnimationActive = false;
  }

  animate() {
    if (
      this.backgroundImageAnimationActive
      || this.maskAnimationActive
      || this.zoomAnimationActive
    ) {
      this.clearCanvas();
      if (this.maskAnimationActive) {
        this.animateMask();
      }
      if (this.zoomAnimationActive) {
        this.animateZoom();
      }
      this.animateBackgroundImage();
      this.ctx.drawImage(this.bufferCanvas, 0, 0);
    }
    this.requestID = requestAnimationFrame(this.animate.bind(this));
  }

  animateMask() {
    const elapsedTime = Date.now() - this.maskParams.startTime;
    const duration = MASK_TRANSITION_DURATION;
    const amountWidth = window.innerWidth - this.maskParams.startWidth;
    const amountHeight = window.innerHeight - this.maskParams.startHeight;
    const ended = elapsedTime > duration;

    this.maskParams.currentWidth = Math.floor(easeOutExpo(
      elapsedTime,
      this.maskParams.startWidth,
      amountWidth,
      duration
    ));

    this.maskParams.currentHeight = Math.floor(easeOutExpo(
      elapsedTime,
      this.maskParams.startHeight,
      amountHeight,
      duration
    ));

    if (
      elapsedTime > 75
      && this.maskParams.zoomTriggered === false
    ) {
      this.maskParams.zoomTriggered = true;
      this.setZoom();
    }

    if (ended) {
      this.maskAnimationActive = false;
      this.maskParams.zoomTriggered = false;
      this.maskParams.currentWidth = window.innerWidth;
      this.maskParams.currentHeight = window.innerHeight;
    }
  }

  animateZoom() {
    const elapsedTime = Date.now() - this.maskParams.zoomStartTime;
    const duration = ZOOM_TRANSITION;
    const ended = elapsedTime > duration;

    this.maskParams.zoom = Math.ceil(easeOutSine(
      elapsedTime,
      ZOOM_FACTOR * 1000,
      1000 - (ZOOM_FACTOR * 1000),
      duration
    )) / 1000;

    if (ended) {
      this.zoomAnimationActive = false;
      this.maskParams.zoom = 1;
    }
  }

  animateBackgroundImage() {
    const elapsedTime = Date.now() - this.backgroundImageParams.startTime;
    const duration = BACKGROUND_IMAGE_TRANSITION;
    const isMouseOut = this.backgroundImageParams.currentImage !== null
      && this.backgroundImageParams.nextImage === null;
    const ended = elapsedTime > duration;
    const offsetX = (this.canvasWidth - (this.canvasWidth * this.maskParams.zoom)) / 2;
    const offsetY = (this.canvasHeight - (this.canvasHeight * this.maskParams.zoom)) / 2;
    const canvasWidth = this.canvasWidth * this.maskParams.zoom;
    const canvasHeight = this.canvasHeight * this.maskParams.zoom;

    let start;
    let amount;

    if (this.backgroundImageParams.currentImage === this.backgroundImageParams.nextImage) {
      start = this.backgroundImageParams.currentAlpha;
      amount = 1 - start;
    } else {
      start = 0;
      amount = 1;
    }

    if (isMouseOut) {
      start = 1;
      amount = -1;
    }

    let alpha = easeOutSine(
      elapsedTime,
      start,
      amount,
      duration
    );

    if (ended) {
      this.backgroundImageParams.currentImage = this.backgroundImageParams.nextImage;
      this.backgroundImageAnimationActive = false;
      alpha = 1;
    }

    this.backgroundImageParams.currentAlpha = alpha;
    this.bufferCtx.save();

    if (
      this.backgroundImageParams.currentImage !== null
      && !isMouseOut
      && this.backgroundImageParams.currentImage !== this.backgroundImageParams.nextImage
    ) {
      this.drawCoverImage(
        this.bufferCtx, this.backgroundImageParams.currentImage,
        offsetX, offsetY,
        canvasWidth, canvasHeight
      );
    }

    if (this.backgroundImageParams.nextImage !== null && !isMouseOut) {
      this.bufferCtx.save();
      this.bufferCtx.globalAlpha = alpha;
      this.drawCoverImage(
        this.bufferCtx, this.backgroundImageParams.nextImage,
        offsetX, offsetY,
        canvasWidth, canvasHeight
      );
      this.bufferCtx.restore();
    }

    if (isMouseOut && this.backgroundImageParams.currentImage !== null) {
      this.bufferCtx.save();
      this.bufferCtx.globalAlpha = alpha;
      this.drawCoverImage(
        this.bufferCtx, this.backgroundImageParams.currentImage,
        offsetX, offsetY,
        canvasWidth, canvasHeight
      );
      this.bufferCtx.restore();
    }

    const maskX = (this.canvasWidth - this.maskParams.currentWidth) / 2;
    const maskY = (this.canvasHeight - this.maskParams.currentHeight) / 2;

    this.bufferCtx.globalCompositeOperation = 'destination-in';
    this.bufferCtx.fillStyle = 'green';
    this.bufferCtx.fillRect(
      maskX,
      maskY,
      this.maskParams.currentWidth,
      this.maskParams.currentHeight
    );

    this.bufferCtx.restore();
  }

  drawCoverImage(
    ctx, img, x = 0, y = 0, w = this.canvasWidth,
    h = this.canvasHeight, offsetX = 0.5, offsetY = 0.5
  ) {
    const iw = img.width;
    const ih = img.height;
    const params = this.calculateCover(iw, ih, x, y, w, h, offsetX, offsetY);

    ctx.drawImage(
      img,
      params.cx, params.cy, params.cw, params.ch,
      params.x, params.y, params.w, params.h
    );

    ctx.fillStyle = 'rgba(0, 0, 0, .3)';
    ctx.fillRect(0, 0, this.canvasWidth, this.canvasHeight);
  }

  calculateCover(iw, ih, x, y, w, h, offsetX = 0.5, offsetY = 0.5) {
    const r = Math.min(w / iw, h / ih);
    let nw = iw * r;
    let nh = ih * r;
    let cx = 1;
    let cy = 1;
    let cw = 1;
    let ch = 1;
    let ar = 1;

    if (nw < w) {
      ar = w / nw;
    }

    if (Math.abs(ar - 1) < 1e-14 && nh < h) {
      ar = h / nh;
    }

    nw *= ar;
    nh *= ar;

    cw = iw / (nw / w);
    ch = ih / (nh / h);
    cx = (iw - cw) * offsetX;
    cy = (ih - ch) * offsetY;

    if (cx < 0) {
      cx = 0;
    }

    if (cy < 0) {
      cy = 0;
    }

    if (cw > iw) {
      cw = iw;
    }

    if (ch > ih) {
      ch = ih;
    }

    return {
      cx, cy, cw, ch, x, y, w, h, ar, nw, nh,
    };
  }

  clearCanvas() {
    this.bufferCtx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
  }

  pageNavigation() {
    window.location.href = this.pageTarget;
  }

  pageTransition(sender, event) {
    this.pageTarget = (sender && sender.getAttribute('href')) || false;
    this.navigationType = sender.getAttribute('data-xhr') !== null ? 'xhr' : 'normal';

    if (this.pageTarget && this.navigationType === 'xhr'
      && !(window.navigator.userAgent.indexOf('MSIE') > 0
        || !!window.navigator.userAgent.match(/Trident\/7\./)
      )
    ) {
      event.preventDefault();
      this.setMask();
      this.pageTransitionActive = true;
      this.collectionOverlay.classList.add('overlay--pageload');
      this.collectionOverlay.classList.add('overlay--pageload-progress');
      Promise.all([service.getModelPage(this.pageTarget)])
        .then((result) => {
          this.collectionOverlay.classList.remove('overlay--pageload-progress');
          window.clearTimeout(this.pageLoadTid);
          this.pageLoadTid = window.setTimeout(() => {
            this.renderModelPage(result && result[0] && result[0].data);
          }, 500);
        })
        .catch((err) => {
          /* eslint-disable no-console */
          console.log('%c %s %s %s ', 'color: yellow; background-color: black;', '--', `Page transition error: ${err}`, '--');
          /* eslint-enable no-console */
        });
    }
  }

  renderModelPage(result) {
    const tempContainer = document.createElement('div');
    const mainContainer = document.body.querySelector('main');
    const tempLinkTag = document.createElement('a');
    let tempImage;
    let tempMain;
    let tempSrc;
    let pageColor;
    let title;
    let modelId;
    let campaignId;

    if (result) {
      tempLinkTag.href = this.pageTarget;
      tempContainer.innerHTML = result;
      tempMain = tempContainer.querySelector('main');
      title = tempContainer.querySelector('title').innerHTML;
      pageColor = (tempMain && tempMain.getAttribute('data-page-color')) || '#000';
      modelId = (tempMain && tempMain.getAttribute('data-model-name')) || '';
      campaignId = (tempMain && tempMain.getAttribute('data-enquire-campaign')) || '';

      this.previousUrl = window.location.href;
      const tempLinkUrl = (tempLinkTag.pathname[0] !== '/')
        ? `/${tempLinkTag.pathname}` : tempLinkTag.pathname;
      history.pushState({
        originalUrl: window.location.href,
      }, title, tempLinkUrl);
      document.body.setAttribute('data-page', 'model-page');

      if (tempMain) {
        mainContainer.innerHTML = tempMain.innerHTML;
        mainContainer.setAttribute('data-page-color', pageColor);
        mainContainer.setAttribute('data-model-name', modelId);
        mainContainer.setAttribute('data-enquire-campaign', campaignId);
        mainContainer.style.backgroundColor = pageColor;

        document.title = title;

        this.heroAModuleImg = mainContainer.querySelector('.hero.hero--a .cover-image');
        if (this.heroAModuleImg) {
          this.heroAModuleImg.style.width = `calc(100% + ${getDeviceProperties.scrollbarWidth}px)`;
        }

        if (mainContainer.querySelector('.hero.hero--a .cover-image img')) {
          moduleController.destroyModules();
          window.scrollTo(0, 0);
          // reinit main background after firing homepage-controller destroy method.
          mainContainer.style.backgroundColor = pageColor;
          moduleController.initModules();
          tempImage = new Image();
          tempImage.onload = () => {
            this.pageLoadDone();
          };
          tempImage.onerror = () => {
            /* eslint-disable no-console */
            console.log('%c %s %s %s ', 'color: yellow; background-color: black;', '--', 'Hero A image couldn\'t be loaded!', '--');
            /* eslint-enable no-console */
            this.pageLoadDone();
          };
          tempSrc = mainContainer.querySelector('.hero.hero--a .cover-image img').getAttribute('src');
          tempImage.src = tempSrc;
        } else {
          this.pageLoadDone();
        }
      }
    }
  }

  pageLoadDone() {
    this.collectionOverlay.classList.remove('overlay--pageload');
    overlayController.closeOverlay('collection-menu', true);
    this.pageTransitionActive = false;

    this.maskParams = {
      startHeight: MASK_START_HEIGHT,
      startWidth: MASK_START_WIDTH,
      currentWidth: MASK_START_WIDTH,
      currentHeight: MASK_START_HEIGHT,
      targetHeight: window.innerHeight,
      targetWidth: window.innerWidth,
      startTime: null,
      elapsedTime: null,
      zoomTriggered: false,
      zoom: ZOOM_FACTOR,
    };

    this.resetBackgroundImage();
    this.resetBackgroundImage();
    this.resetMask();
    this.resetZoom();
    this.clearCanvas();
  }
}

export const collectionMask = new CollectionMask();
