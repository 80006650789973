/**
 *  @fileOverview Slider, pan handler controller
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *  @author       Julia Ero <julia.ero@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     NPM:detect-it
 *  @requires     NPM:hammerjs
 *  @requires     modules/gallery-controller
 *  @requires     modules/image-handler
 *  @requires     modules/enquire
 *  @requires     utils/accessibility
 */

import forEach from 'lodash/forEach';
import debounce from 'lodash/debounce';
import Hammer from 'hammerjs';
import { enquireController } from './enquire-controller';
import { galleryController } from './gallery-controller';
import { accessibilityHelper } from '../utils/accessibility';
import { imageHandler } from './image-handler';

const NEXT_KEY = 39;
const PREV_KEY = 37;

class Slider {
  constructor(slider) {
    this.slider = slider;
    this.id = slider.getAttribute('data-slider');
    this.isInSliderMode = this.slider.getAttribute('data-gallery-slider') !== null;
    this.hammer = null;
    this.itemCount = 0;
    this.currentItem = 0;
    this.currentPosition = 0;
    this.sliderWidth = 0;
    this.screenWidth = 0;
    this.imagesID = '';
    this.inited = false;
    this.enabled = true;
    this.isEnquire = this.slider.hasAttribute('data-enquire-slider');
    this.isDynamic = this.slider.hasAttribute('data-slider-dynamic');
    this.withImageSync = this.slider.hasAttribute('data-sync-imagelist');
    this.sliderPager = this.slider.querySelector('.pager');
    this.sliderPagerBtns = this.sliderPager.querySelectorAll('.pager__button');
    this.isTouch = document.getElementsByTagName('html')[0].classList.contains('touch');

    if (!this.isDynamic && this.inited === false) {
      this.init();
    }
  }

  init() {
    if (this.inited) {
      return;
    }

    this.screenWidth = window.innerWidth;
    this.initDOMElements();
    this.initBindings();

    if (this.sliderButtons !== undefined) {
      this.disableButtons();
    }

    if (this.isSliderEnabled()) {
      this.initHammer();
    }
    this.inited = true;
  }

  initDOMElements() {
    this.items = this.slider.querySelectorAll('[data-slider-item]');
    this.row = this.slider.querySelector('[data-slider-row]');
    this.itemCount = this.items.length;
    this.sliderWidth = this.row.offsetWidth ? this.row.offsetWidth : 200;
    this.screenWidth = window.innerWidth;
    this.sliderButtons = this.slider.querySelectorAll('[data-slider-button]');

    if (this.withImageSync) {
      this.imagesID = this.slider.getAttribute('data-sync-imagelist');
      this.relatedImageList = document.querySelector(`[data-slider-imagelist="${this.imagesID}"]`);
      this.relatedImages = this.relatedImageList.querySelectorAll('[data-slider-image]');
    }

  }

  initBindings() {
    window.addEventListener('resize', debounce(() => {
      if (this.screenWidth !== window.innerWidth) {
        this.sliderWidth = this.row.offsetWidth;
        this.screenWidth = window.innerWidth;
        if (this.enabled) {
          if (this.isSliderEnabled()) {
            this.initHammer();
          } else {
            this.currentPosition = 0;
            this.currentItem = 0;
            if (this.slider.hasAttribute('data-sync-imagelist')) {
              this.syncImages();
            }
            this.row.removeAttribute('style');
            if (this.hammer !== null) {
              this.hammer.destroy();
              this.hammer = null;
              this.slider.removeAttribute('style');
            }
          }
        }
      }
    }, 250));

    if (this.sliderButtons !== undefined) {
      forEach(this.sliderButtons, (item) => {
        item.addEventListener('click', (e) => {
          this.onSliderButton(e);
        });
        accessibilityHelper.subscribe(item, 'open', (e) => {
          this.onSliderButton(e);
        });
      });
    }

    this.row.addEventListener('keydown', (e) => {
      const code = parseInt(e.keyCode, 10);

      if ([NEXT_KEY, PREV_KEY].indexOf(code) >= 0) {
        this.onKeyDown(code);
      }
    });
    if (this.isInSliderMode) {
      forEach(this.sliderPagerBtns, (item, index) => {
        item.addEventListener('click', () => {

          this.currentItem = index;
          this.slideTo();
          this.syncImages();
          this.disableButtons();
        });
      });
    }
  }

  initHammer() {
    this.hammer = new Hammer.Manager(this.slider);
    this.hammer.add(new Hammer.Pan({ direction: Hammer.DIRECTION_HORIZONTAL, threshold: 0 }));
    this.hammer.on('pan', (e) => {
      if (this.enabled && this.isTouch) {
        this.onPan(e);
      }
    });
  }

  isSliderEnabled() {
    return this.itemCount > 1 && this.hammer === null && this.screenWidth <= 1024;
  }

  onPan(event) {
    const actualPosition = Math.min(
      Math.max(
        -1 * ((this.sliderWidth * (this.itemCount - 1)) + 80), this.currentPosition + event.deltaX
      ),
      80
    );

    if (this.row.classList.contains('no-transition') === false) {
      this.row.classList.add('no-transition');
    }
    this.row.style.transform = `translate3d(${actualPosition}px, 0, 0)`;

    if (event.eventType === Hammer.INPUT_END) {
      this.currentPosition = actualPosition;

      if (Math.abs(event.velocityX) > 0.5) {
        this.currentItem += event.velocityX < 0 ? 1 : -1;
      } else {
        this.currentItem = Math.round(Math.abs(actualPosition) / this.sliderWidth);
      }

      if (this.currentItem > this.itemCount - 1) {
        this.currentItem = this.itemCount - 1;
      }

      if (this.currentItem < 0) {
        this.currentItem = 0;
      }

      this.row.classList.remove('no-transition');
      this.slideTo();
      this.activateStyle();
      this.syncImages();

      if (this.id === 'gallery') {
        const gallery = galleryController.getGalleryById(this.slider.getAttribute('data-gallery'));

        gallery.onPan(this.currentItem);
        gallery.setPager();
      }
    }

    this.getEnquireSlides();
  }

  onKeyDown(code) {
    const slideCount = this.isEnquire
      ? Math.ceil(this.itemCount / 3)
      : this.itemCount;

    if (code === PREV_KEY) {
      this.currentItem = this.currentItem === 0
        ? this.currentItem : this.currentItem -= 1;
    } else if (code === NEXT_KEY) {
      this.currentItem = this.currentItem === slideCount - 1
        ? this.currentItem : this.currentItem += 1;

      this.getEnquireSlides();
    }

    this.slideTo();
    this.syncImages();
    this.disableButtons();
  }

  onSliderButton(e) {
    const direction = e.currentTarget.hasAttribute('data-slider-next') ? 1 : -1;
    const disabledClass = e.currentTarget.classList.contains('button') ? 'button--disabled' : 'disabled';

    if (!e.currentTarget.classList.contains(disabledClass)) {
      if (direction > 0) {
        this.currentItem = this.currentItem === this.itemCount - 1
          ? this.currentItem : this.currentItem += 1;
      } else {
        this.currentItem = this.currentItem === 0
          ? this.currentItem : this.currentItem -= 1;
      }

      this.slideTo();
      this.syncImages();
      this.disableButtons();
    }
  }

  disableButtons() {
    const slideCount = this.isEnquire
      ? Math.ceil(this.itemCount / 3)
      : this.itemCount;

    forEach(this.sliderButtons, (button) => {
      const disabledClass = button.classList.contains('button') ? 'button--disabled' : 'disabled';

      if (button.hasAttribute('data-slider-prev') && this.currentItem === 0) {
        button.classList.add(disabledClass);
      } else if (button.hasAttribute('data-slider-next') && this.currentItem === slideCount - 1) {
        button.classList.add(disabledClass);
      } else {
        button.classList.remove(disabledClass);
      }
    });
  }

  slideTo(index = this.currentItem) {
    const margin = this.isEnquire
      ? parseInt(window.getComputedStyle(this.slider.querySelector('[data-slider-item]')).marginRight, 10) : 0;
    const distance = `${(this.row.clientWidth + margin) * index * -1}px`;

    this.row.style.transform = `translate3d(${distance}, 0, 0)`;
    this.currentPosition = this.currentItem * this.sliderWidth * -1;
    imageHandler.checkAndLoad();
    this.handleFocus(this.currentItem);
  }

  handleFocus(index = this.currentItem) {
    const currentItems = this.isEnquire ? [index * 3, (index * 3) + 1, (index * 3) + 2] : [index];

    forEach(this.items, (item, i) => {
      if (currentItems.indexOf(i) >= 0) {
        accessibilityHelper.enableFocus(item);
      } else {
        accessibilityHelper.disableFocus(item);
      }
    });
  }

  getEnquireSlides() {
    if (this.isEnquire) {
      const enquire = enquireController.getEnquireById(this.id);
      enquire.loadMoreDealers();
    }
  }

  activateStyle() {
    if (this.screenWidth <= 1024
      && (this.id === 'stats' || this.id === 'specs')
    ) {
      forEach(this.items, (item, index) => {
        this.currentItem = Math.abs(this.currentPosition / this.sliderWidth);

        if (index === this.currentItem) {
          item.classList.add('active');
        } else {
          item.classList.remove('active');
        }
      });
    }
  }

  syncImages() {
    if (this.withImageSync) {
      forEach(this.relatedImages, (item, index) => {
        if (index === this.currentItem) {
          item.classList.remove('hidden');
        } else {
          item.classList.add('hidden');
        }
      });
    }
  }

  enableSlider() {
    this.enabled = true;
  }

  disableSlider() {
    this.enabled = false;
  }
}

export default Slider;
