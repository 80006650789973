/**
 *  @fileOverview Quote module
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *  @author       Julia Ero <julia.ero@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     NPM:detect-it
 *  @requires     modules/video-controller
 *  @requires     utils/accessibility
 */

import forEach from 'lodash/forEach';
import { supportsPassiveEvents } from 'detect-it';
import { videoController } from './video-controller';
import { accessibilityHelper } from '../utils/accessibility';

class Quote {
  constructor(quote) {
    if (!quote) {
      throw TypeError('Constructor parameter should be a valid quote element!');
    }

    this.quote = quote;
    this.itemCount = 0;
    this.itemTypes = [];
    this.currentItem = 0;
    this.isSingle = this.quote.getAttribute('data-quote-single') !== null;
    this.isAnimating = false;

    this.initDOMElements();
    this.initBindings();
    this.initQuoteState();
  }

  initDOMElements() {
    if (this.quote) {
      this.images = this.quote.querySelectorAll('[data-quote-image]');

      forEach(this.images, (media) => {
        const videoId = media.getAttribute('data-quote-video');
        if (videoId !== null) {
          this.itemTypes.push({
            type: 'video',
            id: videoId,
            player: videoController.getPlayerById(videoId),
          });
        } else {
          this.itemTypes.push({ type: 'image' });
        }
      });

      this.quotes = this.quote.querySelectorAll('[data-quote-item]');
      this.nav = this.quote.querySelector('[data-quote-nav]');
      if (this.nav) {
        this.prevBtn = this.nav.querySelector('[data-quote-btn="prev"]');
        this.nextBtn = this.nav.querySelector('[data-quote-btn="next"]');
      }
      this.itemCount = this.images.length;
    }
  }

  initBindings() {
    forEach(this.images, (image) => {
      image.addEventListener('transitionend', (e) => {
        const item = e.currentTarget;
        if (item.classList.contains('phase-out')) {
          item.classList.remove('phase-out');
          item.classList.remove('active');
        } else if (item.classList.contains('phase-in')) {
          item.classList.remove('phase-in');
          item.classList.add('active');
        }
        this.isAnimating = false;
      });
    });

    forEach(this.quotes, (quote) => {
      quote.addEventListener('transitionend', (e) => {
        const item = e.currentTarget;
        if (item.classList.contains('phase-out')) {
          item.classList.remove('phase-out');
          item.classList.remove('active');
        } else if (item.classList.contains('phase-in')) {
          item.classList.remove('phase-in');
          item.classList.add('active');
        }
        this.isAnimating = false;
      });
    });

    if (this.nav !== null) {
      forEach(this.nav.querySelectorAll('[data-quote-btn]'), (btn) => {
        btn.addEventListener('click', (e) => {
          if (this.isAnimating === false && btn.classList.contains('active')) {
            this.stepQuote(e.currentTarget.getAttribute('data-quote-btn'));
          }
        }, supportsPassiveEvents ? { capture: false, passive: true } : false);

        accessibilityHelper.subscribe(btn, 'open', (e) => {
          if (this.isAnimating === false && btn.classList.contains('active')) {
            this.stepQuote(e.currentTarget.getAttribute('data-quote-btn'));
          }
        });
      });
    }
  }

  initQuoteState() {
    if (this.itemCount > 1) {
      this.nav.classList.remove('quote__nav--hidden');
    }

    if (this.images.length > 0 && this.images[this.currentItem]) {
      this.images[this.currentItem].classList.add('active');
    }

    if (this.quotes.length > 0 && this.quotes[this.currentItem]) {
      this.quotes[this.currentItem].classList.add('active');
    }

    this.updateQuoteVideos();
    this.setNavigationState();
  }

  stepQuote(directionString = 'next') {
    const direction = directionString === 'next' ? 1 : -1;
    this.currentItem = Math.min(
      Math.max(0, parseInt(this.currentItem + direction, 10)), this.itemCount - 1
    );
    this.setQuotes();
    this.updateQuoteVideos();
    this.setNavigationState();
    this.isAnimating = true;
  }

  updateQuoteVideos() {
    forEach(this.itemTypes, (item, index) => {
      if (item.type === 'video' && item.player !== null) {
        if (index === this.currentItem) {
          item.player.playVideo();
        } else {
          item.player.pauseVideo();
        }
      }
    });
  }

  setQuotes() {
    forEach(this.images, (item, index) => {
      if (index === this.currentItem) {
        this.images[index].classList.add('phase-in');
        if (this.isSingle !== true) {
          this.quotes[index].classList.add('phase-in');
        }
      } else if (this.images[index].classList.contains('active')) {
        this.images[index].classList.add('phase-out');
        if (this.isSingle !== true) {
          this.quotes[index].classList.add('phase-out');
        }
      }
    });
  }

  setNavigationState() {
    this.prevBtn.classList.add('active');
    this.nextBtn.classList.add('active');

    if (this.currentItem === 0) {
      this.prevBtn.classList.remove('active');
    } else if (this.currentItem === this.itemCount - 1) {
      this.nextBtn.classList.remove('active');
    }
  }
}

export default Quote;
