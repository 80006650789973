/**
 *  @fileOverview Enquire form and phases handler form part
 *
 *  @author       Julia Ero <julia.ero@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     modules/enquire
 *  @requires     modules/service
 *  @requires     modules/custom-select
 *  @requires     utils/helper
 *  @requires     utils/accessibility
 */

import forEach from 'lodash/forEach';
import { service } from './service';
import { enquireController } from './enquire-controller';
import CustomSelect from './custom-select';
import { helper } from '../utils/helper';
import { accessibilityHelper } from '../utils/accessibility';
import { addLoader, removeLoader } from '../utils/button-helper';

class EnquireForm {
  constructor(form) {
    this.form = form;
    this.id = this.form.getAttribute('data-enquire-form');
    this.enquireModule = this.id === 'module' ? enquireController.getEnquireById('enquire') : null;
    this.dealerData = [];
    this.countries = [];
    this.formData = {};
    this.modelData = [];
    this.currentModel = {};
    this.currentBodyStyle = {};
    this.currentCountry = '';

    if (this.form !== null) {
      this.initDOMElements();
      this.initBindings();
      this.loadData();
    }
  }

  initDOMElements() {
    this.formElements = this.form.querySelectorAll('[data-form-element]');
    this.inputs = this.form.querySelectorAll('[data-form-input]');
    this.submitButton = this.form.querySelector('[data-submit-form]');
    this.countrySelect = this.form.querySelector('[name="Country"]');
    this.dealerSelect = this.form.querySelector('select[name="DealerCode"]');
    this.titleSelect = this.form.querySelector('[name="Title"]');
    this.enquireOverlay = document.body.querySelector('[data-overlay="enquire"]');
    this.enquireSimple = document.body.querySelector('[data-enquire-form="simple"]');
    this.customCountries = null;
    this.customTitle = null;
    this.modelSelect = this.form.querySelector('select[name="Model"]');
    this.bodyStyleSelect = this.form.querySelector('[name="BodyStyle"]');
    this.vehicleEngineSelect = this.form.querySelector('[name="VehicleEngine"]');

    if (this.enquireOverlay !== null) {
      this.enquireOverlayBody = this.enquireOverlay.querySelector('[data-overlay="enquire"] .overlay__body');
      this.successLayer = this.enquireOverlay.querySelector('[data-enquire-success]');
    } else if (this.enquireSimple !== null) {
      this.successLayer = this.enquireSimple.parentElement.querySelector('[data-enquire-success]');
    }

    if (this.dealerSelect) {
      this.customDealer = new CustomSelect(this.dealerSelect);
      this.customDealer.disableSelect();
    }

    if (this.titleSelect) {
      this.customTitle = new CustomSelect(this.titleSelect);
    }
    if (this.modelSelect) {
      this.customModel = new CustomSelect(this.modelSelect);
    }
    if (this.bodyStyleSelect) {
      this.customBodyStyle = new CustomSelect(this.bodyStyleSelect);
      this.customBodyStyle.disableSelect();
    }
    if (this.vehicleEngineSelect) {
      this.customVehicleEngine = new CustomSelect(this.vehicleEngineSelect);
      this.customVehicleEngine.disableSelect();
    }
  }

  initBindings() {
    this.submitButton.addEventListener('click', (e) => {
      e.preventDefault();
      addLoader(this.submitButton);
      this.validateForm(e);
      console.log('validate form 1');
    });

    accessibilityHelper.subscribe(this.submitButton, 'open', (e) => {
      addLoader(this.submitButton);
      this.validateForm(e);
    });

    this.form.addEventListener('transitionend', (e) => {
      this.onFormTransition(e);
    });

    forEach(this.inputs, (input) => {
      if (input.tagName === 'SELECT' || input.getAttribute('type') === 'checkbox') {
        input.addEventListener('change', () => {
          this.onInputChange(input);
        });
      } else {
        input.addEventListener('keydown', () => {
          this.onInputChange(input);
        });
      }
    });

    if (this.countrySelect !== null && this.dealerSelect !== null) {
      this.countrySelect.addEventListener('change', (e) => {
        this.loadDealers(e);
      });
    }

    if (this.modelSelect !== null) {
      this.modelSelect.addEventListener('change', (e) => {
        this.customVehicleEngine.resetOptions();
        this.updateModelBodyStyles(e);
        this.updateModelEngines();
      });
    }

    if (this.bodyStyleSelect !== null) {
      this.bodyStyleSelect.addEventListener('change', (e) => {
        this.updateModelEngines(e);
      });
    }
  }

  loadData() {
    service.getDealer().then((data) => {
      this.dealerData = data;
      forEach(data, (item) => {
        this.countries.push(item.Country);
      });

      if (this.countrySelect !== null) {
        const countryList = this.countrySelect.querySelectorAll('option');
        if (countryList.length === 1) {
          this.loadCountries();
        }

        if (this.customCountries === null) {
          this.customCountries = new CustomSelect(this.countrySelect);
        }
      }
    });

    service.getModels().then((data) => {
      this.modelData = data;
      if (this.modelSelect !== null) {
        this.customModel.loadSelectOptions(this.modelData.Models, 'SalesforceValue');
        if (document.body.getAttribute('data-page').toLowerCase() === 'model-page' && this.id === 'module') {
          this.populateModelSelect();
        }
      }
    });
  }

  populateModelSelect() {
    const currentModel = document.querySelector('main').getAttribute('data-model-name');

    if (
      document.querySelector('main').hasAttribute('data-model-name')
      && document.querySelector('main').getAttribute('data-model-name') !== ''
    ) {
      this.customModel.setSelectedByValue(currentModel);
      this.customModel.hideSelect();
      if (
        this.customModel.selectWrapper.parentNode.parentNode
        && this.customModel.selectWrapper.parentNode.parentNode.classList.contains('form__row')
      ) {
        this.customModel.selectWrapper.parentNode.parentNode.classList.add('hide');
      }
    }

    this.updateModelBodyStyles(currentModel);
    this.customBodyStyle.mimic.classList.remove('select__mimic--inactive');
  }

  updateModelBodyStyles(e) {
    let value = null;

    if (typeof e === 'object') {
      value = e && e.target && e.target.value;
    } else if (typeof e === 'string') {
      value = e;
    }

    this.customBodyStyle.toggleDisabled(value);
    this.customBodyStyle.resetDefault();
    this.customBodyStyle.resetOptions();

    if (e) {
      forEach(this.modelData.Models, (item) => {
        if (item.SalesforceValue === value) {
          this.currentModel = item;
          this.checkBodyAndEngine(item);
        }
      });

      this.customBodyStyle.loadSelectOptions(this.currentModel.BodyStyles, 'SalesforceValue');
      this.customBodyStyle.setFirstOption(value, this.currentModel.BodyStyles);

      if (this.currentModel.BodyStyles && this.currentModel.BodyStyles.length === 1) {
        this.updateModelEngines(this.currentModel.BodyStyles[0].Name, true);
      }
    }
  }

  checkBodyAndEngine(item, selected) {
    let hideEngines = true;
    let hideBodyStyles = true;

    if (item.BodyStyles.length > 1) {
      hideBodyStyles = false;
    }

    if (selected && selected.VehicleEngines.length > 1) {
      hideEngines = false;
    } else if (!selected) {
      forEach(item.BodyStyles, (bodyStyle) => {
        if (bodyStyle.VehicleEngines.length > 1) {
          hideEngines = false;
        }
      });
    }

    if (hideBodyStyles) {
      this.customBodyStyle.hideSelect();
    } else {
      this.customBodyStyle.showSelect();
    }

    if (hideEngines) {
      this.customVehicleEngine.hideSelect();
    } else {
      this.customVehicleEngine.showSelect();
    }

    if (
      this.customVehicleEngine.selectWrapper.parentNode
    ) {
      if (hideEngines) {
        this.customVehicleEngine.selectWrapper.parentNode.classList.add('hide');
        this.customBodyStyle.selectWrapper.parentNode.classList.add('block', 'block--full-width');
        this.customBodyStyle.selectWrapper.parentNode.classList.remove('col', 'col--6-12');
      } else {
        this.customVehicleEngine.selectWrapper.parentNode.classList.remove('hide');
        this.customBodyStyle.selectWrapper.parentNode.classList.remove('block', 'block--full-width');
        this.customBodyStyle.selectWrapper.parentNode.classList.add('col', 'col--6-12');
      }

      if (hideBodyStyles) {
        this.customBodyStyle.selectWrapper.parentNode.classList.add('hide');
        this.customVehicleEngine.selectWrapper.parentNode.classList.add('block', 'block--full-width');
        this.customVehicleEngine.selectWrapper.parentNode.classList.remove('col', 'col--6-12');
      } else {
        this.customBodyStyle.selectWrapper.parentNode.classList.remove('hide');
        this.customVehicleEngine.selectWrapper.parentNode.classList.remove('block', 'block--full-width');
        this.customVehicleEngine.selectWrapper.parentNode.classList.add('col', 'col--6-12');
      }
    }

    if (
      this.customBodyStyle.selectWrapper.parentNode.parentNode
      && this.customBodyStyle.selectWrapper.parentNode.parentNode.classList.contains('form__row')
    ) {
      if (hideEngines && hideBodyStyles) {
        this.customBodyStyle.selectWrapper.parentNode.parentNode.classList.add('hide');
      } else {
        this.customBodyStyle.selectWrapper.parentNode.parentNode.classList.remove('hide');
      }
    }

  }

  updateModelEngines(e, isDirect) {
    let value = e && !isDirect ? e.target.value : null;

    if (isDirect) {
      value = e;
    }

    if (e) {
      if (value) {
        this.customVehicleEngine.toggleDisabled(value);
      }

      this.customVehicleEngine.resetDefault();
      this.customVehicleEngine.resetOptions();

      forEach(this.currentModel.BodyStyles, (bodyStyle) => {
        if (bodyStyle.SalesforceValue === value) {
          this.currentBodyStyle = bodyStyle;
          this.checkBodyAndEngine(this.currentModel, bodyStyle);
        }
      });

      this.customVehicleEngine.loadSelectOptions(this.currentBodyStyle.VehicleEngines, 'SalesforceValue');
      this.customVehicleEngine.setFirstOption(value, this.currentBodyStyle.VehicleEngines);
    }
  }

  loadCountries() {
    forEach(this.countries, (item) => {
      const option = document.createElement('option');
      option.value = item;
      option.innerHTML = item;
      if (this.countrySelect !== null) {
        this.countrySelect.appendChild(option);
      }
    });
  }

  loadDealers(e) {
    this.customDealer.toggleDisabled(e.target.value);
    this.customDealer.resetDefault();
    this.customDealer.resetOptions();

    forEach(this.dealerData, (item) => {
      if (item.Country === e.target.value) {
        this.currentCountry = item;
      }
    });

    this.customDealer.loadSelectOptions(this.currentCountry.Dealerships, 'DealerCode');
    this.customDealer.setFirstOption(e.target.value, this.currentCountry.Dealerships);
  }

  onInputChange(input) {
    const formGroup = helper.getParentNode(input, '.form__element-group')[0];
    const field = helper.getParentNode(input, '[data-form-element]')[0];

    if (field.classList.contains('error')) {
      field.classList.remove('error');
    }

    if (formGroup && formGroup.classList.contains('error')
      && formGroup.querySelectorAll('.error').length === 0) {
      formGroup.classList.remove('error');
    }
  }

  getformData() {
    const main = document.querySelector('main');
    const tmpModel = main.getAttribute('data-model-name');
    forEach(this.inputs, (item) => {
      if (item.type === 'checkbox') {
        this.formData[item.name] = item.checked;
      } else {
        this.formData[item.name] = item.value;
      }
    });

    this.formData.EnquireType = 'Car Sale';
    if (this.id === 'module') {
      const store = this.enquireModule.getStore();

      this.formData.DealerCode = store.DealerCode;

      if (tmpModel) {
        this.formData.Model = tmpModel;
      } else if (!this.formData.Model) {
        this.formData.EnquireType = store.EnquireType;
      }
    }

    forEach(this.dealerData, (dealer) => {
      forEach(dealer.Dealerships, (item) => {
        if (item.DealerCode === this.formData.DealerCode) {
          this.formData.Country = dealer.Country;
        }
      });
    });

    this.formData.Campaign = (main.hasAttribute('data-enquire-campaign'))
      ? main.getAttribute('data-enquire-campaign') : '';

    this.formData.Culture = document.documentElement.lang;
  }

  validateForm(e) {
    this.getformData();
    service.enquireForm(JSON.stringify(this.formData))
      .then((data) => {
        if (data.Success) {
          if (this.id === 'module') {
            this.enquireModule.navigateToPageIndex(e);
          } else {
            this.form.classList.remove('visible');
          }
        } else {
          if (this.id === 'module') {
            this.enquireModule.targetIndex = this.enquireModule.currentIndex;
          }
          this.handleErrors(data);
        }
        removeLoader(this.submitButton);
      });
  }

  handleErrors(data) {
    const errors = Object.keys(data);

    forEach(this.formElements, (item) => {
      const index = errors.indexOf(item.getAttribute('data-form-element'));
      const isGrouped = helper.getParentNode(item, '.form__element-group').length > 0 && window.innerWidth > 768;
      const formGroup = helper.getParentNode(item, '.form__element-group')[0];

      if (index >= 0) {
        const errorSpan = item.querySelector('[data-form-error-message]');

        errorSpan.innerHTML = data[errors[index]][0];

        if (isGrouped) {
          formGroup.classList.add('error');
        }

        item.classList.add('error');
      } else {
        item.classList.remove('error');
      }
    });

    if (this.id === 'module') {
      const enquireStep = this.enquireModule.enquire.querySelector(`[data-enquire-content="${this.enquireModule.currentIndex}"]`);
      this.enquireModule.setSectionHeight(enquireStep);
    }
  }

  onFormTransition(e) {
    if (e.propertyName === 'opacity') {
      if (!e.currentTarget.classList.contains('visible')) {
        e.currentTarget.classList.remove('show');
        this.showSuccess();
      }
    }
  }

  showSuccess() {
    if (typeof this.enquireOverlayBody !== 'undefined') {
      this.enquireOverlayBody.classList.add('success');
    }
    this.successLayer.classList.add('show');

    /* eslint-disable no-param-reassign */
    setTimeout(() => {
      this.successLayer.classList.add('visible');
    }, 50);
    /* eslint-enable no-param-reassign */
  }

  showOverlayForm() {
    this.enquireOverlayBody.classList.remove('success');
    this.form.classList.add('show');
    this.form.classList.add('visible');
    this.successLayer.classList.remove('visible');
    this.successLayer.classList.remove('show');
  }
}

export default EnquireForm;
