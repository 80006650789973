/**
 *  @fileOverview Homepage handler (video and scroll controller)
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     NPM:detect-it
 *  @requires     NPM:in-view
 *  @requires     modules/video-controller
 *  @requires     modules/overlay-controller
 *  @requires     utils/jump
 *  @requires     utils/locator
 *  @requires     utils/device-properties
 *  @requires     utils/easings
 */

import forEach from 'lodash/forEach';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import { deviceType, supportsPassiveEvents } from 'detect-it';
import inView from 'in-view';
import jump from '../utils/jump';
import inCMS from '../utils/locator';
import getDeviceProperties from '../utils/device-properties';
import { easeOutSine } from '../utils/easings';
import { videoController } from './video-controller';
import { overlayController } from './overlay-controller';

const UPWARDKEYS = [37, 38, 74];
const DOWNWARDKEYS = [32, 39, 40, 75];
const MOVEDELAY = 100;
const RESIZEDELAY = 250;
const SCROLLDELAY = 250;

class HomepageController {

  init() {
    this.scrollDisabled = false;
    this.activeBlock = 0;
    this.activeBlockBeforeFooter = false;
    this.lastKnownScrollPosition = 0;
    this.lastDirection = 'down';
    this.animationInProgress = false;

    this.players = videoController.getAllPlayers();
    this.userInteractionLastTime = Date.now();
    this.lastScrollEvent = Date.now();

    this.scrollByMouse = false;

    this.screenWidth = window.innerWidth;
    this.pagerOnMobileInited = false;

    this.throttledMove = throttle(this.onMouseMove.bind(this), MOVEDELAY);
    this.debouncedResize = debounce(this.onResize.bind(this), RESIZEDELAY);
    this.debouncedScroll = debounce(this.onScrollEvent.bind(this), SCROLLDELAY);
    this.onMouseWheelBind = this.onMouseWheel.bind(this);
    this.onMouseDownBind = this.onMouseDown.bind(this);
    this.onKeyDownBind = this.onKeyDown.bind(this);
    this.onPagerClickBind = this.onPagerClick.bind(this);

    this.footerHeight = 204;

    this.initDOMElements();
    this.initInView();
    this.initBindings();
    this.initPlayerCallbacks();
  }

  destroy() {
    if (this.main) {
      this.main.removeAttribute('style');
    }
    this.removeBindings();
  }

  initDOMElements() {
    this.main = document.body.querySelector('main');
    this.pager = document.body.querySelector('main [data-pager]');
    this.footer = document.body.querySelector('[data-footer]');
    this.emissionBlock = this.footer.querySelector('[data-emission-block]');
    this.collectionsList = document.body.querySelector('[data-collection-list]');
    this.blocks = Array.prototype.slice.call(document.body.querySelectorAll('main [data-homepage-block]'));
    this.blocks.push(this.footer);

    if (this.emissionBlock !== null) {
      this.footerHeight = this.footer.offsetHeight;
      if (deviceType === 'mouseOnly') {
        this.main.style.marginBottom = `${this.footerHeight}px`;
      }
    }

  }

  initBindings() {
    if (deviceType === 'mouseOnly') {
      if (this.screenWidth < 769 && this.pagerOnMobileInited === false) {
        this.initPagerOnMobile();
      }

      if (this.pager) {
        forEach(this.pager.querySelectorAll('li'), (elem) => {
          elem.addEventListener('click', this.onPagerClickBind, supportsPassiveEvents ? {
            capture: false,
            passive: true,
          } : false);
        });
      }
      window.addEventListener('mousemove', this.throttledMove, supportsPassiveEvents ? {
        capture: false,
        passive: true,
      } : false);
      window.addEventListener('wheel', this.onMouseWheelBind, supportsPassiveEvents ? {
        capture: false,
        passive: false,
      } : false);
      window.addEventListener('resize', this.debouncedResize, supportsPassiveEvents ? {
        capture: false,
        passive: true,
      } : false);
      window.addEventListener('mousedown', this.onMouseDownBind, supportsPassiveEvents ? {
        capture: false,
        passive: false,
      } : false);
      window.addEventListener('scroll', this.debouncedScroll, supportsPassiveEvents ? {
        capture: false,
        passive: false,
      } : false);
      window.addEventListener('keydown', this.onKeyDownBind, supportsPassiveEvents ? {
        capture: false,
        passive: false,
      } : false);
    } else if (this.pagerOnMobileInited === false) {
      this.initPagerOnMobile();
    }
  }

  removeBindings() {
    if (this.pager) {
      forEach(this.pager.querySelectorAll('li'), (elem) => {
        elem.removeEventListener('click', this.onPagerClickBind);
      });
    }
    window.removeEventListener('mousemove', this.throttledMove);
    window.removeEventListener('wheel', this.onMouseWheelBind);
    window.removeEventListener('resize', this.debouncedResize);
    window.removeEventListener('mousedown', this.onMouseDownBind);
    window.removeEventListener('scroll', this.debouncedScroll);
    window.removeEventListener('keydown', this.onKeyDownBind);
  }

  onPagerClick(e) {
    this.activeBlock = parseInt(e.target.getAttribute('data-target') - 1, 10);
    this.scrollToBlock();
  }

  onMouseMove() {
    this.userInteractionLastTime = Date.now();
  }

  onMouseWheel(e) {
    if (!inCMS()) {
      if (this.scrollDisabled === false && this.screenWidth > 768) {
        e.preventDefault();
        e.stopPropagation();
      }

      if (this.screenWidth > 768) {
        this.onScroll(e);
      }
    }
  }

  onResize() {
    this.screenWidth = window.innerWidth;
    this.footerHeight = this.footer.offsetHeight;
    if (this.screenWidth < 769 && this.pagerOnMobileInited === false) {
      this.initPagerOnMobile();
    }

    if (deviceType === 'mouseOnly') {
      this.main.style.marginBottom = `${this.footerHeight}px`;
    }

    if (this.screenWidth > 768) {
      this.scrollToBlock();
    }
  }

  onMouseDown(e) {
    if (window.innerWidth - e.pageX <= getDeviceProperties.scrollbarWidth) {
      this.scrollByMouse = true;
    }
  }

  onScrollEvent() {
    if (!inCMS()) {
      const direction = this.lastKnownScrollPosition > window.scrollY ? 'up' : 'down';
      this.lastKnownScrollPosition = window.scrollY;
      this.lastDirection = direction;

      if (
        this.animationInProgress === false
        && this.scrollByMouse === true
        && this.screenWidth > 768
      ) {
        if (
          (this.activeBlockBeforeFooter && direction === 'down')
          || (this.activeBlock === this.blocks.length - 1)
        ) {
          this.setActiveBlock(direction);
        }
        this.scrollToBlock();
        this.scrollByMouse = false;
      }
    }
    return true;
  }

  onKeyDown(e) {
    if (!inCMS()) {
      const code = parseInt(e.keyCode, 10);
      let direction = false;

      if (UPWARDKEYS.indexOf(code) !== -1) {
        direction = 'up';
      } else if (DOWNWARDKEYS.indexOf(code) !== -1) {
        direction = 'down';
      }

      if (direction && overlayController.getOverlays().length === 0) {
        e.preventDefault();
        e.stopPropagation();
        if (this.animationInProgress === false) {
          this.setActiveBlock(direction);
          this.scrollToBlock();
        }
      }
    }
  }

  initInView() {
    inView.threshold(0.5);
    inView('main [data-homepage-block]').on('enter', this.onBlockEnters.bind(this));
    inView('main [data-homepage-block]').on('exit', this.onBlockExits.bind(this));

    this.blocks.forEach((block) => {
      if (inView.is(block) && block.getAttribute('data-homepage-block')) {
        this.onBlockEnters(block);
      }
    });
  }

  initPagerOnMobile() {
    let limit = 0;

    if (this.collectionsList) {
      limit = this.collectionsList.offsetTop + (window.innerHeight / 2);
    }

    window.addEventListener(
      'scroll',
      debounce(() => {
        const currentPos = document.body.scrollTop;
        const correctedPos = currentPos + window.innerHeight;

        if (limit < correctedPos && this.collectionsList) {
          this.pager.classList.add('pager--hidden');
        } else {
          this.pager.classList.remove('pager--hidden');
        }
      }, 200),
      supportsPassiveEvents ? { capture: false, passive: true } : false
    );

    this.pagerOnMobileInited = true;
  }

  initPlayerCallbacks() {
    this.players.forEach((item) => {
      item.player.setEndCallback(() => {
        if (
          this.activeBlock < this.blocks.length - 2
          && Date.now() - this.userInteractionLastTime > 5000
          && this.screenWidth > 768
        ) {
          this.setActiveBlock('down');
          this.scrollToBlock();
        }
      });
      item.player.setLoadedCallback(() => {
        const heroItem = item.player.video.parentNode.parentNode;
        heroItem.classList.add('hero--loaded');
        heroItem.querySelector('.subtitle').addEventListener('transitionend', () => {
          heroItem.classList.add('hero--intro-animation-done');
        });
      });
    });
  }

  onBlockEnters(block) {
    this.activeBlock = block && parseInt(block.getAttribute('data-homepage-block') - 1, 10);
    block.querySelector('.hero').classList.add('hero--onscreen');
    block.querySelector('.hero').classList.remove('hero--offscreen-up');
    block.querySelector('.hero').classList.remove('hero--offscreen-down');
    block.querySelector('.hero').classList.remove('hero--offscreen');
    this.setPager();
  }

  onBlockExits(block) {
    block.querySelector('.hero').classList.remove('hero--onscreen');
    block.querySelector('.hero').classList.add(`hero--offscreen-${this.lastDirection}`);
    block.querySelector('.hero').classList.add('hero--offscreen');
  }

  onScroll(event) {
    const currentTime = Date.now();
    const deltaTime = Math.abs(this.lastScrollEvent - currentTime);
    const direction = event.deltaY < 0 ? 'up' : 'down';

    this.lastScrollEvent = currentTime;
    this.userInteractionLastTime = currentTime;

    if (deltaTime < 50 || this.scrollDisabled) {
      return false;
    }

    this.setActiveBlock(direction);
    this.scrollToBlock();

    return true;
  }

  setActiveBlock(direction) {
    this.lastDirection = direction;

    if (direction === 'up') {
      this.activeBlock--;
    } else {
      this.activeBlock++;
    }

    if (this.activeBlock < 0) {
      this.activeBlock = 0;
    }

    if (this.activeBlock > this.blocks.length - 1) {
      this.activeBlock = this.blocks.length - 1;
    }
  }

  scrollToBlock(targetBlock = this.activeBlock) {
    let revealFooter = false;
    let targetIndex = targetBlock;

    if (this.animationInProgress === false) {
      if (targetIndex === this.blocks.length - 1) {
        targetIndex = this.blocks.length - 2;
        revealFooter = true;
      }

      if (revealFooter && this.emissionBlock !== null) {
        this.pager.classList.add('pager--hidden');
      } else {
        this.pager.classList.remove('pager--hidden');
      }

      this.animationInProgress = true;
      jump(this.blocks[targetIndex], {
        duration: 800,
        easing: easeOutSine,
        offset: revealFooter ? this.footerHeight : 0,
        callback: () => {
          this.activeBlock = revealFooter ? targetIndex + 1 : targetIndex;
          this.activeBlockBeforeFooter = targetIndex === this.blocks.length - 2;
          this.animationInProgress = false;
        },
      });
    }
  }

  getPlayerIndex(player) {
    const parent = player.parentNode;
    return parseInt(Array.prototype.indexOf.call(parent.children, player) - 1, 10);
  }

  setPager() {
    forEach(this.pager.querySelectorAll('li'), (elem) => {
      elem.classList.remove('pager__button--active');
      if (parseInt(elem.getAttribute('data-target'), 10) === this.activeBlock + 1) {
        elem.classList.add('pager__button--active');
      }
    });
  }

  disableScroll() {
    this.scrollDisabled = true;
  }

  enabledScroll() {
    this.scrollDisabled = false;
  }

  setFooterHeight(height) {
    this.footerHeight = height;
  }

}

export const homepageController = new HomepageController();
