/**
 *  @fileOverview Specs accordion controller
 *
 *  @author       Julia Ero <julia.ero@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     modules/specs-accordion
 */

import forEach from 'lodash/forEach';
import SpecsAccordion from './specs-accordion';

class SpecsAccordionController {
  init() {
    this.specsAccordions = [];
    this.initSpecAccordions();
  }

  initSpecAccordions() {
    forEach(document.querySelectorAll('[data-specs-accordion]'), (item) => {
      if (!item.hasAttribute('data-specs-accordion-inited')) {
        this.specsAccordions.push(new SpecsAccordion(item));
      }
    });
  }
}

export const specsAccordionController = new SpecsAccordionController();
