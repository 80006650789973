/**
 *  @fileOverview Model page controller
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *  @author       Julia Ero <julia.ero@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     NPM:detect-it
 *  @requires     utils/accessibility
 *  @requires     utils/jump
 *  @requires     utils/easings
 */

import forEach from 'lodash/forEach';
import { supportsPassiveEvents } from 'detect-it';
import jump from '../utils/jump';
import { easeOutSine } from '../utils/easings';
import { accessibilityHelper } from '../utils/accessibility';

class ModelpageController {

  init() {
    this.initBindings();
  }

  initBindings() {
    forEach(document.body.querySelectorAll('[data-page-scroll]'), (elem) => {
      elem.addEventListener(
        'click',
        () => {
          this.onPageScroll(elem);
        },
        supportsPassiveEvents ? { capture: false, passive: true } : false
      );

      accessibilityHelper.subscribe(elem, 'open', () => {
        this.onPageScroll(elem);
      });
    });
  }

  onPageScroll(elem) {
    const params = elem.getAttribute('data-page-scroll').split('|').reduce((obj, val, index) => {
      const objectKeys = ['size', 'direction', 'amount'];
      return { ...obj, [objectKeys[index]]: val };
    }, {});
    const animValues = {};

    if (params.size === 'page') {
      animValues.size = ((window.innerHeight - window.pageYOffset) * (params.direction === 'down' ? 1 : -1)) * params.amount;
    }

    jump(animValues.size, {
      duration: 800,
      easing: easeOutSine,
      offset: 0,
    });
  }

}

export const modelpageController = new ModelpageController();
