/**
 *  @fileOverview Global offset helper
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *
 */

const getGlobalOffsetTop = (element) => {
  let y = 0;
  let el = element;

  while (el) {
    y += el.offsetTop;
    el = el.offsetParent;
  }

  return y;
};

export default getGlobalOffsetTop;
