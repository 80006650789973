/**
 *  @fileOverview Gallery controller - collects and controls all the galleries on a page
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     modules/gallery
 */

import forEach from 'lodash/forEach';
import Gallery from './gallery';

class GalleryController {
  init() {
    this.galleries = [];
    this.initGalleries();
  }

  initGalleries() {
    forEach(document.querySelectorAll('[data-gallery]'), (elem) => {
      this.galleries.push(new Gallery(elem));
    });
  }

  getGalleryById(id) {
    return this.galleries.filter((gallery) => (gallery.id === id))[0];
  }
}

export const galleryController = new GalleryController();
