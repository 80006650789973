/**
 *  @fileOverview Custom select for the forms
 *
 *  @author       Julia Ero <julia.ero@possible.com>
 *  @author       Daniel Jung <daniel.jung@possible.com>
 *
 */

import forEach from 'lodash/forEach';
import { deviceType } from 'detect-it';
import { helper } from '../utils/helper';

class CustomSelect {
  constructor(select) {
    this.select = select;

    if (this.select !== null) {
      this.initDOMElements();
      this.initBindings();
    }
  }

  initDOMElements() {
    this.selectWrapper = helper.getParentNode(this.select, '[data-form-element]')[0];
    this.mimic = this.select.parentNode.querySelector('[data-select-mimic]');
    this.icon = this.select.parentNode.querySelector('.icon');
    this.options = this.select.querySelectorAll('option');
  }

  initBindings() {
    if (this.options && this.options.length > 0) {
      this.select.addEventListener('change', () => {
        this.showSelected();
      });
    } else {
      this.showSelected();
    }
  }

  showSelected(value = this.select.value) {
    const selected = this.select.querySelector(`[value="${value}"]`);

    if (selected) {
      selected.setAttribute('selected', true);

      if (deviceType === 'touchOnly') {
        this.mimic.innerHTML = selected.value;
      }

      if (selected.value !== '') {
        this.mimic.classList.remove('select__mimic--inactive');
      }
    }
  }

  loadSelectOptions(array, value) {
    forEach(array, (item) => {
      const option = document.createElement('option');

      option.value = item[value];
      option.innerHTML = item.Name;
      this.select.appendChild(option);
    });
  }

  setFirstOption(value, array) {
    if (value && array && array.length === 1) {
      this.select.querySelectorAll('option')[1].setAttribute('selected', true);
      this.icon.classList.add('hidden');
      this.mimic.innerHTML = array[0].Name;
      this.select.setAttribute('disabled', true);
    } else {
      this.select.querySelectorAll('option')[0].setAttribute('selected', true);
      this.icon.classList.remove('hidden');
      this.select.removeAttribute('disabled');
      this.resetDefault();
    }

    if (!value) {
      this.select.setAttribute('disabled', true);
    }
  }

  setSelectedByValue(value) {
    this.showSelected(value);
    this.icon.classList.add('hidden');
    this.select.setAttribute('disabled', true);
  }

  toggleDisabled(value = null) {
    this.activateSelect();
    if (!value) {
      this.disableSelect();
    }
  }

  activateSelect() {
    this.select.removeAttribute('disabled');
    this.selectWrapper.classList.remove('disabled');
    this.mimic.classList.remove('select__mimic--inactive');
  }

  disableSelect() {
    this.resetDefault();
    this.mimic.classList.add('select__mimic--inactive');
    this.selectWrapper.classList.add('disabled');
    this.select.setAttribute('disabled', true);
  }

  hideSelect() {
    this.selectWrapper.classList.add('hide');
  }

  showSelect() {
    this.selectWrapper.classList.remove('hide');
  }

  resetDefault() {
    if (this.mimic) {
      this.mimic.innerHTML = this.options[0].innerHTML;
    }
  }

  resetOptions() {
    forEach(this.select.querySelectorAll('option'), (item) => {
      item.removeAttribute('selected');
    });

    while (this.select.children.length !== 1) {
      this.select.removeChild(this.select.lastChild);
    }
  }
}

export default CustomSelect;
