/**
 *  @fileOverview Counter animation module for the specs part of the page
 *
 *  @author       Daniel Jung <daniel.jung@possible.com>
 *
 *  @requires     utils/easings
 */

import { easeInOutExpo } from '../utils/easings';

class UpCounter {
  constructor(el, inp, dur = 2000) {
    this.prevTime = 0;
    this.elapsedTime = 0;
    this.element = el;
    this.duration = dur;
    this.input = null;
    this.inputMaxes = null;
    this.inputWasInteger = null;

    if (inp.some((v) => Number(v))) {
      this.setHelpers(inp);
      this.setInitialValue();
      this.markInited();
      this.count();
    }
  }

  setHelpers(inp) {
    this.inputMaxes = inp.map((v) => (Number(v) ? Number(v) : null));
    this.input = inp.map((v) => (Number(v) ? 0 : v));
    this.inputWasInteger = this.inputMaxes.map((v) => (Number.isInteger(v)));
  }

  setInitialValue() {
    this.element.innerHTML = this.input.join('');
  }

  nextNum() {
    const currentTime = Date.now();
    this.elapsedTime += this.prevTime === 0 ? 0 : currentTime - this.prevTime;
    this.prevTime = currentTime;
    const nextNumbers = this.input.map((v, i) => (this.isNumeric(v)
      ? easeInOutExpo(this.elapsedTime, 0, this.inputMaxes[i], this.duration) : v
    ));
    return this.elapsedTime <= this.duration ? nextNumbers : false;
  }

  markInited() {
    this.element.setAttribute('data-count-up-inited', 'true');
  }

  updateElement(nextVal) {
    this.element.innerHTML = nextVal.map((v, i) => {
      if (this.isNumeric(v)) {
        return this.inputWasInteger[i] ? Math.ceil(v) : v.toFixed(1);
      }
      return v;
    }).join('');
  }

  count() {
    const next = this.nextNum();

    if (next !== false) {
      this.updateElement(next);
      window.requestAnimationFrame(this.count.bind(this));
    } else {
      this.updateElement(this.inputMaxes);
    }
  }

  isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }
}

export default UpCounter;
