/**
 *  @fileOverview Lock Scroll - add class lock-scroll to body element
 *
 *  @author       Lukasz Goral <lukasz.goral@possible.com>
 *
 *  @requires     NPM:lodash
 */

class LockScroll {
  constructor() {
    this.offset = 0;
  }

  lock(offset) {
    this.offset = offset >= 0 ? offset : window.pageYOffset;
    document.body.classList.add('lock-scroll');
    document.body.style.top = `-${this.offset}px`;
  }

  unlock() {
    document.body.classList.remove('lock-scroll');
    document.body.style.top = 0;
    window.scrollTo(0, this.offset);
  }

}

export const lockScroll = new LockScroll();
