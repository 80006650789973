/**
 *  @fileOverview Low-level API handler (Axios based)
 *
 *  @author       Daniel Jung <daniel.jung@possible.com>
 *  @author       Julia Ero <julia.ero@possible.com>
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *
 *  @requires     NPM:axios
 */

import axios from 'axios';

const DEBUG_HOSTS = /localhost/ig;

class Api {
  constructor() {
    this.timeOutToken = null;

    this.isLocalhost = window.location
      && window.location.host
      && window.location.host.match(DEBUG_HOSTS) !== null;
  }

  request(url, method = 'get', data = {}, disableGlobalLoader = false, disableCache = false) {
    const headers = {};
    let contentType = 'application/x-www-form-urlencoded';

    if (!disableGlobalLoader) {
      window.clearTimeout(this.timeOutToken);
      this.timeOutToken = window.setTimeout(() => {
        document.body.setAttribute('data-xhr-loading', 'true');
      }, 500);
    }

    axios.interceptors.response.use((res) => {
      this.beforeResponseHandler();
      return res;
    }, (err) => {
      this.beforeResponseHandler();
      /* eslint-disable no-console */
      console.log('%c %s %s %s ', 'color: yellow; background-color: black;', '--', `API error: ${err}`, '--');
      /* eslint-enable no-console */
    });

    if (method === 'post') {
      contentType = 'application/json';
    }

    headers['Content-Type'] = contentType;
    headers['X-Requested-With'] = 'XMLHttpRequest';
    if (disableCache && this.isLocalhost === false) {
      headers['Cache-Control'] = 'no-cache, no-store, max-age=0';
    }

    return axios({
      headers,
      method,
      url,
      data,
      validateStatus: (status) => ((status >= 200 && status < 300) || status === 400),
    });
  }

  beforeResponseHandler() {
    window.clearTimeout(this.timeOutToken);
    document.body.removeAttribute('data-xhr-loading');
  }
}

export const api = new Api();
