/**
 *  @fileOverview Parallax effect controller
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     NPM:detect-it
 *  @requires     NPM:withinViewport
 *  @requires     modules/module-controller
 *  @requires     utils/global-offset
 */

import { withinViewport } from 'withinviewport';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import { supportsPassiveEvents } from 'detect-it';
import getGlobalOffsetTop from '../utils/global-offset';
import { moduleController } from './module-controller';

class Parallax {
  constructor(el) {
    if (!(typeof el && el.nodeName)) {
      throw TypeError('Constructor parameter should be a valid DOM element!');
    }

    this.page = moduleController.getCurrentPage();
    this.isParallaxActive = false;
    this.isDisabled = false;
    this.screenWidth = window.innerWidth;

    this.elem = el;
    window.setTimeout(() => {
      this.setParameters();
      this.checkVisibility();
      if (this.params.visible && this.screenWidth > 768) {
        this.calculateParallax();
      }
      this.initBindings();
    }, 400);
  }

  initBindings() {
    window.addEventListener('resize', debounce(() => {
      this.refreshParallax();
    }, 250));

    window.addEventListener('scroll', throttle(() => {
      if (this.screenWidth > 768 && this.isDisabled === false) {
        this.checkVisibility();
        if (this.params.visible) {
          this.calculateParallax();
        }
      } else if (this.isParallaxActive) {
        this.isParallaxActive = false;
        this.resetParallax();
      }
    }, 16), supportsPassiveEvents ? { capture: false, passive: false } : false);
  }

  refreshParallax() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > 768 && this.isDisabled === false) {
      this.setParameters();
      if (this.isParallaxActive === false) {
        this.checkVisibility();
        if (this.params.visible) {
          this.calculateParallax();
        }
      }
      this.isParallaxActive = true;
    } else if (this.screenWidth <= 768) {
      this.resetParallax();
    }
  }

  setParameters() {
    this.params = {
      itemOffsetX: parseInt(this.elem.getAttribute('data-parallax-offset-x'), 10) || 0,
      itemOffsetY: parseInt(this.elem.getAttribute('data-parallax-offset-y'), 10) || 0,
      offsetY: getGlobalOffsetTop(this.elem),
      height: this.elem.offsetHeight,
      visible: false,
      lock: this.elem.getAttribute('data-parallax-lock') !== null,
      ratio: parseFloat(this.elem.getAttribute('data-parallax')) || 0.2,
      name: this.elem.getAttribute('data-parallax-name') || '',
    };
  }

  checkVisibility() {
    this.page = moduleController.getCurrentPage();
    this.params.visible = withinViewport(this.elem, {
      top: -this.params.height,
      bottom: -this.params.height,
      sides: 'top bottom',
    });

    if (
      this.params.name === 'header'
      && this.page !== 'model-page'
    ) {
      this.params.visible = false;
    }
  }

  calculateParallax() {
    window.requestAnimationFrame(() => {
      const divTop = getGlobalOffsetTop(this.elem);
      const divHeight = this.elem.offsetHeight;
      const docViewTop = window.pageYOffset;
      const offsetFromCenter = (docViewTop + (window.innerHeight / 2)) - divTop - (divHeight / 2);
      const correction = window.innerHeight - divTop - divHeight;

      let parallaxY = 0;

      if (divTop < window.innerHeight) {
        parallaxY = (
          (docViewTop - divTop) + (window.innerHeight - divHeight - correction)
        ) * this.params.ratio;
      } else {
        parallaxY = offsetFromCenter * this.params.ratio;
      }

      if (this.params.lock && parallaxY < 0) {
        parallaxY = 0;
      }

      if (parallaxY < -this.params.height) {
        parallaxY = -this.params.height;
      }

      this.elem.style.transform = `translate3d(0, ${parallaxY}px, 0)`;
      this.elem.style.transition = 'none';
    });
  }

  resetParallax() {
    window.requestAnimationFrame(() => {
      this.elem.removeAttribute('style');
    });
  }

  disableParallax() {
    if (this.isDisabled === false) {
      this.isDisabled = true;
      this.resetParallax();
    }
  }

  enableParallax() {
    if (this.isDisabled === true) {
      this.isDisabled = false;
      if (this.screenWidth > 768) {
        this.calculateParallax();
      }
    }
  }
}

export default Parallax;
