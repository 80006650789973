/**
 *  @fileOverview Main app module
 *
 *  @author       Milosz Szarek <milosz.szarek@possible.com>
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *
 *  @requires     modules/module-controller
 */

import lazySizes from 'lazysizes';
import { moduleController } from './modules/module-controller';

window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.loadMode = 1;
window.lazySizesConfig.expand = 1000;
window.lazySizesConfig.expFactor = 2;
lazySizes.init();

class App {
  constructor() {
    moduleController.initModules();
  }
}

window.addEventListener('load', () => new App());
