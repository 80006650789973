/**
 *  @fileOverview GlobalFooter
 *
 *  @author       Bogdan Niedźwiecki <bogdan.niedzwiecki@wundermanthompson.com>
 *
 */

const bingScriptEl = document.getElementById('bing-map-control');

class GlobalFooter {

  toggleAccordion(collapsible) {
    this.collapsible.forEach((c) => {
      const panel = c.nextElementSibling;
      if (!panel) return;

      if (collapsible !== c) {
        panel.style.maxHeight = `${panel.offsetHeight}px`;
        setTimeout(() => {
          panel.style.maxHeight = null;
        }, 0);
        c.classList.remove('active');
        c.setAttribute('aria-expanded', 'false');
        panel.setAttribute('aria-hidden', 'true');
        return;
      }

      if (panel.style.maxHeight) {
        panel.style.maxHeight = `${panel.offsetHeight}px`;
        setTimeout(() => {
          panel.style.maxHeight = null;
        }, 0);
      } else {
        panel.style.maxHeight = `${panel.scrollHeight}px`;
        setTimeout(() => {
          if (collapsible.classList.contains('active')) {
            panel.style.maxHeight = 'unset';
          }
        }, 300);
      }

      collapsible.classList.toggle('active');
      collapsible.setAttribute(
        'aria-expanded',
        `${collapsible.getAttribute('aria-expanded') !== 'true'}`
      );
      panel.setAttribute(
        'aria-hidden',
        `${panel.getAttribute('aria-hidden') !== 'true'}`
      );
    });
  }

  initAccordion() {
    this.collapsible = this.el.querySelectorAll('.global-footer-accordion-collapsible');
    if (!this.collapsible.length) return;

    this.collapsible.forEach((collapsible, index) => {
      const panel = collapsible.nextElementSibling;

      collapsible.setAttribute('id', `acc-collapsible-${index}`);
      collapsible.setAttribute('aria-controls', `acc-panel-${index}`);
      collapsible.setAttribute('aria-expanded', 'false');
      collapsible.setAttribute('role', 'heading');

      panel.setAttribute('id', `acc-panel-${index}`);
      panel.setAttribute('aria-labelledby', `acc-collapsible-${index}`);
      panel.setAttribute('aria-hidden', 'true');
      panel.setAttribute('role', 'definition');

      collapsible.addEventListener('click', () => {
        this.toggleAccordion(collapsible);
      });
    });
  }

  asyncInitCallback() {
    if (/comp|inter|loaded/.test(document.readyState)) {
      setTimeout(() => {
        this.initAutosuggest();
      }, 1000);
    } else {
      document.addEventListener('DOMContentLoaded', () => {
        this.initAutosuggest();
      }, false);
    }
  }

  initAutosuggest() {
    if (!window.Microsoft || !window.Microsoft.Maps.loadModule) return;

    function selectedSuggestion(suggestionResult) {
      window.open(`/dealers?lat=${suggestionResult.location.latitude}&lng=${suggestionResult.location.longitude}`, '_blank');
    }

    function onSuggest(elem, f) {
      const observer = new MutationObserver(((mutations) => {
        mutations.forEach((m) => {
          if (m.type !== 'attributes' || m.attributeName !== 'aria-expanded') return;

          f(m.target.getAttribute('aria-expanded'));
        });
      }));
      observer.observe(elem, { attributes: true });
    }

    function onLoad() {
      const options = { maxResults: 4 };
      const manager = new window.Microsoft.Maps.AutosuggestManager(options);
      manager.attachAutosuggest('#dealer', '.global-footer-dealer-field', selectedSuggestion);

      const dealerInput = document.querySelector('#dealer');
      if (!dealerInput) return;
      onSuggest(dealerInput, (expanded) => {
        if (expanded === 'true') {
          dealerInput.classList.add('active', 'visible');
          return;
        }
        if (expanded === 'false') {
          dealerInput.classList.remove('visible');
          setTimeout(() => {
            if (!dealerInput.classList.contains('visible')) {
              dealerInput.classList.remove('active');
            }
          }, 300);
        }
      });
    }

    function onError(message) {
      console.error(message);
    }

    window.Microsoft.Maps.loadModule('Microsoft.Maps.AutoSuggest', {
      callback: onLoad,
      errorCallback: onError,
    });
  }

  initFindADealer() {
    if (!document.querySelector('.global-footer-dealer')) return;

    if (bingScriptEl) {
      window.BingMapCallback = () => {
        window.MapReady = true;
        if (window.AstonMartinGlobalFooterCallback) {
          window.AstonMartinGlobalFooterCallback.call();
        }
      };
      bingScriptEl.src = bingScriptEl.getAttribute('data-src');
    }
  }

  init() {
    this.el = document.querySelector('.global-footer');
    if (!this.el) return;

    this.initAccordion();
    this.initFindADealer();

    this.el.classList.add('active');
  }
}

const gf = new GlobalFooter();

function bindCallback() {
  if (window.MapReady) {
    gf.asyncInitCallback.bind(gf).call();
  } else {
    window.AstonMartinGlobalFooterCallback = gf.asyncInitCallback.bind(gf);
  }
}

if (document.querySelector('.global-footer-dealer')) {
  bindCallback();
}

export const globalFooter = gf;
