/**
 *  @fileOverview Search, autocomplete module
 *
 *  @author       Daniel Jung <daniel.jung@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     modules/overlay-controller
 *  @requires     modules/template
 *  @requires     modules/service
 */

import debounce from 'lodash/debounce';
import { overlayController } from './overlay-controller';
import { service } from './service';
import { template } from './template';

const PREVENT_QUICKSEARCH_ELEMENTS = ['INPUT', 'TEXTAREA', 'SELECT'];
const SEARCH_RESULT_ITEM_HEIGHT = 50;
const SEARCH_RESULT_TITLE_HEIGHT = 35;
const SEARCH_RESULT_CATEGORY_BLOCK_GUTTER = 10;
const SEARCH_RESULT_MAIN_GUTTER = 32;

class Search {
  constructor() {
    this.inited = false;
  }

  init() {
    if (this.inited) {
      return;
    }

    this.searchOverlayOpen = false;
    this.quickSearchText = '';
    this.latestSearchResultHTML = null;
    this.searchCallReady = false;
    this.prevHeight = 0;

    this.initDOMElements();
    this.initBindings();

    this.inited = true;
  }

  initDOMElements() {
    this.searchForm = document.querySelector('[data-search-form]');
    this.searchField = document.querySelector('[data-search-form] input');
    this.searchResult = document.querySelector('[data-search-results]');
    this.searchOverlayClose = document.querySelector('[data-overlay="search"] [data-close-overlay]');
    this.searchSubmit = document.querySelector('[data-search-form] button');
  }

  initBindings() {
    if (this.searchOverlayClose) {
      this.searchOverlayClose.addEventListener('click', this.resetSearchOverlay.bind(this));
    }

    if (this.searchResult) {
      this.searchResult.addEventListener('transitionend', this.searchSuccess.bind(this));
    }

    if (this.searchSubmit) {
      this.searchSubmit.addEventListener('click', (e) => {
        e.preventDefault();
        this.handleSearchInputChange(e);
      });
    }

    if (this.searchField) {
      this.searchField.addEventListener('input', debounce((e) => {
        this.handleSearchInputChange(e);
      }, 200));

      window.addEventListener('keyup', debounce((e) => {
        if (e.keyCode === 27) {
          this.resetSearchOverlay();
        }

        if (((e || {}).target || {}).tagName
          && PREVENT_QUICKSEARCH_ELEMENTS.indexOf(e.target.tagName.toUpperCase()) !== -1
          && !this.searchOverlayOpen
        ) {
          return;
        }

        if (e.key.length === 1 && /\w/.test(e.key) && overlayController.getOverlays().length === 0) {
          this.quickSearchText += e.key;
        }

        if (this.quickSearchText.length === 3 && overlayController.getOverlays().length === 0) {
          overlayController.openOverlay(null, false, false, 'search');
          this.searchField.focus();
          this.searchOverlayOpen = true;
          this.searchField.value = this.quickSearchText;
        }
      }));
    }
  }

  handleSearchInputChange(e) {
    const inputValue = e.target.value;
    if (inputValue && inputValue.length >= 3 && this.searchResult && this.searchForm) {
      this.searchForm.classList.add('loading');
      this.searchResult.classList.add('hide');
      this.searchCallReady = false;
      const searchIndexElement = document.querySelector('[data-search-index]');
      let searchIndex = null;
      if (searchIndexElement) {
        searchIndex = searchIndexElement.getAttribute('data-search-index');
      }
      service.search(e.target.value, searchIndex)
        .then((res) => {
          if (res && res.Categories) {
            const newHeight = this.calcSearchResultHeight(res);
            this.searchResult.setAttribute('style', `height: ${newHeight}px`);
            this.latestSearchResultHTML = template.render('search-result', res);
            this.searchForm.classList.remove('loading');
            this.searchCallReady = true;
            if (this.prevHeight === newHeight) {
              this.searchSuccess();
            }
            this.prevHeight = newHeight;
          }
        });
    } else {
      this.latestSearchResultHTML = '';
      this.searchResult.setAttribute('style', 'height: 0px');
      this.searchResult.classList.add('hide');
    }
  }

  searchSuccess(e) {
    e.stopPropagation();
    if (this.searchCallReady && e.target === this.searchResult) {
      this.searchResult.innerHTML = this.latestSearchResultHTML;
      this.searchResult.classList.remove('hide');
    }
  }

  calcSearchResultHeight(res) {
    const categoryNumber = res.Categories.length;
    /* eslint-disable no-return-assign, no-param-reassign */
    const searchResultItemNumber = res.Categories.reduce((a, v) => (a += v.Results.length), 0);
    /* eslint-enable no-return-assign, no-param-reassign */

    return (SEARCH_RESULT_ITEM_HEIGHT * searchResultItemNumber)
      + (SEARCH_RESULT_TITLE_HEIGHT * categoryNumber)
      + (SEARCH_RESULT_CATEGORY_BLOCK_GUTTER * (categoryNumber - 1))
      + SEARCH_RESULT_MAIN_GUTTER;
  }

  resetSearchOverlay() {
    this.searchOverlayOpen = false;
    this.quickSearchText = '';
    this.searchField.value = '';
    this.latestSearchResultHTML = '';
    this.searchResult.setAttribute('style', 'height: 0px');
    this.searchResult.classList.add('hide');
  }
}

export const search = new Search();
