/**
 *  @fileOverview Enquire form and phases handler
 *
 *  @author       Julia Ero <julia.ero@possible.com>
 *  @author       Daniel Jung <daniel.jung@possible.com>
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     NPM:detect-it
 *  @requires     modules/template
 *  @requires     modules/slider-controller
 *  @requires     modules/service
 *  @requires     utils/device-properties
 *  @requires     utils/helper
 *  @requires     utils/button-helper
 *  @requires     utils/accessibility
 */

import forEach from 'lodash/forEach';
import { service } from './service';
import { template } from './template';
import { helper } from '../utils/helper';
import {
  enableButton, disableButton, addLoader, removeLoader
} from '../utils/button-helper';
import { sliderController } from './slider-controller';
import { accessibilityHelper } from '../utils/accessibility';

const MAPHOST_URL = 'https://maps.googleapis.com/maps/api/staticmap';
const GOOGLE_APIKEY = 'AIzaSyB8NUQRZ6ZQXyl87oErU5i09UvwYlN_NpE';
const PIN_IMAGE_URL = 'https://goo.gl/duWZL3';
const MAP_STYLE = 'zoom=14&format=png&maptype=roadmap&style=element:geometry%7Ccolor:0xf5f5f5&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x616161&style=element:labels.text.stroke%7Ccolor:0xf5f5f5&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:poi.park%7Celement:geometry.fill%7Ccolor:0xc4c4c4&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:road%7Celement:geometry%7Ccolor:0xffffff&style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:road.highway%7Celement:geometry%7Ccolor:0xdadada&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:transit.line%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:transit.station%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:water%7Celement:geometry%7Ccolor:0xc9c9c9&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&size=600x400&markers=color:blue%7Clabel:A%7Cicon:';

class Enquire {
  constructor(el) {
    this.enquire = el;
    this.id = el.getAttribute('data-enquire');
    this.targetIndex = 1;
    this.currentIndex = 1;
    this.locationInputInited = false;
    this.lat = null;
    this.lng = null;
    this.store = {};
    this.dealers = [];
    this.skipDealers = 0;
    this.isEnquire = this.id === 'enquire';
    this.maxInitCount = 60;

    this.init();
  }

  init() {
    if (window.google) {
      this.initDOMElements();
      this.initBindings();
    } else if (--this.maxInitCount > 0) {
      setTimeout(this.init, 100);
    }
  }

  initDOMElements() {
    this.enquireLocation = this.enquire.querySelector('[data-enquire-location]');
    this.enquireContents = this.enquire.querySelectorAll('[data-enquire-content]');
    this.enquireDealerContent = this.enquire.querySelector('[data-enquire-dealer-list]');
    this.enquireBackButton = this.enquire.querySelector('[data-enquire-back]');
    this.enquireButtons = this.enquire.querySelectorAll('[data-enquire-target]');
    this.loadMoreButton = this.enquire.querySelectorAll('[data-load-more]');
    if (this.enquireButtons) {
      this.firstEnquireButton = this.enquireButtons[0];
    }
    this.enquirePager = this.enquire.querySelector('[data-pager]');
    this.enquireSearch = this.enquire.querySelector('[data-enquire-search]');

    if (navigator.geolocation && window.google) {
      this.enquireSearch.classList.add('show');
    }
    this.dealerSlider = sliderController.getSliderById(this.id);
  }

  initBindings() {
    forEach(this.enquireButtons, (item) => {
      item.addEventListener('click', (e) => {
        this.onEnquireButton(e);
      });

      accessibilityHelper.subscribe(item, 'open', (e) => {
        this.onEnquireButton(e);
      });
    });

    this.enquireSearch.addEventListener('click', () => {
      this.getLocation();
    });

    accessibilityHelper.subscribe(this.enquireSearch, 'open', this.getLocation.bind(this));

    if (this.enquireLocation) {
      /* eslint-disable no-undef */
      this.enquireLocation.addEventListener('input', () => {
        if (!this.locationInputInited && google) {
          const options = { types: ['(cities)'] };
          const autocomplete = new google.maps.places.Autocomplete(this.enquireLocation, options);
          autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (((place || {}).geometry || {}).location) {
              this.lat = place.geometry.location.lat();
              this.lng = place.geometry.location.lng();
              this.updateDealers();
            } else {
              disableButton(this.firstEnquireButton);
            }
          });
          this.locationInputInited = true;
        }
      });
      /* eslint-enable */

      this.enquireLocation.addEventListener('keypress', (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
        }
      });
    }

    forEach(this.loadMoreButton, (item) => {
      item.addEventListener('click', () => {
        this.loadMoreDealers();
      });

      accessibilityHelper.subscribe(item, 'open', this.loadMoreDealers.bind(this));
    });

    this.enquireBackButton.addEventListener('click', (e) => {
      this.targetIndex -= 1;
      this.navigateToPageIndex(e);
    });

    accessibilityHelper.subscribe(this.enquireBackButton, 'open', (e) => {
      this.targetIndex -= 1;
      this.navigateToPageIndex(e);
    });

    forEach(this.enquireContents, (item) => {
      item.addEventListener('transitionend', (e) => {
        this.onCardTransition(e);
      });
    });

    window.addEventListener('resize', () => {
      const currentItem = this.enquire.querySelector(`[data-enquire-content="${this.currentIndex}"]`);

      this.setSectionHeight(currentItem);
    });
  }

  navigateToPageIndex(e) {
    e.preventDefault();

    this.hidePages();
    if (this.enquirePager) {
      helper.setActualPagerButton(this.enquirePager, this.targetIndex - 1);
    }
  }

  saveValues(e) {
    if (this.currentIndex === 2) {
      this.store.EnquireType = e.currentTarget.getAttribute('data-enquire-type');
    }

    if (this.currentIndex === 3) {
      this.store.DealerCode = e.currentTarget.getAttribute('data-enquire-dealer');
    }
  }

  getStore() {
    return this.store;
  }

  onCardTransition(e) {
    if (e.propertyName === 'opacity') {
      if (!e.target.classList.contains('visible')) {
        e.target.classList.remove('show');
      }

      if (parseInt(e.target.getAttribute('data-enquire-content'), 10) === this.currentIndex
        && !e.target.classList.contains('visible')) {
        const nextItem = this.enquire.querySelector(`[data-enquire-content="${this.targetIndex}"]`);
        this.enquire.setAttribute('data-enquire-page', this.targetIndex);

        this.showPage(nextItem);
        this.currentIndex = this.targetIndex;
      }
    }
  }

  onEnquireButton(e) {
    if (!e.currentTarget.classList.contains('button--disabled')) {
      this.targetIndex = parseInt(e.currentTarget.getAttribute('data-enquire-target'), 10);
      if (!e.currentTarget.hasAttribute('data-submit-form')) {
        this.navigateToPageIndex(e);
      }
    }
    this.saveValues(e);
  }

  showPage(item) {
    item.classList.add('show');

    /* eslint-disable no-param-reassign */
    setTimeout(() => {
      this.setSectionHeight(item);
      item.style.transform = 'translateY(0)';
      item.classList.add('visible');
      item.setAttribute('aria-hidden', 'false');
    }, 100);
    /* eslint-enable no-param-reassign */
  }

  updateDealers() {
    addLoader(this.firstEnquireButton);
    service.findDealers(this.lat, this.lng)
      .then((res) => {
        this.dealers = res;
        /* eslint-disable no-param-reassign */

        forEach(this.dealers, (item) => {
          item.isEnquire = this.isEnquire;
        });
        this.skipDealers = 0;
        this.enquireDealerContent.innerHTML = '';
        if (window.innerWidth > 768) {
          this.getDealers(6);
        } else {
          this.getDealers(2);
        }
        this.dealerSlider.init();
        this.dealerSlider.handleFocus();
        removeLoader(this.firstEnquireButton);
      })
      .catch(() => {
        removeLoader(this.firstEnquireButton);
        disableButton(this.firstEnquireButton);
      });
  }

  getDealers(amount) {
    this.showItems = this.skipDealers + amount;
    let i = this.skipDealers;

    if (this.enquireDealerContent !== null) {
      const mapCoords = this.dealers.map((v) => ({ lat: v.lat, lon: v.lon }));

      for (i; i < this.showItems; i++) {
        if (this.dealers[i]) {
          const dealerInfo = document.createElement('div');
          dealerInfo.className = 'enquire__card block--bg-white col col--3';
          dealerInfo.setAttribute('data-slider-item', '');
          dealerInfo.innerHTML = template.render('enquireDealerInfo', this.dealers[i]);

          this.enquireDealerContent.appendChild(dealerInfo);
          accessibilityHelper.removeClickFocus(this.enquireDealerContent);

          this.showMap(mapCoords, i);
        }
      }
      this.updateDealerTargetButtons();
      enableButton(this.firstEnquireButton);
    }

    this.skipDealers += amount;
  }

  loadMoreDealers() {
    if (window.innerWidth > 768) {
      this.getDealers(3);
    } else {
      this.getDealers(1);
    }
    this.dealerSlider.initDOMElements();
  }

  updateDealerTargetButtons() {
    const dealerButtons = document.querySelectorAll('[data-enquire-target="4"]');
    if (dealerButtons) {
      forEach(dealerButtons, (item) => {
        if (!item.hasAttribute('data-inited')) {
          item.setAttribute('data-inited', 'true');
          item.addEventListener('click', (e) => {
            if (!e.currentTarget.classList.contains('button--disabled')) {
              this.targetIndex = parseInt(e.currentTarget.getAttribute('data-enquire-target'), 10);
              this.navigateToPageIndex(e);
              this.saveValues(e);
            }
          });
        }
      });
    }
  }

  hidePages() {
    const direction = this.currentIndex > this.targetIndex ? 1 : -1;

    forEach(this.enquireContents, (item) => {
      const pageIndex = parseInt(item.getAttribute('data-enquire-content'), 10);

      /* eslint-disable no-param-reassign */
      if (pageIndex === this.currentIndex) {
        item.classList.remove('visible');
        item.style.transform = `translateY(${direction * 100}%)`;
        item.setAttribute('aria-hidden', 'true');
      } else if (pageIndex !== this.currentIndex && pageIndex !== this.targetIndex) {
        item.classList.remove('visible');
        item.style.transform = 'translateY(100%)';
        item.setAttribute('aria-hidden', 'true');
      }
      /* eslint-enable no-param-reassign */
    });
  }

  getLocation() {
    /* eslint-disable no-undef */
    const geocoder = new google.maps.Geocoder();
    const location = {};

    navigator.geolocation.getCurrentPosition((position) => {
      this.lat = position.coords.latitude;
      this.lng = position.coords.longitude;

      location.lat = this.lat;
      location.lng = this.lng;

      geocoder.geocode({ location }, (results) => {
        this.enquireLocation.value = results[3].formatted_address;
        this.enquireLocation.focus();
      });

      this.updateDealers();
    });
    /* eslint-enable no-undef */
  }

  showMap(mapCoords, id) {
    const enquireMaps = this.enquire.querySelectorAll('[data-enquire-map]');

    forEach(enquireMaps, (item, index) => {
      if (id === index) {
        const latitude = mapCoords[index].lat;
        const longitude = mapCoords[index].lon;
        const url = `${MAPHOST_URL}?key=${GOOGLE_APIKEY}&center=${latitude},${longitude}&${MAP_STYLE}${PIN_IMAGE_URL}%7C${latitude},${longitude}`;
        const image = item.querySelector('img');
        image.src = url;
      }
    });
  }

  setSectionHeight(item) {
    const padding = parseInt(window.getComputedStyle(this.enquire).paddingTop, 10)
      + parseInt(window.getComputedStyle(this.enquire).paddingBottom, 10);

    this.enquire.style.height = `${padding + item.clientHeight}px`;
  }

}

export default Enquire;
