/**
 *  @fileOverview Button helper
 *
 *  @author       Daniel Jung <daniel.jung@possible.com>
 *
 */

const disabledClass = 'button--disabled';
const loaderClass = 'button--loading';

export const enableButton = (button) => {
  if (button && button.classList.contains(disabledClass)) {
    button.classList.remove(disabledClass);
  }
};

export const disableButton = (button) => {
  if (button && !button.classList.contains(disabledClass)) {
    button.classList.add(disabledClass);
  }
};

export const removeLoader = (button) => {
  if (button && button.classList.contains(loaderClass)) {
    button.classList.remove(loaderClass);
  }
};

export const addLoader = (button) => {
  if (button && !button.classList.contains(loaderClass)) {
    button.classList.add(loaderClass);
  }
};
