/**
 *  @fileOverview Accessibility helper
 *
 *  @author       Julia Ero <julia.ero@possible.com>
 *  @author       Daniel Jung <daniel.jung@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     NPM:ally.js
 *  @requires     utils/locator
 */

import forEach from 'lodash/forEach';
import ally from 'ally.js';
import inCMS from './locator';

const OPEN_KEYS = [13, 32];
const CLOSE_KEYS = [27];
const NAV_KEYS = [9];

class AccessibilityHelper {
  constructor() {
    this.rootElement = document.activeElement;
  }

  subscribe(el, type, cb) {
    el.addEventListener('keydown', (e) => {
      if (el.getAttribute('data-focus-root') === 'true') {
        this.rootElement = el;
      }

      const code = parseInt(e.keyCode, 10);
      let ACTION_KEYS = [];

      switch (type) {
        case 'open':
          ACTION_KEYS = OPEN_KEYS;
          break;
        case 'close':
          ACTION_KEYS = CLOSE_KEYS;
          break;
        default:
          ACTION_KEYS = OPEN_KEYS;
          break;
      }

      if (!inCMS() && ACTION_KEYS.indexOf(code) !== -1) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        cb(e);
      }
    });
  }

  restoreRootElementFocus() {
    if (this.rootElement) {
      this.rootElement.focus();
    }
  }

  setFocusedElement(el) {
    const firstFocusable = ally.query.firstTabbable({
      context: el,
      defaultToContext: true,
      strategy: 'quick',
    });

    if (firstFocusable) {
      firstFocusable.focus();
    }
  }

  hide(el) {
    if (el && el.hasAttribute('aria-hidden')) {
      el.setAttribute('aria-hidden', 'true');
    }
  }

  show(el) {
    if (el && el.hasAttribute('aria-hidden')) {
      el.setAttribute('aria-hidden', 'false');
    }
  }

  disableFocus(el) {
    const focusables = ally.query.focusable({
      context: el,
      includeContext: true,
      strategy: 'all',
    });

    if (focusables.length > 0) {
      forEach(focusables, (item) => {
        item.setAttribute('tabindex', '-1');
        item.setAttribute('aria-hidden', 'true');
      });
    }
  }

  enableFocus(el) {
    const focusables = ally.query.focusable({
      context: el,
      includeContext: true,
      strategy: 'all',
    });

    if (focusables.length > 0) {
      forEach(focusables, (item) => {
        item.setAttribute('tabindex', '0');
        item.setAttribute('aria-hidden', 'false');
      });
    }
  }

  removeClickFocus(context = document.body) {
    const focusables = ally.query.focusable({
      context,
      includeContext: true,
      strategy: 'all',
    });

    forEach(focusables, (item) => {
      item.addEventListener('mousedown', (e) => {
        if (item === e.currentTarget) {
          item.classList.add('no-outline');
        } else {
          item.classList.remove('no-outline');
        }
      });

      item.addEventListener('keyup', (e) => {
        if (NAV_KEYS.indexOf(e.keyCode) !== -1 && item.classList && item.classList.remove) {
          item.classList.remove('no-outline');
        }
      });
    });
  }
}

export const accessibilityHelper = new AccessibilityHelper();
