/**
 *  @fileOverview GlobalHeader
 *
 *  @author       Adrian Bobak <adrian.bobak@wundermanthompson.com>
 *
 */

const STICKY = 'sticky';
const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

class GlobalHeader {
  constructor() {
    this.isSticky = false;
    this.hamburgerEvt = new Event('onHamburgerClick');
  }

  onScroll() {
    if (window.scrollY > 0 && !this.isSticky) {
      this.isSticky = true;
      this.el.classList.add(STICKY);
    } else if (window.scrollY <= 0 && this.isSticky) {
      this.isSticky = false;
      this.el.classList.remove(STICKY);
    }
  }

  initObserver() {
    this.onScroll();
    window.addEventListener('scroll', () => this.onScroll(), { passive: true });
  }

  handleHamburgerBtn() {
    this.hamburger = this.el.querySelector('.hamburger');
    this.hamburger.addEventListener('click', () => {
      document.dispatchEvent(this.hamburgerEvt);
    });
  }

  init() {
    this.el = document.querySelector('.global-header');
    if (!this.el) return;

    this.initObserver();
    this.handleHamburgerBtn();

    if (isFirefox) this.el.classList.add('global-header--ff');
    this.el.classList.add('active');
  }
}

export const globalHeader = new GlobalHeader();
