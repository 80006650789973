/**
 *  @fileOverview Template loader
 *
 *  @author       Julia Ero <julia.ero@possible.com>
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *
 *  @requires     NPM:handlebars
 *  @requires     NPM:lodash
 */

import forEach from 'lodash/forEach';
import Handlebars from 'handlebars';

class HandlebarsHelper {
  constructor() {
    const scripts = document.body.querySelectorAll('[data-hbs-template]');
    this.templates = {};
    this.addHelpers();

    forEach(scripts, (script) => {
      const key = script.getAttribute('data-hbs-template');
      this.templates[key] = Handlebars.compile(script.innerHTML);
    });
  }

  /* eslint-disable func-names */
  addHelpers() {
    Handlebars.registerHelper('ifCond', function (v1, v2, options) {
      if (v1 === v2) {
        return options.fn(this);
      }
      return options.inverse(this);
    });
  }
  /* eslint-enable func-names */

  render(key, data = {}) {
    return this.templates[key] ? this.templates[key](data) : '';
  }
}

export const template = new HandlebarsHelper();
