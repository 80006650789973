/**
 *  @fileOverview Subnavigation module, navigation handler
 *
 *  @author       Julia Ero <julia.ero@possible.com>
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     NPM:detect-it
 *  @requires     modules/overlay-controller
 *  @requires     modules/parallax-controller
 *  @requires     utils/jump
 *  @requires     utils/easings
 *  @requires     utils/helper
 */

import forEach from 'lodash/forEach';
import throttle from 'lodash/throttle';
import getGlobalOffsetTop from '../utils/global-offset';
import jump from '../utils/jump';
import { easeOutSine } from '../utils/easings';
import { helper } from '../utils/helper';
import { parallaxController } from './parallax-controller';
import { overlayController } from './overlay-controller';

class SubNavigation {
  init() {
    this.subNav = null;
    this.activeMenu = 0;
    this.prevScrollPosition = 0;
    this.subnavHeight = 0;
    this.currentSubnavItem = null;
    this.directScroll = false;
    this.screenWidth = window.innerWidth;
    this.subNavInView = true;
    this.initDOMElements();

    if (this.subNav !== null) {
      this.highlightSection();
      this.initBindings();

      if (window.location.hash.length > 0) {
        this.scrollToHash();
        this.setActiveItems(this.subNavItems);
        this.setActiveItems(this.subnavOverlayItems);
      }
      if (window.pageYOffset < 65) {
        this.subNav.classList.add('subnavigation--inited');
      } else {
        this.subNav.classList.add('subnavigation--inited-instant');
      }
    }
  }

  initDOMElements() {
    this.header = document.body.querySelector('header');
    this.subNav = document.body.querySelector('[data-subnavigation]');
    this.subnavHeight = this.subNav ? this.subNav.clientHeight : 0;
    this.header = document.body.querySelector('header');
    this.pageSections = document.body.querySelectorAll('[data-section]');
    this.subnavOverlay = document.body.querySelector('[data-overlay="subnavigation-menu"]');
    this.headerParallaxItem = parallaxController.getParallaxItemByName('header');

    const originalColor = helper.getPageColor();
    const colorInHex = helper.colorToHex(originalColor);
    const lighterColor = helper.lightenDarkenColor(colorInHex, 10);

    if (this.subNav !== null) {
      this.subNav.style.backgroundColor = `${lighterColor}`;
      this.subNavWrapper = this.subNav.querySelector('[data-subnavigation-wrapper]');
      this.subNavItems = this.subNav.querySelectorAll('[data-subnavigation-wrapper] li a');
    }

    if (this.subnavOverlay !== null) {
      this.subnavOverlay.style.backgroundColor = `${lighterColor}`;
      this.subnavOverlayItems = this.subnavOverlay.querySelectorAll('li a');
    }

    if (this.header !== null) {
      this.headerMenus = this.header.querySelectorAll('nav > div');
    }
  }

  initBindings() {
    window.addEventListener('scroll', throttle(() => {
      this.setMenuPositions();
    }, 50));

    window.addEventListener('resize', throttle(() => {
      this.onResize();
    }, 50));

    window.addEventListener('hashchange', this.onHashChange.bind(this));
  }

  setMenuPositions() {
    const activeOverlays = overlayController.getOverlays();
    if (activeOverlays.length === 0) {
      this.controlSubNav();
      this.highlightSection();
    }
  }

  controlSubNav() {
    const scrollDirection = this.prevScrollPosition < window.pageYOffset ? -1 : 1;
    if (this.subNav !== null) {
      this.subNavInView = this.subNav.offsetTop > window.pageYOffset - this.subNav.offsetHeight;

      if (this.subNavInView) {
        this.header.classList.remove('header--subnavigation-active');
        if (this.headerParallaxItem) {
          this.headerParallaxItem.enableParallax();
        }
        this.showHeaderItems();
      } else if (this.screenWidth > 768) {
        this.hideHeaderItems(true);
      }

      if (
        window.pageYOffset > this.header.clientHeight
      ) {
        if (this.screenWidth < 769) {
          this.hideHeaderItems();
        }
      }

      if (!this.subNavInView) {
        this.header.classList.add('header--subnavigation-active');
        if (this.headerParallaxItem) {
          this.headerParallaxItem.disableParallax();
        }
      }

      if (!this.subNavInView && this.screenWidth < 769) {
        this.showHeaderItems(true);
      } else if (window.pageYOffset <= this.header.clientHeight) {
        this.showHeaderItems();
      }

      if (scrollDirection > 0 && this.subNavInView) {
        this.subNavWrapper.classList.remove('hidden');
        this.subNavWrapper.classList.remove('subnavigation__wrapper--fixed');
      } else if (scrollDirection < 0 && !this.subNavInView) {
        this.subNavWrapper.classList.add('subnavigation__wrapper--fixed');
      }

      this.prevScrollPosition = window.pageYOffset;
    }
  }

  onResize() {
    this.screenWidth = window.innerWidth;
    this.highlightSection();
  }

  onMenuTransition(e) {
    if (window.innerWidth < 769 && e.propertyName === 'opacity') {
      if (!e.target.classList.contains('visible')) {
        e.target.classList.remove('active');
      }
    }
  }

  onHashChange(e) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.scrollToHash();
    this.setActiveItems(this.subNavItems);
    this.setActiveItems(this.subnavOverlayItems);
  }

  scrollToHash() {
    this.directScroll = true;

    forEach(this.pageSections, (item, index) => {
      const href = item.getAttribute('data-section');
      if (href === window.location.hash.slice(1)) {
        this.currentSubnavItem = this.subNav.querySelector(`[href='#${href}']`);
        this.activeMenu = index;
        this.goToSection(item);
      }
    });
  }

  goToSection(el) {
    const diff = Math.abs(getGlobalOffsetTop(el) - window.pageYOffset);
    const duration = Math.min(diff / 4, 3000);
    if (el !== null) {
      jump(el, {
        duration,
        offset: -this.subnavHeight + 1,
        easing: easeOutSine,
        callback: () => {
          this.highlightClickedSection();
          setTimeout(() => {
            this.directScroll = false;
          }, 100);
        },
      });
    }
  }

  highlightClickedSection() {
    if (this.subNavItems && this.currentSubnavItem) {
      forEach(this.subNavItems, (item) => {
        item.classList.remove('active');
      });
      this.currentSubnavItem.classList.add('active');
    }
  }

  highlightSection() {
    if (!this.directScroll) {
      forEach(this.pageSections, (item, index) => {
        if (window.pageYOffset + this.subnavHeight >= item.offsetTop) {
          this.activeMenu = index;
        }
      });
      this.setActiveItems(this.subNavItems);
      this.setActiveItems(this.subnavOverlayItems);
    }
  }

  setActiveItems(navItems) {
    forEach(navItems, (item, index) => {
      if (index === this.activeMenu) {
        item.classList.add('active');
      } else {
        item.classList.remove('active');
      }

      if (window.innerWidth < 769) {
        if (index === this.activeMenu) {
          item.classList.add('active');

          setTimeout(() => {
            item.classList.add('visible');
          }, 50);
        } else {
          item.classList.remove('visible');
        }
      }
    });
  }

  hideHeaderItems(justLogo = false) {
    this.disableLogo();
    if (justLogo === false) {
      forEach(this.headerMenus, (item) => {
        item.classList.add('hidden');
      });
    }
  }

  showHeaderItems(withoutLogo = false) {
    if (withoutLogo === false) {
      this.enableLogo();
    }
    forEach(this.headerMenus, (item) => {
      item.classList.remove('hidden');
    });
  }

  disableLogo() {
    this.header.classList.add('disable');
  }

  enableLogo() {
    this.header.classList.remove('disable');
  }

  destroy() {
    window.removeEventListener('hashchange', this.onHashChange);
  }

  getDirectScroll() {
    return this.directScroll;
  }

}

export const subNavigation = new SubNavigation();
