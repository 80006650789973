/**
 *  @fileOverview Specs accordion handler
 *
 *  @author       Julia Ero <julia.ero@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     utils/accessibility
 */

import forEach from 'lodash/forEach';
import { accessibilityHelper } from '../utils/accessibility';

class SpecsAccordion {
  constructor(specs) {
    this.specsAccordion = specs;
    this.specsAccordion.setAttribute('data-specs-accordion-inited', 'true');

    this.initDOMElements();
    this.initBindings();
  }

  get accordionReference() {
    return this.specsAccordion;
  }

  initDOMElements() {
    if (this.specsAccordion) {
      this.accordionButton = this.specsAccordion.querySelector('[data-specs-accordion-opener]');
      this.accordionContent = this.specsAccordion.querySelector('[data-specs-accordion-content]');
      if (this.accordionContent) {
        this.accordionContentRows = this.accordionContent.querySelectorAll('[data-specs-accordion-content-row]');
        this.accordionContentBlocks = this.accordionContent.querySelectorAll('[data-specs-accordion-content-block]');
      }
    }
  }

  initBindings() {
    if (this.accordionButton) {
      this.accordionButton.addEventListener('click', () => {
        this.toggleAccordion();
      });

      accessibilityHelper.subscribe(this.accordionButton, 'open', this.toggleAccordion.bind(this));
    }
  }

  toggleAccordion() {
    const activeClass = 'specs-accordion--opened';
    const accordionContentHeight = this.getAccordionContentHeight();

    if (this.specsAccordion.classList.contains(activeClass)) {
      this.accordionContent.removeAttribute('style');
      this.specsAccordion.classList.remove(activeClass);
    } else {
      this.accordionContent.style.height = `${accordionContentHeight}px`;
      this.specsAccordion.classList.add(activeClass);
    }
  }

  getAccordionContentHeight() {
    let height = 0;

    if (this.accordionContent.getAttribute('data-content-layout') === 'columns') {
      forEach(this.accordionContentBlocks, (item) => {
        const columns = item.querySelectorAll('[data-specs-accordion-content-column]');

        height += this.getColumnBlockHeight(columns) + 55;
      });
    } else {
      forEach(this.accordionContentRows, (item) => {
        height += item.clientHeight;
      });
      height += 55;
    }

    return height;
  }

  getColumnBlockHeight(columns) {
    let height = 0;

    if (window.innerWidth > 768) {
      const colLengths = [];
      let highestCol = null;
      let highestColItems = null;

      forEach(columns, (item) => {
        colLengths.push(item.querySelectorAll('li').length);
      });

      highestCol = columns[colLengths.indexOf(Math.max(...colLengths))];
      highestColItems = highestCol.querySelectorAll('li');

      forEach(highestColItems, (item) => {
        height += item.clientHeight;
      });

      height += 50;
    } else {
      forEach(columns, (col) => {
        const colItems = col.querySelectorAll('li');

        forEach(colItems, (item) => {
          height += item.clientHeight;
        });

        height += 50;
      });
    }

    return height;
  }

}

export default SpecsAccordion;
