/**
 *  @fileOverview LockTabbable - lock tabbable elements under popup and overlays
 *
 *  @author       Szymon Grzybek <lukasz.goral@possible.com>
 *
 */

import forEach from 'lodash/forEach';

class LockTabbable {
  constructor() {
    this.tabables = document.querySelectorAll('button, body [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
  }

  lock(exceptionSelector) {
    forEach(this.tabables, (el) => {
      if (exceptionSelector && !el.closest(exceptionSelector)) {
        el.setAttribute('tabindex', '-1');
        el.classList.add('js-tabbable-blocked');
      }
    });
  }

  unlock() {
    forEach(this.tabables, (el) => {
      el.setAttribute('tabindex', null);
      el.classList.remove('js-tabbable-blocked');
    });
  }

}

export const lockTabbable = new LockTabbable();
