/**
 *  @fileOverview Tab handler, tab solution
 *
 *  @author       Julia Ero <julia.ero@possible.com>
 *  @author       Daniel Jung <daniel.jung@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     modules/image-handler
 *  @requires     modules/parallax-controller
 *  @requires     utils/object-fit
 */

import forEach from 'lodash/forEach';
import { imageHandler } from './image-handler';
import { objectFit } from '../utils/object-fit';
import { parallaxController } from './parallax-controller';

class TabModule {
  constructor(module) {
    this.tabModule = module;
    this.activeIndex = 0;
    this.tabSwitchInProgress = false;

    if (this.tabModule) {
      this.initDOMElements();
      this.initBindings();
    }
  }

  initDOMElements() {
    this.tabItems = this.tabModule.querySelectorAll('[data-tab-item]');
    this.tabContents = this.tabModule.querySelectorAll('[data-tab-content-item]');
  }

  initBindings() {
    if (this.tabItems.length) {
      forEach(this.tabItems, (tabItem) => {
        tabItem.addEventListener('click', (e) => {
          e.preventDefault();
          if (!this.tabSwitchInProgress) {
            const newIndex = Number(tabItem.getAttribute('data-tab-item'));
            if (newIndex !== this.activeIndex) {
              this.tabSwitchInProgress = true;
              this.switchActiveTab(tabItem);
              this.switchActiveTabContent(tabItem);
            }
          }
        });
      });
    }

    if (this.tabContents.length) {
      forEach(this.tabContents, (tabContent) => {
        tabContent.addEventListener('transitionend', () => {
          if (this.tabSwitchInProgress) {
            this.tabSwitchInProgress = false;
            tabContent.classList.remove('active');
            this.fadeIn();
            imageHandler.checkAndLoad();
            objectFit.update();
            parallaxController.init();
          }
        });
        if (tabContent.classList.contains('active')) {
          tabContent.setAttribute('aria-hidden', 'false');
        } else {
          tabContent.setAttribute('aria-hidden', 'true');
        }
      });
    }
  }

  fadeOut(tabContentItem) {
    tabContentItem.classList.remove('fade');
    this.clickedTabContent.setAttribute('aria-hidden', 'true');
  }

  fadeIn() {
    this.clickedTabContent = this.tabModule.querySelector(`[data-tab-content-item="${this.activeIndex}"]`);
    this.clickedTabContent.classList.add('active');
    this.clickedTabContent.setAttribute('aria-hidden', 'false');
    setTimeout(() => {
      this.clickedTabContent.classList.add('fade');
    }, 100);
  }

  switchActiveTab(tabItem) {
    const currentTabItem = this.tabModule.querySelector(`[data-tab-item="${this.activeIndex}"]`);
    currentTabItem.classList.remove('active');
    tabItem.classList.add('active');
  }

  switchActiveTabContent(tabItem) {
    this.clickedTabContent = this.tabModule.querySelector(`[data-tab-content-item="${this.activeIndex}"]`);
    this.fadeOut(this.clickedTabContent);
    this.activeIndex = Number(tabItem.getAttribute('data-tab-item'));
  }
}

export default TabModule;
