/**
 *  @fileOverview Iframe - loading iframes with 'iframe-data' attribute
 *  and resize height of it to 100%
 *  @author       Lukasz Goral <lukasz.goral@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     NPM:iframe-resizer
 */
import iframeResizer from 'iframe-resizer/js/iframeResizer';

class IframeLoad {
  constructor(el) {
    this.el = el;
    this.lazyLoadIframe();
  }

  lazyLoadIframe() {
    const threshold = [0.01, 1.0];
    const rootMargin = '500px';
    this.observer = new IntersectionObserver((entries) => {
      [...entries].forEach((entry) => {
        if (entry.isIntersecting) {
          // Setting the iframe url from js to trigger the loading of the iframe resources
          const url = this.el.getAttribute('data-src') || '';
          if (url) {
            this.el.setAttribute('src', url);
            iframeResizer({ heightCalculationMethod: 'taggedElement' }, this.el);
            this.observer.disconnect();
          } else {
            this.el.remove();
          }

        }
      });
    }, {
      threshold,
      rootMargin,
    });
    this.observer.observe(this.el);
  }
}

class IframeController {
  init() {
    this.iframeModules = null;
    document.querySelectorAll('[data-iframe-resizer]').forEach((el) => {
      try {
        // eslint-disable-next-line no-new
        new IframeLoad(el);
      } catch (e) {
        console.warn(e);
      }
    });
  }
}

export const iframeController = new IframeController();
