/**
 *  @fileOverview LanguageSwitcher
 *
 *  @author       Bogdan Niedźwiecki <bogdan.niedzwiecki@wundermanthompson.com>
 *
 */

class LanguageSwitcher {
  constructor(el) {
    this.el = el;
    this.languageSwitcherOpen = this.el.querySelector('.language-switcher-open');
    this.languageSwitcherPopup = this.el.querySelector('.language-switcher-popup');
    this.languageSwitcherClose = this.el.querySelector('.language-switcher-close');

    this.languageSwitcherClickOutside = (e) => {
      if (!this.languageSwitcherOpen.contains(e.target)
        && !this.languageSwitcherPopup.contains(e.target)) {
        this.toggleLanguageSwither();
      }
    };

    this.toggleLanguageSwither = () => {
      // open popup
      if (!this.languageSwitcherPopup.classList.contains('visible')) {
        this.languageSwitcherPopup.classList.add('active');
        this.languageSwitcherOpen.classList.add('active');
        setTimeout(() => {
          this.languageSwitcherPopup.classList.add('visible');
        }, 0);
        this.languageSwitcherClose.addEventListener('click', this.toggleLanguageSwither);
        document.body.addEventListener('click', this.languageSwitcherClickOutside);
        return;
      }

      // close popup
      this.languageSwitcherPopup.classList.remove('visible');
      this.languageSwitcherOpen.classList.remove('active');
      setTimeout(() => {
        if (!this.languageSwitcherPopup.classList.contains('visible')) {
          this.languageSwitcherPopup.classList.remove('active');
        }
      }, 300);
      this.languageSwitcherClose.removeEventListener('click', this.toggleLanguageSwither);
      document.body.removeEventListener('click', this.languageSwitcherClickOutside);
    };
  }

  init() {
    if (!this.languageSwitcherOpen || !this.languageSwitcherPopup || !this.languageSwitcherClose) {
      return;
    }

    this.languageSwitcherOpen.addEventListener('click', this.toggleLanguageSwither);
  }
}

class LanguageSwitcherController {
  init() {
    document.querySelectorAll('.language-switcher').forEach((el) => {
      try {
        const ls = new LanguageSwitcher(el);
        ls.init();
      } catch (e) {
        console.warn(e);
      }
    });
  }
}

export const languageSwitcherController = new LanguageSwitcherController();
