/**
 *  @fileOverview Object fit polyfill
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *
 *  @requires     NPM:lodash
 */

/* eslint-disable no-param-reassign */
import forEach from 'lodash/forEach';

/* eslint-disable */

class ObjectFit {

  init() {
    if ('objectFit' in document.documentElement.style !== false) {
      return;
    }
    this.media = document.body.querySelectorAll('[data-object-fit]');
    this.resizeTimer = null;
    for (let i = 0; i < this.media.length; i++) {
      const mediaType = this.media[i].nodeName.toLowerCase();

      if (mediaType === 'img') {
        this.media[i].addEventListener('load', () => {
          this.objectFit(this.media[i]);
        });
        if (this.media[i].complete) {
          this.objectFit(this.media[i]);
        } else if (this.media[i].set !== true) {          
          this.media[i].src = this.media[i].src;
          this.media[i].set = true;
        }
      } else if (mediaType === 'video') {
        if (this.media[i].readyState > 0) {
          this.objectFit(this.media[i]);
        } else {
          this.media[i].addEventListener('loadedmetadata', () => {
            this.objectFit(this.media[i]);
          });
        }
      }
    }
    window.addEventListener('resize', () => {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        this.update();
      }, 50);
    });
  }

  update() {
    if ('objectFit' in document.documentElement.style !== false) {
      return;
    }
    if (this.media) {
      for (let i = 0; i < this.media.length; i++) {
        this.objectFit(this.media[i]);
      }
    }
  }

  checkParentContainer(container) {
    const styles = window.getComputedStyle(container, null);
    const position = styles.getPropertyValue('position');
    const overflow = styles.getPropertyValue('overflow');
    const display = styles.getPropertyValue('display');

    if (!position || position === 'static') {
      container.style.position = 'relative';
    }

    if (overflow !== 'hidden') {
      container.style.overflow = 'hidden';
    }

    if (!display || display === 'inline') {
      container.style.display = 'block';
    }

    if (container.clientHeight === 0) {
      container.style.height = '100%';
    }

    if (container.className.indexOf('object-fit-polyfill') === -1) {
      container.className = `${container.className} object-fit-polyfill`;
    }
  }

  checkMediaProperties(media) {
    const styles = window.getComputedStyle(media, null);
    const constraints = {
      'max-width': 'none',
      'max-height': 'none',
      'min-width': '0px',
      'min-height': '0px',
      top: 'auto',
      right: 'auto',
      bottom: 'auto',
      left: 'auto',
      'margin-top': '0px',
      'margin-right': '0px',
      'margin-bottom': '0px',
      'margin-left': '0px',
    };

    forEach(constraints, (property) => {
      const constraint = styles.getPropertyValue(property);

      if (constraint !== constraints[property]) {
        media.style[property] = constraints[property];
      }
    });
  }

  setPosition(axis, media, objectPosition) {
    let position;
    let other;
    let start;
    let end;
    let side;

    objectPosition = objectPosition.split(' ');

    if (objectPosition.length < 2) {
      objectPosition[1] = objectPosition[0];
    }

    if (axis === 'x') {
      position = objectPosition[0];
      other = objectPosition[1];
      start = 'left';
      end = 'right';
      side = media.clientWidth;
    } else if (axis === 'y') {
      position = objectPosition[1];
      other = objectPosition[0];
      start = 'top';
      end = 'bottom';
      side = media.clientHeight;
    } else {
      return;
    }

    if (position === start || other === start) {
      media.style[start] = '0';
      return;
    }

    if (position === end || other === end) {
      media.style[end] = '0';
      return;
    }

    if (position === 'center') {
      position = '50%';
    }

    if (position.indexOf('%') >= 0) {
      position = parseInt(position, 10);
      media.style[start] = `${position}%`;
      media.style.transition = 'all 0s';
      media.style.transform = `translate${axis.toUpperCase()}(-${position}%)`;
    } else {
      media.style[start] = position;
    }
  }

  objectFit(media) {
    let fit = (media.dataset) ? media.dataset.objectFit : media.getAttribute('data-object-fit');
    let position = (media.dataset) ? media.dataset.objectPosition : media.getAttribute('data-object-position');
    fit = fit || 'cover';
    position = position || '50% 50%';
    media.style.width = 'auto';
    media.style.height = 'auto';

    const container = media.parentNode;

    const containerRatio = container.clientWidth / container.clientHeight;
    const mediaRatio = media.clientWidth / media.clientHeight;
    this.checkParentContainer(container);
    this.checkMediaProperties(media);

    media.style.position = 'absolute';
    media.style.height = '100%';
    media.style.width = 'auto';

    if (fit === 'scale-down') {
      media.style.height = 'auto';

      if (
        media.clientWidth < container.clientWidth &&
        media.clientHeight < container.clientHeight
      ) {
        this.setPosition('x', media, position);
        this.setPosition('y', media, position);
      } else {
        fit = 'contain';
        media.style.height = '100%';
      }
    }

    if (fit === 'none') {
      media.style.width = 'auto';
      media.style.height = 'auto';
      this.setPosition('x', media, position);
      this.setPosition('y', media, position);
    } else if (
      (
        fit === 'cover' && mediaRatio > containerRatio
      ) ||
      (
        fit === 'contain' && mediaRatio < containerRatio
      )
    ) {
      media.style.top = '0';
      media.style.marginTop = '0';
      this.setPosition('x', media, position);
    } else if (fit !== 'scale-down') {
      media.style.width = '100%';
      media.style.height = 'auto';
      media.style.left = '0';
      media.style.marginLeft = '0';
      this.setPosition('y', media, position);
    }
  }

}

export const objectFit = new ObjectFit();
