/**
 *  @fileOverview Specs accordion controller
 *
 *  @author       Julia Ero <julia.ero@possible.com>
 *  @author       Daniel Jung <daniel.jung@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     modules/service
 *  @requires     modules/template
 *  @requires     modules/specs-accordion-controller
 *  @requires     modules/count-up-controller
 *  @requires     modules/slider-controller
 *  @requires     utils/jump
 *  @requires     utils/easings
 *  @requires     utils/accessibility
 *  @requires     utils/helper
 */

import forEach from 'lodash/forEach';
import jump from '../utils/jump';
import { easeOutSine } from '../utils/easings';
import { service } from './service';
import { template } from './template';
import { specsAccordionController } from './specs-accordion-controller';
import { countUpController } from './count-up-controller';
import { sliderController } from './slider-controller';
import { helper } from '../utils/helper';
import { imageHandler } from './image-handler';
import { accessibilityHelper } from '../utils/accessibility';
import getDeviceProperties from '../utils/device-properties';

class SpecsTemplate {
  init() {
    this.modelVariations = {};
    this.presets = {};
    this.presetUrls = {};
    this.presetImageHeroes = {};
    this.lastPresetId = null;
    this.unhandeledTransition = null;
    this.presetImagesLoaded = false;
    this.heroQuality = 70;
    this.initDOMElements();

    if (this.configurator !== null) {
      this.initBindings();
      this.loadSpecsData();
    }
  }

  initDOMElements() {
    this.configurator = document.querySelector('[data-configurator]');
    this.viewSpecs = document.querySelector('[data-toggle-specs]');
    this.configLink = document.querySelector('[data-configurator-link]');
    this.specs = document.querySelector('[data-specs]');
    this.mainSpecs = document.querySelector('[data-specs-main]');
    this.presetSpecs = document.querySelector('[data-specs-preset]');
    if (this.configurator !== null) {
      this.modelID = this.configurator.getAttribute('data-model-id');
      this.modelVersionID = this.configurator.getAttribute('data-model-version-id');
      this.presetID = this.configurator.getAttribute('data-version-preset-id');
      this.presetHeroFixedImage = this.configurator.querySelector('[data-model-hero-fix] img');
      this.presetHeroTempImage = this.configurator.querySelector('[data-model-hero-temp] img');
    }
    this.presetDescription = document.querySelector('[data-preset-description]');
  }

  initBindings() {
    this.initViewSpecsBindings();

    if (this.configurator) {
      this.configurator.addEventListener('transitionend', () => {
        if (this.unhandeledTransition) {
          this.presetHeroFixedImage.src = this.presetImageHeroes[this.lastPresetId].src;
          this.configurator.removeAttribute('style');
          this.configurator.classList.remove('transitioning');
          this.configurator.classList.remove('changing');
          this.unhandeledTransition = false;
        }
      });
    }
  }

  initViewSpecsBindings() {
    const viewSpecsInited = this.viewSpecs.hasAttribute('data-inited');
    if (this.viewSpecs && !viewSpecsInited) {
      this.viewSpecs.setAttribute('data-inited', 'true');
      this.viewSpecs.addEventListener('click', this.viewSpecsClickHandler.bind(this));
    }
  }

  updateViewSpecsBindings() {
    this.viewSpecs.removeEventListener('click', this.viewSpecsClickHandler);
    this.viewSpecs = document.querySelector('[data-toggle-specs]');
    this.initViewSpecsBindings();
  }

  viewSpecsClickHandler(e) {
    e.preventDefault();
    if (!this.viewSpecs.classList.contains('button--disabled')) {
      this.updateConfiguratorData();
      this.loadSpecsTemplate(true);
      this.specs.classList.remove('hidden');
      jump(this.specs, {
        duration: 800,
        easing: easeOutSine,
        offset: -65,
      });
    }
  }

  loadSpecsData() {
    const specsPromise = service.specs(this.modelID);
    specsPromise.then((data) => {
      this.getPresets(data.Versions);
      this.getModelVariation(data.Versions);
      this.loadPresetHeros();
      this.viewSpecs.classList.remove('button--disabled');
      if (this.configLink !== null) {
        this.configLink.classList.remove('button--disabled');
      }
    });
  }

  loadSpecsTemplate(ignoreDesc) {
    this.loadPresetSpecsById(this.presetID, ignoreDesc);
    this.loadModelVariationById(this.modelVersionID);
    helper.animateBlocks();
    sliderController.init();
    accessibilityHelper.removeClickFocus();
  }

  getModelVariation(data) {
    forEach(data, (item) => {
      this.modelVariations[item.Id] = item;
    });
  }

  getPresets(data) {
    const pixelRatio = getDeviceProperties.pixelRatio;
    const clientWidth = this.presetHeroFixedImage.clientWidth;
    const imgWidth = parseInt(clientWidth * pixelRatio, 10);
    const selectedWidth = imageHandler.getClosestSize(imgWidth);

    forEach(data, (item) => {
      forEach(item.Presets, (preset) => {
        this.presets[preset.Id] = preset;
        const heroUrl = preset.Hero.SelectedSizeUrl;
        const joinChar = this.hasQueryString(heroUrl) ? '&' : '?';
        if (this.notPng(heroUrl)) {
          this.presetUrls[preset.Id] = `${heroUrl}${joinChar}w=${selectedWidth}&quality=${this.heroQuality}`;
        } else {
          this.presetUrls[preset.Id] = `${heroUrl}${joinChar}w=${selectedWidth}&format=jpg&quality=${this.heroQuality}`;
        }
      });
    });
  }

  getContext() {
    return this;
  }

  updateConfiguratorData() {
    this.modelID = this.configurator.getAttribute('data-model-id');
    this.modelVersionID = this.configurator.getAttribute('data-model-version-id');
    this.presetID = this.configurator.getAttribute('data-version-preset-id');
  }

  loadPresetHeros() {
    const presetIds = Object.keys(this.presets);
    const images = this.loadImagesByPaths(this.presetUrls);

    Promise.all(images)
      .then((res) => {
        res.forEach((img, index) => {
          this.presetImageHeroes[presetIds[index]] = img;
          this.presetImagesLoaded = true;
        });
      });
  }

  /* eslint-disable prefer-promise-reject-errors */
  loadImagesByPaths(paths) {
    const keys = Object.keys(paths);
    const carImages = [];
    keys.forEach((key) => {
      carImages.push(
        new Promise((resolve, reject) => {
          const image = new Image();
          image.onload = () => {
            resolve(image);
          };
          image.onerror = (() => reject(new Error('Could not load image!')));
          image.src = paths[key];
        })
      );
    });
    return carImages;
  }
  /* eslint-enable */

  changePresetHero(id) {
    if (
      this.configurator && this.presetImagesLoaded
      && (this.presetImageHeroes[id] || {}).src
    ) {
      if (this.presetHeroTempImage && this.presetHeroFixedImage) {
        this.lastPresetId = id;
        this.unhandeledTransition = true;
        this.presetHeroTempImage.src = this.presetImageHeroes[id].src;
        this.configurator.classList.add('transitioning');
        this.configurator.classList.add('changing');
      }
    }
  }

  loadPresetSpecsById(id, ignoreDesc) {
    if (this.presetSpecs && this.presetDescription) {
      const preset = this.presets[id];
      this.presetSpecs.innerHTML = template.render('presetSpecs', preset);
      if (!ignoreDesc) {
        this.presetDescription.innerHTML = template.render('presetDescription', preset);
        this.changePresetHero(id);
      }
      this.updateDynamicContent();
    }
  }

  loadModelVariationById(id) {
    if (this.mainSpecs) {
      const modelVariation = this.modelVariations[id];
      this.mainSpecs.innerHTML = template.render('mainSpecs', modelVariation);
      this.updateDynamicContent();
    }
  }

  updateDynamicContent() {
    helper.animateBlocks();
    specsAccordionController.init();
    countUpController.init();
    this.updateViewSpecsBindings();
  }

  hasQueryString(url) {
    return url.indexOf('?') > -1;
  }

  notPng(url) {
    return url.indexOf('.png') === -1;
  }
}

export const specsTemplate = new SpecsTemplate();
