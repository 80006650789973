/**
 *  @fileOverview Cookie Bar - GDPR
 *
 *  @author       Szymon Grzybek <szymon.grzybek@possible.com>
 *
 *  @requires     NPM:lodash
 */

class CookieBar {
  init() {
    this.cookieName = 'AMLcookiePolicy';
    if (document.querySelectorAll('.cookies').length) {
      if (this.getCookie(this.cookieName)) return;
      this.initDOMElements();
      this.initBindings();
      this.showCookieBar();
    }
  }

  initDOMElements() {
    this.cookieBar = document.querySelector('.cookies');
    this.cookieCTA = this.cookieBar.querySelector('.cookies__cta');
  }

  initBindings() {
    this.cookieCTA.addEventListener('click', this.hideCookieBar.bind(this));
  }

  showCookieBar() {
    this.cookieBar.classList.add('cookies--visible');
  }

  hideCookieBar(e) {
    e.preventDefault();
    this.setCookie(this.cookieName, 'accepted', 365, '/');
    this.cookieBar.classList.remove('cookies--visible');
  }

  setCookie(name, value = 'accepted', expires = 365, path = '/', domain = '', secure = false) {
    const today = new Date();
    today.setTime(today.getTime());
    const expirationTime = expires * 1000 * 60 * 60 * 24;
    const expiresDate = new Date(today.getTime() + expirationTime).toGMTString();
    /* eslint-disable */
    const theCookie = name + "=" + escape(value) +
      ((expiresDate) ? "; expires=" + expiresDate : "") +
      ((path) ? "; path=" + path : "") +
      ((domain) ? "; domain=" + domain : "") +
      ((secure) ? "; secure" : "");
    document.cookie = theCookie;
    /* eslint-enable */
  }

  getCookie(name) {
    if (document.cookie.length) {
      let cStart = document.cookie.indexOf(`${name}=`);
      /* eslint-disable */
      if (~cStart) {
        cStart = cStart + name.length + 1;
        let cEnd = document.cookie.indexOf(';', cStart);
        if (!~cEnd) {
          cEnd = document.cookie.length;
        }
        return decodeURI(document.cookie.substring(cStart, cEnd));
      }
      /* eslint-enable */
    }
    return '';
  }

  deleteCookie(name) {
    if (this.getCookie(name)) {
      document.cookie = `${name}=; expiresThu, 01 Jan 1970 00:00:01 GMT;`;
    }
  }
}

export const cookieBar = new CookieBar();
