/**
 *  @fileOverview General helper functions
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *  @author       Daniel Jung <daniel.jung@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     NPM:detect-it
 *  @requires     NPM:in-view
 *  @requires     utils/device-properties
 *  @requires     utils/object-fit
 *  @requires     utils/develop
 *  @requires     utils/svg-sprite-loader
 */

import { deviceType, supportsPassiveEvents } from 'detect-it';
import throttle from 'lodash/throttle';
import forEach from 'lodash/forEach';
import inView from 'in-view';
import getDeviceProperties from './device-properties';
import { objectFit } from './object-fit';
import { develop } from './develop';
import loadSvgSprite from './svg-sprite-loader';
import closest from './closest';

const DEBUG_HOSTS = /localhost/ig;
let scrollTimerId;

/**
 * General page helper methods and utilities
 */

class Helper {

  constructor() {
    this.inited = false;
  }

  init() {
    this.fixedElements = [];

    if (!this.inited) {
      loadSvgSprite();
    }
    if (!Element.prototype.closest) {
      closest();
    }

    if ('objectFit' in document.documentElement.style === false) {
      objectFit.init();

      if (!this.inited) {
        window.addEventListener('resize', throttle(() => {
          objectFit.update();
        }, 100));
      }
    }

    this.onScrollBind = this.onScroll.bind(this);
    if (deviceType === 'mouseOnly') {
      this.initScrollPerformance();
    }
    this.collectFixedElements();
    this.animateBlocks();

    if (deviceType !== 'mouseOnly') {
      document.documentElement.classList.remove('no-touch');
      document.documentElement.classList.add('touch');
    }

    if (
      window.location
      && window.location.host
      && window.location.host.match(DEBUG_HOSTS) !== null
      && this.inited === false
    ) {
      develop.init();
    }
    this.inited = true;
  }

  destroy() {
    this.fixedElements.forEach((elem) => {
      if (elem && elem.elem) {
        elem.elem.removeAttribute('style');
      }
    });
    this.fixedElements = [];
    window.removeEventListener('scroll', this.onScrollBind);
    inView('[data-animate]').off('enter');
  }

  initScrollPerformance() {
    window.addEventListener(
      'scroll',
      this.onScrollBind,
      supportsPassiveEvents ? { capture: false, passive: true } : false
    );
  }

  onScroll() {
    window.clearTimeout(scrollTimerId);
    if (document.body.querySelector('main').classList.contains('no-hover') === false) {
      this.disableHover();
    }

    scrollTimerId = setTimeout(() => {
      this.enableHover();
    }, 500);
  }

  disableHover() {
    document.body.querySelector('main').classList.add('no-hover');
  }

  enableHover() {
    document.body.querySelector('main').classList.remove('no-hover');
  }

  getParentNode(elem, selector) {
    const elements = [];
    const hasSelector = selector !== undefined;
    let element = elem;

    /* eslint-disable no-cond-assign, no-continue */
    while ((element = element.parentElement) !== null) {
      if (element.nodeType !== Node.ELEMENT_NODE) {
        continue;
      }
      if (!hasSelector || this.selectorMatches(element, selector)) {
        elements.push(element);
      }
    }
    /* eslint-enable no-cond-assign, no-continue */

    return elements;
  }

  selectorMatches(el, selector) {
    const p = Element.prototype;
    const m = (s) => (
      [].indexOf.call(document.querySelectorAll(s), this) !== -1
    );
    const f = p.matches
      || p.webkitMatchesSelector
      || p.mozMatchesSelector
      || p.msMatchesSelector
      || m;

    return f.call(el, selector);
  }

  collectFixedElements() {
    let css;
    let isItFixed;
    let isFixEnabled;
    let isForced;
    let isHibrid;

    forEach(document.body.querySelectorAll('*'), (elem) => {
      css = window.getComputedStyle(elem);
      isItFixed = css.getPropertyValue('position').toString() === 'fixed';
      isFixEnabled = elem.getAttribute('data-disable-scrollfix') === null;
      isForced = elem.getAttribute('data-force-scrollfix') !== null;
      isHibrid = elem.hasAttribute('data-hibrid-scrollfix');

      if ((isItFixed && isFixEnabled) || isForced === true) {
        this.fixedElements.push({
          elem,
          initialPaddingRight: parseInt(css.getPropertyValue('padding-right'), 10),
          initialRight: parseInt(css.getPropertyValue('right'), 10),
          isHibrid,
        });
      }
    });
  }

  getFixedElements() {
    return this.fixedElements;
  }

  setScrollbarCorrection(set) {
    const correction = set ? getDeviceProperties.scrollbarWidth : 0;

    this.setBodyPadding(set, correction);
    /* eslint-disable no-param-reassign */
    this.fixedElements.forEach((elem) => {
      if (
        !elem.isHibrid
        || window.getComputedStyle(elem.elem).getPropertyValue('position').toString() === 'fixed'
      ) {
        if (elem.initialRight === 0) {
          elem.elem.style.right = `${elem.initialRight + correction}px`;
        } else {
          elem.elem.style.paddingRight = `${elem.initialPaddingRight + correction}px`;
        }
      }
    });
  }

  setBodyPadding(set, correction) {
    document.body.style.paddingRight = set ? `${correction}px` : '0px';
  }

  animateBlocks() {
    const toAnimate = document.querySelectorAll('[data-animate]');

    forEach(toAnimate, (item) => {
      if (inView.is(item)) {
        item.classList.add('lifted');
      }
    });

    inView('[data-animate]').on('enter', (el) => {
      el.classList.add('lifted');
    });
  }

  setActualPagerButton(pager, index) {
    const actualButton = pager.querySelector(`[data-target="${index + 1}"]`);
    if (actualButton) {
      const pagerButtons = pager.querySelectorAll('[data-pager-button]');

      forEach(pagerButtons, (button) => {
        button.classList.remove('pager__button--active');
      });

      actualButton.classList.add('pager__button--active');
    }
  }

  getPageColor() {
    return window.getComputedStyle(
      document.body.querySelector('main[data-page-color]') || document.body
    ).backgroundColor;
  }

  /* eslint-disable no-bitwise */

  lightenDarkenColor(col, amt) {
    let usePound = false;

    if (col[0] === '#') {
      col = col.slice(1);
      usePound = true;
    }

    const num = parseInt(col, 16);
    let r = (num >> 16) + amt;
    if (r > 255) {
      r = 255;
    } else if (r < 0) {
      r = 0;
    }

    let b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) {
      b = 255;
    } else if (b < 0) {
      b = 0;
    }

    let g = (num & 0x0000FF) + amt;

    if (g > 255) {
      g = 255;
    } else if (g < 0) {
      g = 0;
    }

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
  }

  isLightColor(col) {
    if (col[0] === '#') {
      col = col.slice(1);
    }

    const num = parseInt(col, 16);
    const r = (num >> 16);
    const g = ((num >> 8) & 0x00FF);
    const b = (num & 0x0000FF);

    return (r > 200 && g > 200 && b > 200);
  }

  colorToHex(color) {
    if (color.substr(0, 1) === '#' || color === 'transparent') {
      return color;
    }
    let digits = /(.*?)rgb\((\d+), (\d+), (\d+)\)/.exec(color);
    if (!digits) {
      digits = /(.*?)rgba\((\d+), (\d+), (\d+), (\d+)\)/.exec(color);
    }

    const red = parseInt(digits[2], 10);
    const green = parseInt(digits[3], 10);
    const blue = parseInt(digits[4], 10);

    const rgb = blue | (green << 8) | (red << 16);

    return `${digits[1]}#${rgb.toString(16)}`;
  }

  /* eslint-enable no-bitwise */
}

export const helper = new Helper();
