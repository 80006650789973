/**
 *  @fileOverview Quote controller module
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     modules/quote
 */

import forEach from 'lodash/forEach';
import Quote from './quote';

class QuoteController {
  init() {
    this.quotes = [];
    this.initQuotes();
  }

  initQuotes() {
    forEach(document.querySelectorAll('[data-quote]'), (quote) => {
      const quoteObj = new Quote(quote);
      this.quotes.push(quoteObj);
    });
  }
}

export const quoteController = new QuoteController();
