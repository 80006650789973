/**
 *  @fileOverview Video player handler
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     NPM:detect-it
 *  @requires     NPM:in-view
 *  @requires     modules/image-handler
 *  @requires     utils/camelcase
 *  @requires     utils/accessibility
 *  @requires     utils/device-properties
 */

import forEach from 'lodash/forEach';
import { deviceType, supportsPassiveEvents } from 'detect-it';
import inView from 'in-view';
import throttle from 'lodash/throttle';
import once from 'lodash/once';
import camelCase from '../utils/camelcase';
import getDeviceProperties from '../utils/device-properties';
import { accessibilityHelper } from '../utils/accessibility';
import { imageHandler } from './image-handler';

const PLAYING = 'playing';
const PAUSED = 'paused';
const ENDED = 'ended';
const LOADED = 'loaded';
const ERROR = 'error';

class VideoPlayer {
  constructor(el, id) {
    if (!(typeof el && el.nodeName)) {
      throw TypeError('Constructor parameter should be a valid DOM element!');
    }

    this.state = [];
    this.params = {};
    this.video = el;
    this.id = id;
    this.connectedElements = [];
    this.player = el.querySelector('video');

    this.loadedCallback = null;
    this.endCallback = null;
    this.endCallbackCalled = false;

    this.isHighDefEnabled = false;
    this.normalDefSource = null;
    this.highDefSource = null;

    this.isMobileEnabled = this.video.getAttribute('data-video-desktop-only') === 'false';

    if (this.player === null) {
      throw TypeError('Cannot find a valid video element!');
    }

    if (deviceType === 'touchOnly') {
      this.video.setAttribute('data-video-mobile', true);
    }

    if (deviceType !== 'touchOnly'
      || (deviceType === 'touchOnly' && this.isMobileEnabled)
    ) {
      this.normalDefSource = this.player.querySelector('source[data-video-normaldef]');
      this.highDefSource = this.player.querySelector('source[data-video-highdef]');
      this.isHighDefEnabled = deviceType !== 'touchOnly' && screen.width > 1200;

      if (
        (this.normalDefSource !== null && this.isHighDefEnabled === false)
        || this.highDefSource === null
      ) {
        this.normalDefSource.setAttribute('src', this.normalDefSource.getAttribute('data-src'));
      } else if (this.highDefSource !== null && this.isHighDefEnabled === true) {
        this.highDefSource.setAttribute('src', this.highDefSource.getAttribute('data-src'));
      } else {
        this.normalDefSource.setAttribute('src', this.normalDefSource.getAttribute('data-src'));
      }
      this.player.load();
    }

    this.initDOMElements();
    this.initCoverImages();
    this.initPlayerParams();

    if (
      deviceType !== 'touchOnly' || this.isMobileEnabled
    ) {
      this.initEvents();
      if (this.player.readyState > 1) {
        this.onLoaded();
        this.onPlayerReady();
      } else {
        this.checkPlayerState();
      }
    } else {
      this.setState('loaded');
    }
  }

  initDOMElements() {
    this.wrapper = this.video.querySelector('[data-video-wrapper]');
    this.cover = this.video.querySelector('[data-video-cover]');
    this.triggerListener = this.video.querySelector('[data-video-trigger-listener]');
    this.controls = this.video.querySelector('[data-video-controls]');
    this.progress = this.video.querySelector('[data-video-progress]');
    this.connectedElements = document.body.querySelectorAll(`[data-video-connected="${this.id}"]`);
  }

  initCoverImages() {
    const { containerWidth } = imageHandler.getContainerSize({ el: this.video });
    const imgWidth = parseInt(containerWidth * getDeviceProperties.pixelRatio, 10);

    let landscapeItem;
    let portraitItem;
    let landscapeUrl;
    let portraitUrl;

    if (this.cover) {
      landscapeItem = this.cover.querySelector('[data-landscape-img-src]');
      portraitItem = this.cover.querySelector('[data-portrait-img-src]');

      if (landscapeItem && portraitItem) {
        landscapeUrl = landscapeItem.getAttribute('data-landscape-img-src');
        portraitUrl = portraitItem.getAttribute('data-portrait-img-src');

        const landscapeSrcParts = landscapeUrl.split('&');
        const portraitSrcParts = portraitUrl.split('&');

        landscapeUrl = landscapeSrcParts[0];
        portraitUrl = portraitSrcParts[0];

        let landscapeParametrizedSrc = landscapeUrl;
        let portraitParametrizedSrc = portraitUrl;

        if (landscapeUrl.match(/\?/ig) !== null) {
          landscapeParametrizedSrc = `${landscapeUrl}&w=${imgWidth}`;
        } else {
          landscapeParametrizedSrc = `${landscapeUrl}?w=${imgWidth}`;
        }

        if (portraitUrl.match(/\?/ig) !== null) {
          portraitParametrizedSrc = `${portraitUrl}&w=${imgWidth}`;
        } else {
          portraitParametrizedSrc = `${portraitUrl}?w=${imgWidth}`;
        }

        landscapeParametrizedSrc = `${landscapeParametrizedSrc}&quality=40`;
        portraitParametrizedSrc = `${portraitParametrizedSrc}&quality=40`;

        const initLandscape = once(() => {
          landscapeItem.style.backgroundImage = `url(${landscapeParametrizedSrc})`;
        });

        const initPortrait = once(() => {
          portraitItem.style.backgroundImage = `url(${portraitParametrizedSrc})`;
        });

        this.injectCoverImages(initLandscape, initPortrait);
      } else {
        /* eslint-disable no-console */
        console.log('%c %s %s %s ', 'color: yellow; background-color: black;', '--', `Please check cover image for the video ${this.id}, it is not set!`, '--');
        /* eslint-enable no-console */
      }
    }
  }

  initPlayerParams() {
    const l = this.video.attributes.length;
    let i = 0;
    let attribute;

    for (i; i < l; i++) {
      attribute = this.video.attributes[i];

      if (deviceType === 'touchOnly' && this.isMobileEnabled && attribute.value === 'hover') {
        attribute.value = 'in-view';
      }

      if (attribute.name.includes('data-video-')) {
        this.params[camelCase(attribute.name.substring(11))] = attribute.value.toLowerCase();
      }
    }

    this.fixBooleans();
  }

  initEvents() {
    this.player.addEventListener('canplay', this.onLoaded.bind(this));
    this.player.addEventListener('playing', this.onPlay.bind(this));
    this.player.addEventListener('pause', this.onPause.bind(this));
    this.player.addEventListener('ended', this.onEnd.bind(this));
    this.player.addEventListener('error', this.onError.bind(this));
    this.player.addEventListener('timeupdate', this.onTimeUpdate.bind(this));
  }

  initBindings() {
    if (this.params.controlsEnabled) {
      forEach(this.video.querySelectorAll('[data-video-control]'), (control) => {
        control.addEventListener('click', this.onControlClick.bind(this));
        accessibilityHelper.subscribe(control, 'open', this.onControlClick.bind(this));
      });
    }

    if (this.params.trigger === 'click') {
      this.triggerListener.addEventListener('click', this.onClick.bind(this));
      accessibilityHelper.subscribe(this.triggerListener, 'open', this.onClick.bind(this));
    } else
    if (this.params.trigger === 'hover') {
      this.triggerListener.addEventListener('mouseover', this.onMouseOver.bind(this));
      this.triggerListener.addEventListener('mouseleave', this.onMouseLeave.bind(this));
    } else
    if (this.params.trigger === 'in-view') {
      /*
      * AML-663 [S. Thorne]: If the Element is null (not found) this throws an Exception
      * when calling the inView.is(Element) method. inView(Element CSS Selector) does not
      * appear to be affected.
      * IE11 in particular doesn't handle this well, which results in a jerky scroll and
      * videos not auto-playing. Assert that Element exists first.
      */
      const e = document.querySelector(`[data-video="${this.id}"]`);
      if (typeof e !== 'undefined' && e !== null) {
        if (inView.is(e)) {
          setTimeout(() => {
            this.playVideo();
          }, 100);
        }
      }

      inView(`[data-video="${this.id}"]`).on('enter', () => {
        this.playVideo();
      });

      inView(`[data-video="${this.id}"]`).on('exit', () => {
        this.pauseVideo();
      });
    }
  }

  orientationMethod(methodLandscape, methodPortrait) {
    if (window.innerHeight > window.innerWidth) {
      methodPortrait();
    } else {
      methodLandscape();
    }
  }

  injectCoverImages(initLandscape, initPortrait) {
    this.orientationMethod(initLandscape, initPortrait);
    window.addEventListener('resize', throttle(() => {
      this.orientationMethod(initLandscape, initPortrait);
    }, 50), supportsPassiveEvents ? { capture: false, passive: true } : false);
  }

  checkPlayerState() {
    if (this.player.readyState > 3) {
      this.onLoaded();
      this.onPlayerReady();
    } else {
      window.setTimeout(() => {
        this.checkPlayerState();
      }, 100);
    }
  }

  onClick(event) {
    event.preventDefault();

    if (this.getState(PLAYING)) {
      this.pauseVideo();
    } else {
      this.playVideo();
    }
  }

  onMouseOver() {
    if (this.getState(PLAYING) !== true && this.getState(LOADED)) {
      this.playVideo();
    }
  }

  onMouseLeave() {
    if (this.getState(PLAYING)) {
      this.pauseVideo();
    }
  }

  onControlClick(event) {
    const item = event.currentTarget;
    const action = item.getAttribute('data-video-control').toLowerCase();

    switch (action) {
      case 'play':
        this.playVideo();
        this.setActiveControl();
        break;
      case 'pause':
        this.pauseVideo();
        this.setActiveControl();
        break;
      case 'mute':
        this.muteVideo();
        this.video.querySelector('[data-video-control="unmute"]').classList.add('control-button--active');
        this.video.querySelector('[data-video-control="mute"]').classList.remove('control-button--active');
        this.video.querySelector('[data-video-control="unmute"]').focus();
        break;
      case 'unmute':
        this.unmuteVideo();
        this.video.querySelector('[data-video-control="mute"]').classList.add('control-button--active');
        this.video.querySelector('[data-video-control="unmute"]').classList.remove('control-button--active');
        this.video.querySelector('[data-video-control="mute"]').focus();
        break;
      default:
        break;
    }
  }

  onPlayerReady() {
    inView.threshold(0.1);
    this.initBindings();
    this.configurePlayer();
    this.update();
  }

  onPlay() {
    this.removeState(PAUSED);
    this.setState(PLAYING);
  }

  onPause() {
    this.removeState(PLAYING);
    this.setState(PAUSED);
  }

  onEnd() {
    this.removeState(PLAYING);
    this.removeState(PAUSED);
    this.setState(ENDED);

    if (this.endCallback && typeof this.endCallback === 'function') {
      this.endCallback();
    }
  }

  onError() {
    this.removeState(PAUSED);
    this.removeState(PLAYING);
    this.setState(ERROR);
  }

  onLoaded() {
    this.setState(LOADED);

    if (this.loadedCallback && typeof this.loadedCallback === 'function') {
      this.loadedCallback();
    }
  }

  onTimeUpdate() {
    let percent = Math.floor((this.player.currentTime / this.player.duration) * 100);

    if (isNaN(percent)) {
      percent = 0;
    }

    if (percent > 0 && percent < 50 && this.endCallbackCalled === true) {
      this.endCallbackCalled = false;
    }

    if (
      (percent > 96)
      && this.params.loop === true
      && this.endCallbackCalled === false
    ) {
      if (
        this.endCallback
        && typeof this.endCallback === 'function'
      ) {
        this.endCallbackCalled = true;
        this.endCallback();
      }
    }
  }

  setActiveControl() {
    if (this.getState(PLAYING)) {
      this.video.querySelector('[data-video-control="play"]').classList.add('control-button--active');
      this.video.querySelector('[data-video-control="pause"]').classList.remove('control-button--active');
      this.video.querySelector('[data-video-control="play"]').focus();
    } else {
      this.video.querySelector('[data-video-control="play"]').classList.remove('control-button--active');
      this.video.querySelector('[data-video-control="pause"]').classList.add('control-button--active');
      this.video.querySelector('[data-video-control="pause"]').focus();
    }
  }

  update() {
    if (this.params.controlsEnabled && this.getState(PLAYING) === true) {
      this.updateProgressBar();
    }
    this.requestID = requestAnimationFrame(this.update.bind(this));
  }

  updateProgressBar() {
    const percent = Math.floor((this.player.currentTime / this.player.duration) * 1000) / 1000;
    this.progress.style.transform = `scaleX(${percent})`;
  }

  getState(state = PLAYING) {
    return this.state.includes(state);
  }

  setState(state = null) {
    if (state !== null && this.state.includes(state) === false) {
      this.state.push(state);
      this.video.classList.add(`video--${state}`);
      forEach(this.connectedElements, (elem) => {
        elem.classList.add(`video--${state}`);
      });
    }
    this.video.setAttribute('data-video-state', this.state.join('|'));
  }

  removeState(state) {
    this.video.classList.remove(`video--${state}`);
    forEach(this.connectedElements, (elem) => {
      elem.classList.remove(`video--${state}`);
    });
    this.state = this.state.filter((val) => (val !== state));
    this.setState();
  }

  fixBooleans() {
    let fixedParam;

    forEach(this.params, (param, key) => {
      fixedParam = param;

      if (fixedParam === 'true') {
        fixedParam = true;
      }

      if (fixedParam === 'false') {
        fixedParam = false;
      }

      this.params[key] = fixedParam;
    });
  }

  configurePlayer() {
    this.setPosterImage();
    this.checkAutoPlay();
  }

  setPosterImage() {
    if (this.params.hidePoster === true) {
      this.video.classList.add('video--hide-poster');
    }
  }

  checkAutoPlay() {
    this.player.pause();
    /*
    * AML-663 [S. Thorne]
    * Assert DOM Element exists
    */
    const e = document.querySelector(`[data-video="${this.id}"]`);
    if (
      this.params.autoplay === true
      && (
        this.params.trigger === 'none'
        || (typeof e !== 'undefined' && e !== null && inView.is(e))
      )
    ) {
      this.player.volume = 0;
      this.player.play();
    }
  }

  playVideo() {
    this.player.play();
  }

  pauseVideo() {
    this.player.pause();
  }

  stopVideo() {
    this.pauseVideo();
    this.player.currentTime = 0;
    this.updateProgressBar();
  }

  muteVideo() {
    this.player.volume = 0;
  }

  unmuteVideo() {
    this.player.volume = 1;
  }

  setLoadedCallback(callback) {
    this.loadedCallback = callback;
    if (
      deviceType === 'touchOnly'
      || this.player.readyState > 3
    ) {
      this.loadedCallback();
    }
  }

  setPlayCallback(callback) {
    this.playCallback = callback;

    if (this.playCallback && typeof this.playCallback === 'function') {
      this.playCallback();
    }
  }

  setPauseCallback(callback) {
    this.pauseCallback = callback;

    if (this.pauseCallback && typeof this.pauseCallback === 'function') {
      this.pauseCallback();
    }
  }

  setEndCallback(callback) {
    this.endCallback = callback;
  }

}

export default VideoPlayer;
