/**
 *  @fileOverview Global landing page handler
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *
 *  @requires     NPM:detect-it
 *  @requires     modules/custom-select
 */

import { deviceType } from 'detect-it';
import CustomSelect from './custom-select';
import { accessibilityHelper } from '../utils/accessibility';
import { addLoader } from '../utils/button-helper';

class GlobalLandingPageController {

  init() {
    this.selectedLanding = null;
    this.initDOMElements();
    this.initBindings();
  }

  initDOMElements() {
    this.main = document.body.querySelector('main');
    if (this.main) {
      this.form = this.main.querySelector('form[data-form="global-landing"]');
    }

    if (this.form) {
      this.landingSelect = this.form.querySelector('select[data-landing-select]');
      this.landingCTA = this.form.querySelector('[data-global-landing-cta]');
    }
  }

  initBindings() {
    if (this.landingSelect) {
      this.customLandingSelect = new CustomSelect(this.landingSelect);
      this.landingSelect.addEventListener('change', () => {
        const selected = this.landingSelect.querySelector('option[selected]');
        if (deviceType === 'touchOnly') {
          window.scrollTo(0, 0);
        }
        if (selected && selected.value !== '') {
          this.selectedLanding = selected.value;
          this.landingCTA.classList.remove('button--disabled');
          this.landingCTA.removeAttribute('disabled');
        } else {
          this.selectedLanding = null;
          this.landingCTA.classList.add('button--disabled');
          this.landingCTA.setAttribute('disabled', true);
        }
      });
    }

    if (this.landingCTA) {
      this.landingCTA.addEventListener('click', (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.redirectUser();
      });

      accessibilityHelper.subscribe(this.landingCTA, 'open', () => {
        this.redirectUser();
      });
    }
  }

  redirectUser() {
    if (this.selectedLanding) {
      addLoader(this.landingCTA);
      window.location.href = this.selectedLanding;
    }
  }

}

export const globalLandingPageController = new GlobalLandingPageController();
