/**
 *  @fileOverview Rich accordion module, based on the user scroll it changes size and opacity
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *  @author       Tamas Oberritter <tamas.oberritter@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     NPM:withinViewport
 *  @requires     utils/global-offset
 *  @requires     utils/accessibility
 */

import throttle from 'lodash/throttle';
import { withinViewport } from 'withinviewport';
import { deviceType } from 'detect-it';
import { parallaxController } from './parallax-controller';
import getGlobalOffsetTop from '../utils/global-offset';
import { accessibilityHelper } from '../utils/accessibility';
import { subNavigation } from './subnavigation';

/**
 * The maximum height of the accordion in closed state
 * @constant
 *
 * @type {number}
 */
const TITLE_HEIGHT = 195;

class Accordion {
  constructor(config) {
    if (!(typeof config.el && config.el.nodeName)) {
      throw TypeError('Constructor parameter should be a valid DOM element!');
    }

    this.el = config.el;
    this.id = config.id;
    this.titleHeight = config.titleHeight || TITLE_HEIGHT;
    this.currentTitleHeight = 0;
    this.animationInProgress = false;
    this.isCMS = document.querySelectorAll('.sfPageEditor').length > 0;

    this.initDOMElements();
    this.initState();
    if (this.title && this.content && !this.isCMS) {
      this.checkSpacing();
      this.initBindings();
    }
  }

  initDOMElements() {
    this.title = this.el.querySelector('.accordion__title');
    if (this.title) {
      this.titleWrapper = this.title.querySelector('.wrapper');
      if (!this.titleWrapper) {
        /* eslint-disable no-console */
        console.log('%c %s %s %s ', 'color: yellow; background-color: black;', '--', 'Accordion - Title wrapper element is missing!', '--');
        /* eslint-enable no-console */
      }
    } else {
      /* eslint-disable no-console */
      console.log('%c %s %s %s ', 'color: yellow; background-color: black;', '--', 'Accordion - Title element is missing!', '--');
      /* eslint-enable no-console */
    }
    this.button = this.el.querySelector('.accordion__button');
    this.content = this.el.querySelector('.accordion__content');
    this.inner = this.el.querySelector('.accordion__inner');
  }

  initBindings() {
    this.title.addEventListener('click', this.onToggle.bind(this));
    accessibilityHelper.subscribe(this.title, 'open', this.onToggle.bind(this));
    this.content.addEventListener('transitionend', this.animationEnd.bind(this));
    if (this.isCMS === false && deviceType !== 'touchOnly') {
      window.addEventListener('scroll', throttle(this.onScroll.bind(this), 30));
    } else if (this.isCMS && this.button && this.title) {
      this.title.addEventListener('click', (e) => {
        e.preventDefault();
      });
      this.button.addEventListener('click', (e) => {
        e.preventDefault();
      });
    }
  }

  initState() {
    this.state = {
      open: this.el.getAttribute('data-accordion-open') === 'true',
      inZone: this.updateSize(),
      visible: withinViewport(this.el, {
        top: 0,
        bottom: 0,
      }),
    };
  }

  checkSpacing() {
    const parentElement = this.el.parentNode;
    const nextElement = (parentElement && parentElement.nextElementSibling) || false;
    const removeSpacing = (
      nextElement
      && nextElement.querySelectorAll('.content.content--f, .experience, .promo.promo--c').length > 0
    ) || false;

    if (removeSpacing) {
      this.el.classList.add('accordion--no-spacing');
    }
  }

  animationEnd() {
    if (this.animationInProgress) {
      this.content.style.height = this.content.style.height === '0px' ? '0px' : 'auto';
      parallaxController.updateAllParallaxItems();
      window.setTimeout(() => {
        this.animationInProgress = false;
      });
    }
  }

  open() {
    this.el.setAttribute('data-accordion-title-rotate', 'true');
    this.state.open = true;

    window.setTimeout(() => {
      this.animationInProgress = true;
      this.content.style.height = `${this.inner.offsetHeight}px`;
      this.el.setAttribute('data-accordion-open', 'true');

      if (this.openCallback && typeof this.openCallback === 'function') {
        this.openCallback.bind(this)();
      }

      this.updateSize();
    }, 500);
  }

  close() {
    this.el.setAttribute('data-accordion-title-rotate', 'false');
    this.state.open = false;

    window.setTimeout(() => {
      this.animationInProgress = true;
      this.content.style.height = `${this.inner.offsetHeight}px`;
      window.setTimeout(() => {
        this.content.style.height = 0;
      }, 20);

      this.el.setAttribute('data-accordion-open', 'false');

      if (this.closeCallback && typeof this.closeCallback === 'function') {
        this.closeCallback.bind(this)();
      }

      this.updateSize();
    }, 500);
  }

  toggle() {
    if (!this.animationInProgress) {
      this.title.classList.remove('no-transition');
      if (this.state.open) {
        this.close();
      } else {
        this.open();
      }
    }
  }

  setOpenCallback(callback) {
    this.openCallback = callback;
  }

  setCloseCallback(callback) {
    this.closeCallback = callback;
  }

  onToggle(event) {
    event.preventDefault();
    this.toggle();
  }

  updateSize() {
    window.requestAnimationFrame(() => {
      const divTop = getGlobalOffsetTop(this.el);
      const divHeight = this.currentTitleHeight;
      const docViewTop = window.pageYOffset;
      const docViewSize = window.innerHeight;
      const docViewCenter = docViewTop + (docViewSize / 2);
      const currPosition = docViewCenter - (divTop - (docViewSize / 2));
      const fullRange = docViewSize + divHeight;
      const opacityFullRange = (docViewSize / 2) + divHeight;
      const currPercentage = (currPosition / fullRange) * 100;
      const opacityPercentage = (currPosition / opacityFullRange) * 100;
      const ratio = currPercentage / 50;
      const opacityRatio = opacityPercentage / 50;
      const size = ratio < 1 ? ratio - 1 : 1 - ratio;
      const opacityValue = opacityRatio < 2 ? opacityRatio - 2 : 2 - opacityRatio;
      const height = this.titleHeight * (1 + size);

      let opacity = (100 * (1 + opacityValue)) / 100;

      if (opacity > 1) {
        opacity = 1;
      }

      if (opacity < 0) {
        opacity = 0;
      }

      if (height > 0 || height < this.titleHeight) {
        this.currentTitleHeight = height;
        if (this.title) {
          this.title.style.height = `${height}px`;
        }

        if (this.titleWrapper) {
          this.titleWrapper.style.opacity = `${opacity}`;
        }
      }
    });
  }

  checkVisibility() {
    this.state.visible = withinViewport(this.el, {
      top: -this.titleHeight,
      bottom: -this.titleHeight,
    });
  }

  onScroll() {
    this.checkVisibility();
    if (
      this.state.visible && this.state.open === false && subNavigation.getDirectScroll() === false
    ) {
      this.title.classList.add('no-transition');
      this.updateSize();
    }
  }
}

export default Accordion;
