/**
 *  @fileOverview Camelcase helper
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *
 */

const camelCase = (item) => (
  item.replace(/(-[a-z])/g, ($1) => (
    $1.toUpperCase().replace('-', '')
  )));

export default camelCase;
