/**
 *  @fileOverview Experience controller
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *
 *  @requires     NPM:in-view
 */

import inView from 'in-view';

class ExperienceController {
  init() {
    this.initBindings();
  }

  destroy() {
    inView('[data-experience]').off('enter');
  }

  initBindings() {
    inView('[data-experience]').on('enter', (elem) => {
      elem.classList.add('show');
    });
  }
}

export const experienceController = new ExperienceController();
