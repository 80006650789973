/**
 *  @fileOverview Parallax effect controller
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *  @author       Julia Ero <julia.ero@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     NPM:detect-it
 *  @requires     NPM:hammerjs
 *  @requires     modules/gallery-controller
 *  @requires     modules/image-handler
 *  @requires     modules/enquire
 *  @requires     utils/accessibility
 */
import forEach from 'lodash/forEach';
import Slider from './slider';

class SliderController {
  init() {
    this.sliders = [];
    this.initSliders();
  }

  initSliders() {
    forEach(document.querySelectorAll('[data-slider]'), (slider) => {
      if (slider.getAttribute('data-slider-inited') === null) {
        this.sliders.push(new Slider(slider));
        slider.setAttribute('data-slider-inited', true);
      }
    });
  }

  getSliderById(id) {
    return this.sliders.filter((slider) => (slider.id === id))[0];
  }
}

export const sliderController = new SliderController();
