/**
 *  @fileOverview Overlay controller module (open, close, toggle etc.)
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *  @author       Julia Ero <julia.ero@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     NPM:detect-it
 *  @requires     modules/homepage-controller
 *  @requires     modules/collection-mask
 *  @requires     modules/subnavigation
 *  @requires     modules/enquire-form-controller
 *  @requires     utils/helper
 *  @requires     utils/request-timeout
 *  @requires     utils/accessibility
 */

import { deviceType, supportsPassiveEvents } from 'detect-it';
import forEach from 'lodash/forEach';
import { requestTimeout, clearRequestTimeout } from '../utils/request-timeout';
import { homepageController } from './homepage-controller';
import { collectionMask } from './collection-mask';
import { subNavigation } from './subnavigation';
import { formController } from './enquire-form-controller';
import { helper } from '../utils/helper';
import { accessibilityHelper } from '../utils/accessibility';

let overlayRequestTimeoutId;

class OverlayController {
  constructor() {
    this.inited = false;
  }

  init() {
    if (this.inited) {
      return;
    }

    this.activeOverlays = [];
    this.overlays = [];
    this.overlayDelays = [];
    this.fixedElements = helper.getFixedElements();
    this.targetHref = '';

    this.overlayDelay = 0.3;
    this.itemDelay = 0.15;
    this.transitionDelay = 0.9;
    this.footerDelay = 0.9;

    this.initOverlays();
    this.initBindings();

    this.inited = true;
  }

  initOverlays() {
    let id;
    let isActive;

    forEach(document.body.querySelectorAll('[data-overlay]'), (elem) => {
      id = elem.getAttribute('data-overlay');
      isActive = elem.getAttribute('data-overlay-active') === 'true';

      this.overlays.push({
        id,
        overlay: elem,
      });

      if (id === 'collection-menu' || id === 'main-menu' || id === 'subnavigation-menu') {
        this.setGeneralDelays(elem, id);
      }

      if (isActive) {
        this.activeOverlays.push({
          id,
          overlay: elem,
        });
      }
    });
  }

  setGeneralDelays(overlay, id) {
    const items = overlay.querySelectorAll('[data-overlay-items-source] li').length;
    const globalDelay = parseFloat(
      (2 * this.itemDelay) + this.overlayDelay + ((items * this.itemDelay) / 2)
    ).toFixed(3);

    const beforeOpeningSelector = `.overlay.overlay--menu-opening[data-overlay='${id}'] ul::before`;
    const beforeOpeningDelayValue = this.overlayDelay + (items * this.itemDelay) + this.itemDelay;
    const beforeOpeningRule = `${beforeOpeningSelector} { transition-delay: ${beforeOpeningDelayValue}s; }`;

    const beforeClosingSelector = `.overlay.overlay--menu-closing[data-overlay='${id}'] ul::after`;
    const beforeClosingDelayValue = ((items * this.itemDelay) / 2) + this.itemDelay;
    const beforeClosingRule = `${beforeClosingSelector} { transition-delay: ${beforeClosingDelayValue}s; }`;

    /* eslint-disable no-param-reassign */
    this.overlayDelays.push({
      id,
      delay: globalDelay,
    });
    overlay.style.transitionDelay = `${globalDelay}s`;
    document.styleSheets[0].insertRule(beforeOpeningRule, 0);
    document.styleSheets[0].insertRule(beforeClosingRule, 0);
    /* eslint-enable no-param-reassign */
  }

  setOpeningDelays(overlay) {
    const items = overlay.querySelectorAll('[data-overlay-items-source] li');
    const itemNumber = items ? items.length : 0;
    const restrictedItemNumber = this.restrictItemNumbers(itemNumber, items);

    /* eslint-disable no-param-reassign */
    forEach(overlay.querySelectorAll('li'), (li, i) => {
      const liDelay = this.overlayDelay
        + ((restrictedItemNumber * this.itemDelay) - (i * this.itemDelay));
      li.style.transitionDelay = `${liDelay}s`;
    });
    /* eslint-enable no-param-reassign */
  }

  setClosingDelays(overlay, id) {
    const items = overlay.querySelectorAll('[data-overlay-items-source] li').length;

    if (id === 'main-menu') {
      const footerElem = overlay.querySelector('.overlay__footer');
      footerElem.style.transitionDelay = `${this.overlayDelay + (items * this.itemDelay)}s`;
    }

    /* eslint-disable no-param-reassign */
    forEach(overlay.querySelectorAll('li'), (li, i) => {
      const liDelay = this.itemDelay + ((i * this.itemDelay) / 2);
      li.style.transitionDelay = `${liDelay}s`;
    });
    /* eslint-enable no-param-reassign */
  }

  removeItemDelays(overlay) {
    forEach(overlay.querySelectorAll('li'), (li) => {
      li.removeAttribute('style');
    });
  }

  restrictItemNumbers(itemNumber, items) {
    const subNavigationWrapper = document.querySelector('[data-subnavigation-wrapper]');
    let result = null;
    let restrictedItemNumber = null;

    if (subNavigationWrapper && items.length > 0) {
      restrictedItemNumber = Math.floor(
        (window.innerHeight - subNavigationWrapper.clientHeight)
        / items[0].clientHeight
      );
    }

    if (restrictedItemNumber && itemNumber > restrictedItemNumber) {
      result = restrictedItemNumber;
    } else {
      result = itemNumber;
    }
    return result;
  }

  initBindings() {
    let id;

    forEach(document.body.querySelectorAll('[data-overlay]'), (elem) => {
      elem.addEventListener('transitionend', (e) => {
        e.stopPropagation();
        this.onTransitionEnd(e.target);
        if (this.getOverlays().length === 0) {
          accessibilityHelper.restoreRootElementFocus();
        }
      });
    });

    forEach(document.body.querySelectorAll('[data-overlay] nav ul'), (elem) => {
      const itemLength = elem.querySelectorAll('li').length;
      const overlay = helper.getParentNode(elem, '[data-overlay]')[0];
      elem.addEventListener('transitionend', (e) => {
        e.stopPropagation();
        const nodeIndex = [...e.target.parentNode.children].indexOf(e.target);
        const isActive = overlay.getAttribute('data-overlay-active') === 'true';
        if (
          (
            (isActive === false && nodeIndex === itemLength - 1)
            || (isActive === true && nodeIndex === 0)
          )
          && overlay
          && e.propertyName.toLowerCase() === 'transform'
        ) {
          if (isActive) {
            overlay.classList.add('overlay--menu-opened');
          } else {
            overlay.classList.remove('overlay--menu-opened');
          }
          overlay.classList.remove('overlay--menu-opening');
          overlay.classList.remove('overlay--menu-closing');
        }
      });
    });

    forEach(document.body.querySelectorAll('[data-toggle-overlay]'), (elem) => {
      id = (elem.getAttribute('data-toggle-overlay' || '')).toLowerCase();
      if (id) {
        elem.addEventListener('click', this.toggleOverlay.bind(this));
        accessibilityHelper.subscribe(elem, 'open', this.toggleOverlay.bind(this));
      }
    });

    forEach(document.body.querySelectorAll('[data-open-overlay]'), (elem) => {
      id = (elem.getAttribute('data-open-overlay' || '')).toLowerCase();
      if (id) {
        elem.addEventListener('click', this.openOverlay.bind(this));
        accessibilityHelper.subscribe(elem, 'open', this.openOverlay.bind(this));
      }
    });

    forEach(document.body.querySelectorAll('[data-close-overlay]'), (elem) => {
      elem.addEventListener('click', () => {
        this.closeOverlayHandler(elem, id);
      });
      accessibilityHelper.subscribe(elem, 'open', () => {
        this.closeOverlayHandler(elem, id);
      });
    });

    forEach(document.body.querySelectorAll('[data-overlay="subnavigation-menu"] a'), (elem) => {
      elem.addEventListener('click', (e) => {
        this.freezeViewport(e);
        this.closeOverlay('subnavigation-menu');
        this.targetHref = e.currentTarget.getAttribute('href');
      });
    });

    if (deviceType === 'mouseOnly') {
      window.addEventListener('keyup', (e) => {
        const code = parseInt(e.keyCode, 10);
        if (code === 27 && this.activeOverlays.length > 0) {
          this.closeOverlay(this.activeOverlays[this.activeOverlays.length - 1].id);
        }
      });
    }
  }

  closeOverlayHandler(elem, id) {
    /* eslint-disable no-param-reassign */
    const idList = elem.getAttribute('data-close-overlay').split('|');

    forEach(idList, (item) => {
      id = item;
      if (id === '') {
        id = this.activeOverlays[this.activeOverlays.length - 1].id;
      }
      const overlayIds = this.overlays.map((v) => v.id);
      if (overlayIds && overlayIds.some((v) => v === id)) {
        this.closeOverlay(id);
      }
    });
    /* eslint-enable */
  }

  onTransitionEnd(target) {
    let id;

    if (target.getAttribute('data-overlay') !== null) {
      id = target.getAttribute('data-overlay');
      if (target.classList.contains('overlay--closing')) {
        target.setAttribute('data-overlay-visible', false);
        target.setAttribute('aria-hidden', 'true');
        target.classList.remove('overlay--menu-opening');
        target.classList.remove('overlay--menu-opened');
        target.classList.remove('overlay--menu-closing');

        if (id === 'collection-menu' || id === 'main-menu') {
          this.removeItemDelays(target);
          if (id === 'main-menu') {
            target.querySelector('.overlay__footer').removeAttribute('style');
          }
        }
      }

      target.classList.remove('overlay--opening');
      target.classList.remove('overlay--closing');
      target.classList.remove('overlay--no-transition');

      if (target.getAttribute('data-overlay-active') === 'false') {
        if (this.activeOverlays.length === 0) {
          if (deviceType === 'mouseOnly' || deviceType === 'hybrid') {
            helper.setScrollbarCorrection(false);
            document.body.classList.remove('overlay-active');
          }
        }
      }

      if (id === 'subnavigation-menu') {
        location.hash = this.targetHref;
      }
    }
  }

  openOverlay(event, isToggle = false, directTarget = false, directId) {
    let id;

    if (event && directTarget === false) {
      id = event.currentTarget.getAttribute(
        isToggle
          ? 'data-toggle-overlay'
          : 'data-open-overlay'
      ).toLowerCase() || false;
    } else if (directTarget !== false) {
      id = directTarget.getAttribute(
        isToggle
          ? 'data-toggle-overlay'
          : 'data-open-overlay'
      ).toLowerCase();
    } else {
      id = directId;
    }

    const overlay = this.getOverlayById(id);

    if (overlay) {
      overlay.querySelector('.overlay__outer-wrapper').scrollTop = 0;
      overlay.setAttribute('data-overlay-visible', true);
      overlay.setAttribute('aria-hidden', 'false');

      if (directId === 'search') {
        overlay.setAttribute('data-overlay-quicksearch', true);
      }

      if (id === 'enquire') {
        const form = formController.getFormById('overlay');
        form.showOverlayForm();
      }

      if (id === 'subnavigation-menu') {
        subNavigation.disableLogo();
        subNavigation.showHeaderItems(true);
      }

      if (id === 'collection-menu' && !collectionMask.inited) {
        collectionMask.init();
      }

      if (overlayRequestTimeoutId) {
        clearRequestTimeout(overlayRequestTimeoutId);
      }

      overlayRequestTimeoutId = requestTimeout(() => {
        if (id === 'collection-menu' || id === 'main-menu' || id === 'subnavigation-menu') {
          this.setOpeningDelays(overlay);
        }

        if (id === 'search' || id === 'enquire' || id === 'account') {
          this.getOverlayById('main-menu').setAttribute('data-overlay-active', false);
        }

        overlay.setAttribute('data-overlay-active', true);
        overlay.classList.add('overlay--opening');
        overlay.classList.add('overlay--menu-opening');
        overlay.classList.remove('overlay--closing');

        if (overlay.getAttribute('data-overlay-hide-header') !== null) {
          document.body.querySelector('header.header').classList.add('header--hide');
        }

        forEach(document.body.querySelectorAll(`[data-toggle-overlay="${id}"]`), (elem) => {
          elem.setAttribute('data-toggle-state', 'visible');
        });

        this.activeOverlays.push({
          id,
          overlay,
        });

        homepageController.disableScroll();

        if (deviceType === 'mouseOnly' || deviceType === 'hybrid') {
          document.body.classList.add('overlay-active');
          helper.setScrollbarCorrection(true);
        } else {
          this.stopBodyScrolling(true);
        }
      }, 16);
    }
  }

  closeOverlay(id, noTransition = false) {
    const overlay = this.getOverlayById(id);

    if (overlay && overlay.getAttribute('data-overlay-active') === 'true') {
      if (id === 'collection-menu' || id === 'main-menu' || id === 'subnavigation-menu') {
        this.setClosingDelays(overlay, id);
      }

      if (id === 'search' || id === 'enquire' || id === 'account') {
        this.getOverlayById('main-menu').setAttribute('data-overlay-active', true);
      }

      overlay.setAttribute('data-overlay-active', false);
      overlay.setAttribute('data-overlay-quicksearch', false);

      if (noTransition) {
        overlay.classList.add('overlay--no-transition');
      }

      overlay.classList.add('overlay--closing');
      overlay.classList.add('overlay--menu-closing');
      overlay.classList.remove('overlay--menu-opened');
      overlay.classList.remove('overlay--opening');

      if (overlay.getAttribute('data-overlay-hide-header') !== null) {
        document.body.querySelector('header.header').classList.remove('header--hide');
      }

      forEach(document.body.querySelectorAll(`[data-toggle-overlay="${id}"]`), (elem) => {
        elem.setAttribute('data-toggle-state', 'hidden');
      });

      this.activeOverlays = this.activeOverlays.filter((elem) => (
        elem.id !== id
      ));

      if (this.activeOverlays.length === 0) {
        homepageController.enabledScroll();
        this.stopBodyScrolling(false);
      }

      if (noTransition) {
        this.onTransitionEnd(overlay);
      }
    }
  }

  toggleOverlay(event) {
    const target = event.currentTarget;
    const id = target.getAttribute('data-toggle-overlay').toLowerCase();
    const isOnlyMobile = target.getAttribute('data-toggle-overlay-only-mobile') !== null;
    const state = target.getAttribute('data-toggle-state').toLowerCase();
    const connectedOverlays = target.getAttribute('data-close-overlay')
      ? target.getAttribute('data-close-overlay').split('|') : false;
    let delay = 0;

    if (isOnlyMobile && window.innerWidth > 768) {
      return false;
    }

    if (
      connectedOverlays
      && this.activeOverlays.length > 0
      && connectedOverlays.indexOf(this.activeOverlays[this.activeOverlays.length - 1].id) >= 0
    ) {
      delay = parseFloat(
        (this.overlayDelay + parseFloat(this.getDelayById(id))) * 1000
      );
    }

    event.stopPropagation();

    window.setTimeout(() => {
      if (state === 'visible') {
        target.setAttribute('data-toggle-state', 'hidden');
        this.closeOverlay(id);
      } else {
        target.setAttribute('data-toggle-state', 'visible');
        this.openOverlay(event, true, target);
      }
    }, delay);

    return true;
  }

  closeAllOverlay() {
    forEach(document.body.querySelectorAll('[data-overlay]'), (elem) => {
      this.closeOverlay((elem.getAttribute('data-overlay' || '')).toLowerCase());
    });
  }

  getOverlayById(id) {
    const overlayObj = this.overlays.filter((overlay) => (overlay.id === id))[0];
    return (overlayObj && overlayObj.overlay) || null;
  }

  getDelayById(id) {
    const delayObj = this.overlayDelays.filter((delay) => (delay.id === id))[0];
    return (delayObj && delayObj.delay) || 0;
  }

  stopBodyScrolling(stopIt) {
    if (stopIt === true) {
      document.body.querySelector('main').addEventListener(
        'touchmove',
        this.freezeViewport,
        supportsPassiveEvents ? { capture: false, passive: true } : false
      );
    } else {
      document.body.querySelector('main').removeEventListener('touchmove', this.freezeViewport);
    }
  }

  freezeViewport(e) {
    e.preventDefault();
  }

  setOverlayColor(id) {
    const currentColor = document.querySelector('main').getAttribute('data-page-color');
    const overlay = this.getOverlayById(id);

    if (overlay) {
      overlay.setAttribute('data-overlay-color', currentColor);
    }
  }

  getOverlays() {
    return this.activeOverlays;
  }

}

export const overlayController = new OverlayController();
