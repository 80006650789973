/**
 *  @fileOverview Gallery handler
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     NPM:detect-it
 *  @requires     modules/module-controller
 *  @requires     modules/overlay-controller
 *  @requires     modules/service
 *  @requires     modules/image-handler
 *  @requires     utils/global-offset
 *  @requires     utils/helper
 *  @requires     utils/object-fit
 *  @requires     utils/accessibility
 *  @requires     utils/device-properties
 */

import forEach from 'lodash/forEach';
import throttle from 'lodash/throttle';
import { supportsPassiveEvents } from 'detect-it';
import getGlobalOffsetTop from '../utils/global-offset';
import deviceProperties from '../utils/device-properties';
import { helper } from '../utils/helper';
import { objectFit } from '../utils/object-fit';
import { accessibilityHelper } from '../utils/accessibility';
import { imageHandler } from './image-handler';
import { sliderController } from './slider-controller';

const SHOW_NEXT_PAGE_CLASS = 'gallery--show-next';
const SHOW_GALLERY_OVERLAY_CLASS = 'gallery--show-overlay';
const SHOW_BUTTON_CLASS = 'gallery__icon--active';
const GALLERY_CHANGE_CLASS = 'gallery--scroll-change';
const DISABLED_BUTTON_CLASS = 'gallery__icon--disabled';
const LOOPING_ENABLED = true;
const NEXT_KEY = 39;
const PREV_KEY = 37;

class Gallery {
  constructor(el) {
    if (!(typeof el && el.nodeName)) {
      throw TypeError('Constructor parameter should be a valid DOM element!');
    }

    this.gallery = el;
    this.id = el.getAttribute('data-gallery');

    this.isCMS = document.querySelectorAll('.sfPageEditor').length > 0;
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.isNextPageActive = false;
    this.isOverlayActive = false;
    this.isInSliderMode = el.getAttribute('data-gallery-slider') !== null;
    this.activeItem = 0;
    this.itemCount = 0;
    this.timer = null;
    this.activeImageProps = {};
    this.isTouch = document.getElementsByTagName('html')[0].classList.contains('touch');
    this.activePage = 0;

    this.initDOMElements();
    if (this.isCMS === false) {
      this.initBindings();
      if (this.isInSliderMode === false) {
        this.checkGalleryPosition();
      }
    }
    this.setActiveItem();
    this.handleFocus();

    if (this.isInSliderMode === true) {
      this.setSlides();
      this.setGalleryAutoplay();
    }

    if (this.screenWidth > 1024) {
      if (this.isInSliderMode === false) {
        this.checkGalleryPosition();
      }
      this.gallerySlider.disableSlider();
    } else {
      this.gallerySlider.enableSlider();
      this.gallerySlider.init();
    }
  }

  initDOMElements() {
    this.galleryWrapper = this.gallery.querySelector('.gallery__wrapper');
    this.galleryPager = this.gallery.querySelector('.pager');
    this.galleryPagerBtns = this.galleryPager.querySelectorAll('.pager__button');
    this.pagerPrev = this.gallery.querySelector('[data-gallery-btn="prev"]');
    this.pagerNext = this.gallery.querySelector('[data-gallery-btn="next"]');
    this.items = this.gallery.querySelectorAll('[data-gallery-item]');
    this.itemCount = this.items.length;
    this.gallerySlider = sliderController.getSliderById('gallery');
  }

  initBindings() {
    window.addEventListener('resize', throttle(() => {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
      if (this.screenWidth > 1024) {
        if (this.isInSliderMode === false) {
          this.checkGalleryPosition();
        }
        this.gallerySlider.disableSlider();
      } else {
        this.setPager(0);
        this.gallerySlider.enableSlider();
        this.gallerySlider.init();
      }

      if (this.isInSliderMode) {
        this.activeItem = 0;
        this.setActiveItem();
        this.setSlides();
      }

      this.slideTo();
    }, 50));

    window.addEventListener('scroll', throttle(() => {
      if (this.screenWidth > 1024 && this.isInSliderMode === false) {
        this.checkGalleryPosition();
      }
    }, 50), supportsPassiveEvents ? { capture: false, passive: false } : false);

    this.gallery.querySelector('[data-gallery-btn="close"]').addEventListener('click', this.closeOverlay.bind(this));
    accessibilityHelper.subscribe(this.gallery, 'open', this.closeOverlay.bind(this));

    this.gallery.addEventListener('mousemove', this.onMouseMove.bind(this));

    this.gallery.addEventListener('click', this.onOverlayClick.bind(this), supportsPassiveEvents ? {
      capture: false,
      passive: false,
    } : false);

    this.gallery.addEventListener('keydown', (e) => {
      this.setGalleryAutoplay();
      if (this.isCMS === false) {
        this.onKeyDown(e);
      }
    });

    accessibilityHelper.subscribe(this.gallery, 'close', () => {
      if (this.isOverlayActive) {
        this.closeOverlay();
      }
    });

    this.pagerPrev.addEventListener('click', this.onPagerPrevClick.bind(this), false);
    this.pagerNext.addEventListener('click', this.onPagerNextClick.bind(this), false);

    forEach(this.gallery.querySelectorAll('[data-gallery-item]'), (item) => {
      item.addEventListener('click', this.onItemClick.bind(this), supportsPassiveEvents ? {
        capture: false,
        passive: false,
      } : false);

      accessibilityHelper.subscribe(item, 'open', this.onItemClick.bind(this));
    });

    forEach(this.galleryPagerBtns, (item, index) => {
      item.addEventListener('click', () => {
        if (this.isInSliderMode) {
          this.setGalleryAutoplay();
          this.activeItem = index;
          this.slideTo(this.activeItem);
          this.setPager();
          this.updatePagerIcons();
        }
      });
    });

    forEach(this.items, (item) => {
      item.addEventListener('touchstart', () => {
        if (this.isInSliderMode) {
          clearInterval(this.timer);
        }
      });
    });
    forEach(this.items, (item) => {
      item.addEventListener('touchend', () => {
        if (this.isInSliderMode) {
          this.setGalleryAutoplay();
        }
      });
    });
  }

  onItemClick(e) {
    const item = e.currentTarget;
    const itemIndex = parseInt(item.getAttribute('data-gallery-item'), 10);

    e.stopPropagation();

    if (
      this.isOverlayActive === false
      && !isNaN(itemIndex)
      && this.screenWidth > 1024
      && this.isInSliderMode === false
    ) {
      this.showOverlay(itemIndex);
      this.gallery.focus();
    }
  }

  onPagerPrevClick() {
    if (this.isInSliderMode) {
      this.activeItem -= 1;

      if (this.activeItem < 0) {
        this.activeItem = LOOPING_ENABLED ? this.itemCount - 1 : 0;
      }

      this.slideTo(this.activeItem);
      this.setPager();
      this.updatePagerIcons();
    }
  }

  onPagerNextClick() {
    if (this.isInSliderMode) {
      this.activeItem += 1;

      if (this.activeItem > this.itemCount - 1) {
        this.activeItem = LOOPING_ENABLED ? 0 : this.itemCount - 1;
      }

      this.slideTo(this.activeItem);
      this.setPager();
      this.updatePagerIcons();
    }
  }

  onMouseMove(e) {
    if (
      this.isOverlayActive === true
      && this.screenWidth > 1024
      && this.isInSliderMode === false
    ) {
      this.updateIcons(e);
    }
  }

  onOverlayClick(e) {
    if (
      this.isOverlayActive === true
      && this.screenWidth > 1024
    ) {
      const posX = e.clientX;
      const galleryWidth = this.galleryWrapper.offsetWidth;
      const leftMargin = galleryWidth / 2;

      if (posX < leftMargin) {
        this.activeItem -= 1;
      } else {
        this.activeItem += 1;
      }

      if (this.activeItem < 0) {
        this.activeItem = LOOPING_ENABLED ? this.itemCount - 1 : 0;
      }

      if (this.activeItem > this.itemCount - 1) {
        this.activeItem = LOOPING_ENABLED ? 0 : this.itemCount - 1;
      }

      this.slideTo(this.activeItem);
      this.setPager();
      this.updateIcons(e);
      this.updatePagerIcons();
    }
  }

  onKeyDown(e) {
    if (
      (this.isOverlayActive === true
      || this.isInSliderMode === true)
    ) {
      const code = parseInt(e.keyCode, 10);

      if (code === PREV_KEY) {
        this.activeItem -= 1;
      } else if (code === NEXT_KEY) {
        this.activeItem += 1;
      }

      if (this.activeItem < 0) {
        this.activeItem = LOOPING_ENABLED ? this.itemCount - 1 : 0;
      }

      if (this.activeItem > this.itemCount - 1) {
        this.activeItem = LOOPING_ENABLED ? 0 : this.itemCount - 1;
      }

      this.slideTo(this.activeItem);
      this.updateIcons(e);
      this.setPager();
      this.updatePagerIcons();
    }
  }

  onPan(index) {
    this.activeItem = index;
  }

  setSlides() {
    /* eslint-disable no-param-reassign */
    forEach(this.gallery.querySelectorAll('[data-gallery-item]'), (elem) => {
      elem.style.maxWidth = `${this.screenWidth - deviceProperties.scrollbarWidth}px`;
    });
    /* eslint-enable no-param-reassign */
  }

  updateIcons(e) {
    const posX = e.clientX;
    const posY = e.clientY;
    const galleryWidth = this.galleryWrapper.offsetWidth;
    const iconPosX = posX - 22.5;
    const iconPosY = posY - 22.5;
    const leftMargin = galleryWidth / 2;

    this.pagerPrev.classList.remove(SHOW_BUTTON_CLASS);
    this.pagerNext.classList.remove(SHOW_BUTTON_CLASS);
    this.pagerPrev.classList.add(DISABLED_BUTTON_CLASS);
    this.pagerNext.classList.add(DISABLED_BUTTON_CLASS);

    this.updatePagerIcons();

    if (iconPosY > 50) {
      if (posX < leftMargin) {
        this.pagerPrev.classList.add(SHOW_BUTTON_CLASS);
      } else {
        this.pagerNext.classList.add(SHOW_BUTTON_CLASS);
      }
      this.pagerPrev.style.transform = `translate3d(${iconPosX}px, ${iconPosY}px, 0)`;
      this.pagerNext.style.transform = `translate3d(${iconPosX}px, ${iconPosY}px, 0)`;
    }
  }

  updatePagerIcons() {
    if (LOOPING_ENABLED === false) {
      this.pagerPrev.classList.add(DISABLED_BUTTON_CLASS);
      this.pagerNext.classList.add(DISABLED_BUTTON_CLASS);

      if (this.activeItem > 0) {
        this.pagerPrev.classList.remove(DISABLED_BUTTON_CLASS);
      }

      if (this.activeItem < this.itemCount - 1) {
        this.pagerNext.classList.remove(DISABLED_BUTTON_CLASS);
      }
    } else {
      this.pagerNext.classList.remove(DISABLED_BUTTON_CLASS);
      this.pagerPrev.classList.remove(DISABLED_BUTTON_CLASS);
    }
  }

  closeOverlay() {
    this.isOverlayActive = false;
    this.slideTo(0);
    this.gallery.classList.remove(SHOW_GALLERY_OVERLAY_CLASS);
    document.body.classList.remove('fullscreen-active');
    helper.setScrollbarCorrection(false);
    this.updateImages('cover');
    this.checkGalleryPager();
    this.gallerySlider.enableSlider();
  }

  showOverlay(itemIndex) {
    this.activePage = Math.floor(itemIndex / 3);
    this.activeItem = itemIndex;
    this.isOverlayActive = true;
    this.gallerySlider.disableSlider();

    this.slideTo(itemIndex);
    this.galleryPager.classList.remove('hidden');
    this.setPager();
    this.gallery.classList.add(SHOW_GALLERY_OVERLAY_CLASS);
    document.body.classList.add('fullscreen-active');
    this.updateImages('contain');
    helper.setScrollbarCorrection(true);
  }

  setPager(index = this.activeItem) {
    let itemIndex;

    if (index === 0) {
      itemIndex = 0;
    } else {
      itemIndex = this.screenWidth < 769 || this.isOverlayActive || this.isInSliderMode
        ? this.activeItem : this.activePage * 3;
    }

    const target = itemIndex + 1;

    forEach(this.galleryPager.querySelectorAll('li'), (elem) => {
      elem.classList.remove('pager__button--active');
      if (parseInt(elem.getAttribute('data-target'), 10) === target) {
        elem.classList.add('pager__button--active');
      }
    });
  }

  resetActivePage() {
    this.activePage = 0;
    this.activeItem = 0;
    this.isNextPageActive = false;
    this.setPager();
    this.gallery.classList.remove(SHOW_NEXT_PAGE_CLASS);
  }

  checkGalleryPosition() {
    const galleryOffsetTop = getGlobalOffsetTop(this.gallery);
    const galleryCenter = (this.screenHeight - this.gallery.clientHeight) / 2;
    const isAboveTheMiddle = galleryOffsetTop - window.pageYOffset - galleryCenter < 0;
    const currentState = this.isNextPageActive === true;

    this.checkGalleryPager();

    if (this.screenWidth > 1024 && this.itemCount > 3) {
      if (isAboveTheMiddle && this.isNextPageActive === false) {
        this.isNextPageActive = true;
        this.activePage = 1;
        this.activeItem = 3;
      } else if (!isAboveTheMiddle && this.isNextPageActive === true) {
        this.isNextPageActive = false;
        this.activePage = 0;
        this.activeItem = 0;
      }
    }

    if (currentState !== this.isNextPageActive) {
      this.gallery.classList.add(GALLERY_CHANGE_CLASS);
      window.setTimeout(() => {
        this.gallery.classList.remove(GALLERY_CHANGE_CLASS);
      }, 500);

      if (this.isNextPageActive) {
        this.gallery.classList.add(SHOW_NEXT_PAGE_CLASS);
      } else {
        this.gallery.classList.remove(SHOW_NEXT_PAGE_CLASS);
      }
    }

    this.handleFocus(this.activePage);
    this.setPager();
  }

  checkGalleryPager() {
    if (this.screenWidth > 1024) {
      if (this.itemCount > 3) {
        this.galleryPager.classList.remove('hidden');
      } else {
        this.galleryPager.classList.add('hidden');
      }
    }
  }

  setActiveItem(index = this.activeItem) {
    forEach(this.gallery.querySelectorAll('[data-gallery-item]'), (item, key) => {
      item.classList.remove('gallery__item--active');
      if (key === index) {
        item.classList.add('gallery__item--active');
      }
    });
  }

  slideTo(index = this.activeItem) {
    if (
      index === 0
      || (
        this.screenWidth > 1024 && this.isOverlayActive === false && this.isInSliderMode === false
      )
    ) {
      this.galleryWrapper.removeAttribute('style');
    } else {
      this.galleryWrapper.style.transform = `translate3d(${index * -100}%, 0, 0)`;
    }
    imageHandler.checkAndLoad();
    this.setActiveItem();
    this.handleFocus();
    this.updatePagerIcons();
  }

  handleFocus(index = this.activeItem) {
    const currentItems = this.isOverlayActive || this.isInSliderMode
      ? [index]
      : [index * 3, (index * 3) + 1, (index * 3) + 2];

    forEach(this.items, (item, i) => {
      if (currentItems.indexOf(i) >= 0) {
        accessibilityHelper.enableFocus(item);
      } else {
        accessibilityHelper.disableFocus(item);
      }
    });
  }

  updateImages(fillMode) {
    forEach(this.items, (item) => {
      item.querySelector('img').setAttribute('data-object-fit', fillMode);
    });
    objectFit.update();
  }

  setGalleryAutoplay() {
    clearInterval(this.timer);
    this.timer = setInterval(() => {
      this.updateImages('cover');
      this.onPagerNextClick();
    }, 5000);
  }

}

export default Gallery;
