/**
 *  @fileOverview Video controller, video player controller
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     NPM:detect-it
 *  @requires     modules/video-player
 *  @requires     utils/helper
 *  @requires     utils/object-fit
 *  @requires     utils/accessibility
 */

import forEach from 'lodash/forEach';
import { deviceType } from 'detect-it';
import VideoPlayer from './video-player';
import { helper } from '../utils/helper';
import { objectFit } from '../utils/object-fit';
import { accessibilityHelper } from '../utils/accessibility';

class VideoController {
  constructor() {
    this.players = [];
  }

  init() {
    this.initPlayers();
    this.initBindings();
  }

  initPlayers() {
    let id;

    forEach(document.body.querySelectorAll('[data-video]'), (elem) => {
      id = elem.getAttribute('data-video');
      if (elem.getAttribute('data-video-inited') === null) {
        this.players.push({
          id,
          player: new VideoPlayer(elem, id),
        });
        elem.setAttribute('data-video-inited', true);
      }
    });
  }

  initBindings() {
    forEach(document.body.querySelectorAll('[data-video-play]'), (elem) => {
      const id = elem.getAttribute('data-video-play');
      const player = this.getPlayerById(id);

      if (player !== null && elem.getAttribute('data-video-play-inited') === null) {
        elem.addEventListener('click', (e) => {
          e.preventDefault();
          elem.setAttribute('data-video-play-inited', true);
          if (id !== null) {
            player.playVideo();
          }
        }, false);
      }
    });

    forEach(document.body.querySelectorAll('[data-video-toggle]'), (elem) => {
      const id = elem.getAttribute('data-video-toggle');
      const player = this.getPlayerById(id);

      if (player !== null && elem.getAttribute('data-video-toggle-inited') === null) {
        elem.addEventListener('click', (e) => {
          const isPlaying = player.getState('playing');
          e.preventDefault();
          elem.setAttribute('data-video-toggle-inited', true);
          if (id !== null && isPlaying === false) {
            player.playVideo();
          } else {
            player.pauseVideo();
          }
        }, false);
      }
    });

    if (deviceType === 'mouseOnly') {
      forEach(document.body.querySelectorAll('[data-video-toggle-fullscreen]'), (elem) => {
        const id = elem.getAttribute('data-video-toggle-fullscreen');
        const player = this.getPlayerById(id);

        if (player !== null && elem.getAttribute('data-video-toggle-fullscreen-inited') === null) {
          elem.setAttribute('data-trigger', 'click');
          elem.addEventListener('click', (e) => {
            e.preventDefault();
            this.onToggleFullscreen(elem, id, player);
          }, false);

          accessibilityHelper.subscribe(elem, 'open', () => {
            this.onToggleFullscreen(elem, id, player);
          });
        }
      });

      forEach(document.body.querySelectorAll('[data-video-go-fullscreen]'), (elem) => {
        const id = elem.getAttribute('data-video-go-fullscreen');
        const player = this.getPlayerById(id);

        if (player !== null && elem.getAttribute('data-video-go-fullscreen-inited') === null) {
          player.setEndCallback(() => {
            this.closeFullscreen(id);
          });
        }
      });

      forEach(document.body.querySelectorAll('[data-video-close-fullscreen]'), (elem) => {
        const id = elem.getAttribute('data-video-close-fullscreen');

        if (elem.getAttribute('data-video-close-fullscreen-inited') === null) {
          elem.addEventListener('click', (e) => {
            e.preventDefault();
            elem.setAttribute('data-video-close-fullscreen-inited', true);
            this.closeFullscreen(id);
          }, false);

          accessibilityHelper.subscribe(elem, 'open', () => {
            elem.setAttribute('data-video-close-fullscreen-inited', true);
            this.closeFullscreen(id);
          });
        }
      });

      forEach(document.body.querySelectorAll('[data-video-fullscreen]'), (elem) => {
        const id = elem.getAttribute('data-video-fullscreen');

        accessibilityHelper.subscribe(elem, 'close', () => {
          elem.setAttribute('data-video-close-fullscreen-inited', true);
          this.closeFullscreen(id);
        });
      });
    }

    if (deviceType === 'touchOnly') {
      forEach(document.body.querySelectorAll('[data-video-toggle-fullscreen]'), (elem) => {
        const id = elem.getAttribute('data-video-toggle-fullscreen');
        const player = this.getPlayerById(id);

        if (player !== null
          && elem.getAttribute('data-video-toggle-fullscreen-inited') === null
          && player.isMobileEnabled
        ) {
          const src = player.player.querySelector('[data-video-normaldef]') !== null
            ? player.player.querySelector('[data-video-normaldef]').getAttribute('data-src')
            : player.player.querySelector('[data-video-highdef]').getAttribute('data-src');

          elem.addEventListener('click', () => {
            window.location = src;
          });
        }
      });
    }
  }

  openFullscreen(id) {
    const player = this.getPlayerById(id);
    const fullscreenVideo = document.body.querySelector(`[data-video-fullscreen="${id}"]`);
    if (fullscreenVideo.classList.contains('fullscreen-video--active') === false) {
      document.body.classList.add('fullscreen-active');
      helper.setScrollbarCorrection(true);
      // accessibilityHelper.setTabbableOrder(fullscreenVideo);
      fullscreenVideo.classList.add('fullscreen-video--active');
      fullscreenVideo.setAttribute('aria-hidden', 'false');
      player.playVideo();
      player.setActiveControl();
      fullscreenVideo.focus();
    }
  }

  closeFullscreen(id) {
    const player = this.getPlayerById(id);
    const fullscreenVideo = document.body.querySelector(`[data-video-fullscreen="${id}"]`);

    document.body.classList.remove('fullscreen-active');
    fullscreenVideo.classList.remove('fullscreen-video--active');
    fullscreenVideo.setAttribute('aria-hidden', 'true');
    player.stopVideo();
    helper.setScrollbarCorrection(false);
  }

  onGoFullscreen(elem, id, player) {
    const isPlaying = player.getState('playing');

    elem.setAttribute('data-video-go-fullscreen-inited', true);
    if (id !== null && isPlaying === false) {
      this.openFullscreen(id);
      objectFit.update();
    }
  }

  onToggleFullscreen(elem, id, player) {
    const isPlaying = player.getState('playing');
    elem.setAttribute('data-video-toggle-fullscreen-inited', true);
    if (id !== null && isPlaying === false) {
      this.openFullscreen(id);
      objectFit.update();
    } else {
      this.closeFullscreen(id);
    }
  }

  getAllPlayers() {
    return this.players;
  }

  getPlayerById(id) {
    const result = this.players.filter((player) => (player.id === id));

    if (result && result[0] && result[0].player) {
      return result[0].player;
    }

    return null;
  }
}

export const videoController = new VideoController();
