/**
 *  @fileOverview Audio module controller, audio player controller
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     NPM:detect-it
 *  @requires     modules/audio-player
 */

import forEach from 'lodash/forEach';
import AudioPlayer from './audio-player';

class AudioController {
  constructor() {
    this.players = [];
  }

  init() {
    this.initPlayers();
  }

  initPlayers() {
    let id;

    forEach(document.body.querySelectorAll('[data-audio]'), (elem) => {
      id = elem.getAttribute('data-audio');
      if (elem.getAttribute('data-audio-inited') === null) {
        this.players.push({
          id,
          player: new AudioPlayer(elem, id),
        });
        elem.setAttribute('data-audio-inited', true);
      }
    });
  }

  getAllPlayers() {
    return this.players;
  }

  getPlayerById(id) {
    const result = this.players.filter((player) => (player.id === id));

    if (result && result[0] && result[0].player) {
      return result[0].player;
    }

    return null;
  }
}

export const audioController = new AudioController();
