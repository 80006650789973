/**
 *  @fileOverview Accordion controller, manages all the rich accordions
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *  @author       Tamas Oberritter <tamas.oberritter@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     modules/accordion
 */

import forEach from 'lodash/forEach';
import Accordion from './accordion';

class AccordionController {
  constructor() {
    this.accordions = [];
  }

  init() {
    let id;
    forEach(document.body.querySelectorAll('[data-accordion]'), (el) => {
      if (el.getAttribute('data-accordion-inited') === null) {
        id = el.getAttribute('data-accordion');
        this.accordions.push({
          id,
          accordion: new Accordion({
            el,
            id,
          }),
        });
        el.setAttribute('data-accordion-inited', true);
      }
    });
  }
}

export const accordionController = new AccordionController();
