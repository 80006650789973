/**
 *  @fileOverview Enquire form and phases handler form part
 *
 *  @author       Lukasz Goral <lukasz.goral@possible.com>
 *
 */

import throttle from 'lodash/throttle';
import { api } from './api';
import { generalError } from './general-errors';

class ContactFormController {
  init() {
    this.initVars();
    this.initDOMElements();
  }

  initVars() {
    this.baseUrl = location.origin;
    this.contactForm = null;
    this.submitButton = null;
    this.subject = null;
    this.title = null;
    this.fullName = null;
    this.email = null;
    this.phone = null;
    this.comments = null;
    this.onChangeValidation = false;
    this.subjectErrorMsg = null;
    this.fullNameErrorMsg = null;
    this.emailErrorMsg = null;
    this.phoneErrorMsg = null;
    this.commentsErrorMsg = null;
    this.sentMsg = null;
    this.whereIsFirstError = null;
    this.formContent = {
      Subject: '',
      Title: '',
      FullName: '',
      Email: '',
      Phone: '',
      Comments: '',
    };
    this.isFormValuesValid = {
      Subject: false,
      FullName: false,
      Email: false,
      Phone: false,
      Comments: false,
    };
  }

  initDOMElements() {
    this.contactForm = document.querySelector('[contact-form]');
    if (this.contactForm != null) {
      this.submitButton = this.contactForm.querySelector('[data-submit-form]');
      this.subject = this.contactForm.querySelector('select[name="Subject"]');
      this.title = this.contactForm.querySelector('select[name="Title"]');
      this.fullName = this.contactForm.querySelector('input[name="FullName"]');
      this.email = this.contactForm.querySelector('input[name="Email"]');
      this.phone = this.contactForm.querySelector('input[name="Phone"]');
      this.comments = this.contactForm.querySelector('textarea[name="Comments"]');
      this.subjectErrorMsg = this.contactForm.querySelector('.contact-form__subject-error');
      this.fullNameErrorMsg = this.contactForm.querySelector('.contact-form__full-name-error');
      this.emailErrorMsg = this.contactForm.querySelector('.contact-form__email-error');
      this.phoneErrorMsg = this.contactForm.querySelector('.contact-form__phone-error');
      this.commentsErrorMsg = this.contactForm.querySelector('.contact-form__comments-error');
      this.formSentMsg = this.contactForm.querySelector('.contact-form__message-sent');
      this.formNotSentMsg = this.contactForm.querySelector('.contact-form__message-not-sent');
      this.initBindings();
    }
  }

  initBindings() {
    this.submitButton.addEventListener('click', throttle((e) => {
      e.preventDefault();
      this.onSubmit();
    }, 2000));
    this.contactForm.addEventListener('submit', (e) => {
      e.preventDefault();
    });
    this.subject.addEventListener('change', () => {
      if (this.onChangeValidation) {
        this.getDataFromForm();
        this.validData();
        this.showError();
      }

    });
    this.email.addEventListener('change', () => {
      if (this.onChangeValidation) {
        this.getDataFromForm();
        this.validData();
        this.showError();
      }

    });
    this.phone.addEventListener('change', () => {
      if (this.onChangeValidation) {
        this.getDataFromForm();
        this.validData();
        this.showError();
      }

    });
    this.fullName.addEventListener('change', () => {
      if (this.onChangeValidation) {
        this.getDataFromForm();
        this.validData();
        this.showError();
      }

    });
    this.comments.addEventListener('change', () => {
      if (this.onChangeValidation) {
        this.getDataFromForm();
        this.validData();
        this.showError();
      }

    });
  }

  getDataFromForm() {
    this.formContent.Subject = this.subject.options[this.subject.selectedIndex].value.trim();
    this.formContent.Title = this.title.options[this.title.selectedIndex].value.trim();
    this.formContent.FullName = this.fullName.value.trim();
    this.formContent.Email = this.email.value.trim();
    this.formContent.Phone = this.phone.value.trim();
    this.formContent.Comments = this.comments.value.trim();
  }

  onSubmit() {
    this.onChangeValidation = true;
    this.formNotSentMsg.classList.remove('contact-form__message-not-sent--visible');
    this.formSentMsg.classList.remove('contact-form__message-sent--visible');
    this.submitButton.classList.remove('contact-form__bt--sent');
    this.submitButton.classList.remove('contact-form__bt--not-sent');

    this.getDataFromForm();
    this.validData();
    this.showError();
    if (this.isDataValid()) {
      this.submitButton.classList.add('contact-form__bt--sending', 'button--loading');
      this.sendData(this.formContent);
    } else {
      this.goToFormTop();
    }
  }

  validData() {
    this.isFormValuesValid.Subject = this.isNotEmpty(this.formContent.Subject);
    this.isFormValuesValid.FullName = this.isNotEmpty(this.formContent.FullName);
    this.isFormValuesValid.Email = this.isEmailCorrect(this.formContent.Email);
    this.isFormValuesValid.Phone = this.isPhoneCorrect(this.formContent.Phone);
    this.isFormValuesValid.Comments = this.isNotEmpty(this.formContent.Comments);

  }

  isDataValid() {
    const values = this.isFormValuesValid;
    return (values.Subject && values.FullName && values.Email && values.Phone && values.Comments);
  }

  isEmailCorrect(email) {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  }

  isPhoneCorrect(phone) {
    const regex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;
    return regex.test(phone) || phone.length === 0;

  }

  isNotEmpty(input) {
    return (typeof input === 'string' && input.length !== 0);
  }

  sendData(formData) {
    let result = {};
    const url = `${this.baseUrl}/api/contact/post`;
    return api.request(url, 'post', formData)
      .then((res) => {
        if ((res || {}).data || {}) {
          if (res.status === 400 && res.data.ModelState) {
            const errors = res.data.ModelState;
            const errorKeys = Object.keys(errors);
            /* eslint-disable no-return-assign */
            errorKeys.forEach((errKey) => result[errKey.split('.')[1]] = errors[errKey]);
            /* eslint-enable no-return-assign */
            this.submitButton.classList.remove('contact-form__bt--sending', 'button--loading');
            this.formNotSentMsg.classList.add('contact-form__message-not-sent--visible');
          } else {
            result = res.data;
            this.submitButton.classList.remove('contact-form__bt--sending', 'button--loading');
            this.formSentMsg.classList.add('contact-form__message-sent--visible');
            this.onChangeValidation = false;
            this.clearForm();
          }
        }
        return result;
      })
      .catch((err) => {
        if ((err || {}).message) {
          generalError.showError(err.message);
          this.submitButton.classList.remove('contact-form__bt--sending', 'button--loading');
          this.submitButton.classList.add('contact-form__bt--not-sent');
          this.formNotSentMsg.classList.add('contact-form__message-not-sent--visible');
        }
      });
  }

  showError() {
    if (this.isFormValuesValid.Comments) {
      this.comments.classList.remove('contact-form__textarea--incorrect');
      this.commentsErrorMsg.classList.remove('contact-form__comments-error--visible');
    } else {
      this.comments.classList.add('contact-form__textarea--incorrect');
      this.commentsErrorMsg.classList.add('contact-form__comments-error--visible');
      this.whereIsFirstError = this.comments;
    }

    if (this.isFormValuesValid.Phone) {
      this.phone.classList.remove('contact-form__input--incorrect');
      this.phoneErrorMsg.classList.remove('contact-form__phone-error--visible');
    } else {
      this.phone.classList.add('contact-form__input--incorrect');
      this.phoneErrorMsg.classList.add('contact-form__phone-error--visible');
      this.whereIsFirstError = this.phone;
    }

    if (this.isFormValuesValid.Email) {
      this.email.classList.remove('contact-form__input--incorrect');
      this.emailErrorMsg.classList.remove('contact-form__email-error--visible');
    } else {
      this.email.classList.add('contact-form__input--incorrect');
      this.emailErrorMsg.classList.add('contact-form__email-error--visible');
      this.whereIsFirstError = this.email;
    }

    if (this.isFormValuesValid.FullName) {
      this.fullName.classList.remove('contact-form__input--incorrect');
      this.fullNameErrorMsg.classList.remove('contact-form__full-name-error--visible');
    } else {
      this.fullName.classList.add('contact-form__input--incorrect');
      this.fullNameErrorMsg.classList.add('contact-form__full-name-error--visible');
      this.whereIsFirstError = this.fullName;
    }

    if (this.isFormValuesValid.Subject) {
      this.subject.classList.remove('contact-form__select--incorrect');
      this.subjectErrorMsg.classList.remove('contact-form__subject-error--visible');
    } else {
      this.subject.classList.add('contact-form__select--incorrect');
      this.subjectErrorMsg.classList.add('contact-form__subject-error--visible');
      this.whereIsFirstError = this.subject;
    }

  }

  clearForm() {
    this.subject.selectedIndex = 0;
    this.title.selectedIndex = 0;
    this.fullName.value = '';
    this.email.value = '';
    this.phone.value = '';
    this.comments.value = '';
  }

  goToFormTop() {
    if (this.whereIsFirstError != null) {
      this.whereIsFirstError.scrollIntoView();
      window.scrollBy(0, -130);
      this.whereIsFirstError = null;
    }
  }

}
export const contactFormController = new ContactFormController();
