/**
 *  @fileOverview Device properties helper
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     NPM:detect-it
 */

import throttle from 'lodash/throttle';
import { supportsPassiveEvents } from 'detect-it';

let deviceProperties;
let listenersAdded = false;

const measureScrollbar = () => {
  const scrollDiv = document.createElement('div');
  let scrollbarWidth = 0;

  scrollDiv.classList.add('scrollbar-measure');
  document.body.appendChild(scrollDiv);
  scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);

  return scrollbarWidth;
};

const updateProperties = () => {
  const w = window;
  const d = document;
  const e = d.documentElement;
  const g = d.getElementsByTagName('body')[0];
  const W = w.innerWidth || e.clientWidth || g.clientWidth;
  const H = w.innerHeight || e.clientHeight || g.clientHeight;

  deviceProperties.window.width = W;
  deviceProperties.window.height = H;
};

const initDeviceProperties = () => {
  const w = window;
  const d = document;
  const e = d.documentElement;
  const g = d.getElementsByTagName('body')[0];
  const W = w.innerWidth || e.clientWidth || g.clientWidth;
  const H = w.innerHeight || e.clientHeight || g.clientHeight;

  if (listenersAdded === false) {
    listenersAdded = true;
    window.addEventListener('resize', throttle(() => {
      updateProperties();
    }, 50), supportsPassiveEvents ? { capture: false, passive: true } : false);
  }

  deviceProperties = {
    connection: navigator.connection ? navigator.connection : 'unknown',
    pixelRatio: window.devicePixelRatio,
    scrollbarWidth: measureScrollbar(),
    window: {
      width: W,
      height: H,
    },
  };
};

const getDeviceProperties = () => deviceProperties;

initDeviceProperties();

export default getDeviceProperties();
