/**
 *  @fileOverview Enquire form and phases handler form part
 *
 *  @author       Julia Ero <julia.ero@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     modules/enquire
 *  @requires     modules/service
 *  @requires     modules/custom-select
 *  @requires     utils/helper
 *  @requires     utils/accessibility
 */

import forEach from 'lodash/forEach';
import { accessibilityHelper } from '../utils/accessibility';
import { addLoader } from '../utils/button-helper';

class LoginForm {
  init() {
    this.formData = {};
    this.initDOMElements();
    this.initBindings();
  }

  initDOMElements() {
    this.form = document.querySelector('[data-login-form]');
    this.formElements = this.form.querySelectorAll('[data-form-element]');
    this.inputs = this.form.querySelectorAll('[data-form-input]');
    this.submitButton = this.form.querySelector('[data-submit-form]');
  }

  initBindings() {
    this.submitButton.addEventListener('click', (e) => {
      e.stopPropagation();
      e.preventDefault();
      addLoader(this.submitButton);
      this.validateForm();
    });

    accessibilityHelper.subscribe(this.submitButton, 'open', () => {
      addLoader(this.submitButton);
      this.validateForm();
    });
  }

  getformData() {
    forEach(this.inputs, (item) => {
      if (item.type === 'checkbox') {
        this.formData[item.name] = item.checked;
      } else {
        this.formData[item.name] = item.value;
      }
    });
  }

  handleErrors(data) {
    const errors = Object.keys(data);

    forEach(this.formElements, (item) => {
      const index = errors.indexOf(item.getAttribute('data-form-element'));

      if (index >= 0) {
        const errorSpan = item.querySelector('[data-form-error-message]');

        errorSpan.innerHTML = data[errors[index]][0];
        item.classList.add('error');
      } else {
        item.classList.remove('error');
      }
    });
  }
}

export const loginForm = new LoginForm();
