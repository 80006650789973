/**
 *  @fileOverview Colorize util to help generate and colorize different page elements
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *  @author       Julia Ero <julia.ero@possible.com>
 *
 *  @requires     NPM:lodash
 *  @requires     utils/helper
 */

import forEach from 'lodash/forEach';
import { helper } from '../utils/helper';

class Colorize {
  constructor() {
    this.colorStyles = [];
  }

  init() {
    this.color = helper.getPageColor();
    this.colorHex = helper.colorToHex(this.color);
    this.colorLighter = helper.lightenDarkenColor(this.colorHex, 10);
    this.colorDarker = helper.lightenDarkenColor(this.colorHex, -10);
    this.colorDarkest = helper.lightenDarkenColor(this.colorHex, -220);
    this.isLight = helper.isLightColor(this.colorHex);

    this.initColorize();
    this.setTheme();
  }

  initColorize() {
    forEach(this.colorStyles, () => {
      document.styleSheets[0].deleteRule(0);
    });
    this.colorStyles = [];
    forEach(document.querySelectorAll('[data-colorize]'), (elem) => {
      if (elem.getAttribute('data-colorize-inited') === null) {
        this.generateStyles(elem);
        elem.setAttribute('data-colorize-inited', true);
      }
    });
  }

  setTheme() {
    const page = document.querySelector('main');

    page.classList.remove('page--dark');
    page.classList.remove('page--light');

    if (this.isLight) {
      page.classList.add('page--light');
    } else {
      page.classList.add('page--dark');
    }
  }

  generateStyles(elem) {
    const type = elem.getAttribute('data-colorize') || 'primary-button';
    let selector;
    let baseRule;
    let hoverRule;

    switch (type) {
      case 'acc-darker-background':
        selector = '.accordion .accordion__title, .accordion .accordion__content';
        baseRule = `${selector} { background-color: ${this.colorDarker} !important; }`;
        break;
      case 'gallery':
        selector = '.gallery.gallery--show-overlay';
        baseRule = `${selector} { background-color: ${this.colorDarkest} !important; }`;
        break;
      case 'primary-button':
      default:
        selector = '.button.button--primary-cta';
        baseRule = `${selector} { background-color: ${this.colorHex} !important; }`;
        hoverRule = `${selector}:hover { background-color: ${this.colorLighter} !important; }`;
        break;
    }

    if (baseRule !== undefined) {
      this.colorStyles.push(document.styleSheets[0].insertRule(baseRule, this.colorStyles.length));
    }

    if (hoverRule !== undefined) {
      this.colorStyles.push(document.styleSheets[0].insertRule(hoverRule, this.colorStyles.length));
    }
  }
}

export const colorize = new Colorize();
